<!--渠道的解决时间-->
<template>
  <div class="box" v-loading="loading">
    <div class="box_head">
      <div class="box_title">
        <!-- 渠道的解决时间 -->
        <span>{{ $t("c540") }}</span>
        <svg aria-hidden="true" style="margin-left: 8px" @click="refresh()">
          <use href="#icon-shuaxin-changtai"></use>
        </svg>
      </div>
      <!-- 统计数据日期选择下拉组件 -->
      <StatisticalSelect ref="StatisticalSelect" :Application="Application" />
      <!-- 编辑删除组件 -->
      <DeleteEdit
        class="delete_edit"
        v-if="isShowExtendBtnDropdown"
        :isEdit="true"
        :cpnName="ChannelData.name"
        :cpnId="ChannelData.i"
        :cpnObject="ChannelData"
      />
    </div>
    <div class="table" v-if="tableData">
      <!-- <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="channel" label="渠道" width="180">
        </el-table-column>
        <el-table-column prop="time" label="解决时间（小时）" width="180">
        </el-table-column>
        <el-table-column prop="percentage" label="百分比"> </el-table-column>
      </el-table> -->
      <div class="tabel_head">
        <span>{{ $t("c544") }}</span
        ><!--渠道-->
        <span>{{ $t("c545") }}</span
        ><!--解决时间（小时）-->
        <span>{{ $t("label.percent") }}</span
        ><!--百分比-->
      </div>
      <div class="tabel_body" v-for="(item, index) in tableData" :key="index">
        <div v-if="item.channel" class="tabel_item">
          <span>{{ item.channel }}</span>
          <span>{{ item.solutiontime }}</span>
          <span>{{ item.percentage }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteEdit from "./DeleteEdit"; //引入编辑删除组件
import StatisticalSelect from "./StatisticalSelect"; //引入统计数据日期选择下拉组件
export default {
  components: {
    DeleteEdit,
    StatisticalSelect,
  },
  props: {
    ChannelData: {
      type: Object,
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tableData: [],
      Application: "channel",
      loading: false,
      // timeArr: [],
      // channelArr: [],
      // percentageArr: [],
    };
  },
  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      }
      return undefined
    },
  },
  mounted() {},
  methods: {
    //刷新
    refresh() {
      this.loading = true;
      this.$refs.StatisticalSelect.getIndividualCase(
        this.$refs.StatisticalSelect.value1,
        this.$refs.StatisticalSelect.value2
      );
    },
  },
};
</script>
<style lang='scss' scoped>
.box {
  height: 100%;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #e1e1e1;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  .box_head {
    position: relative;
    padding: 14px 20px 14px 20px;
    display: flex;
    justify-content: space-between;
    .box_title {
      span {
        font-size: 14px;
        font-weight: bold;
        color: #393939;
      }
    }
    .box_select {
      font-size: 14px;
      font-weight: 400;
      color: #393939;
    }
    svg {
      width: 14px;
      height: 14px;
    }
    .delete_edit {
      position: absolute;
      right: 0;
      z-index: 1000;
    }
  }
  .table {
    padding: 0 28px 0 28px;
    .tabel_head {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      color: #888888;
    }
    .tabel_body {
      display: flex;
      font-size: 14px;
      font-weight: 400;
      color: #393939;
      .tabel_item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 22px;
        font-weight: 400;
        span {
          display: block;
        }
        span:nth-child(1) {
          width: 50px;
        }
        span:nth-child(3) {
          width: 80px;
          text-align: right;
        }
      }
    }
  }
}
</style>