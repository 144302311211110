<template>
  <!-- 公告组件 -->
  <div class="container">
    <!-- 标题 -->
    <div class="titleDiv">
      <!-- 公告 -->
      <div>{{ $t("label.callboard") }}</div>
      <!-- 编辑删除组件 -->
      <DeleteEdit
        class="delete_edit"
        v-if="isShowExtendBtnDropdown"
        :isEdit="true"
        :cpnName="NoticeData.name"
        :cpnId="NoticeData.i"
        :cpnObject="NoticeData"
      />
    </div>
    <!-- tab切换栏 -->
    <el-tabs class="tab" @tab-click="handleClick" v-model="activeName">
      <el-tab-pane
        :label="item.key"
        :name="item.key"
        :key="index"
        v-for="(item, index) in tabList"
      ></el-tab-pane>
      <!-- <el-tab-pane label="促销活动" name="second">促销活动</el-tab-pane>-->
    </el-tabs>
    <!-- 内容区域 -->
    <div class="content" v-loading="noticeLoading">
      <!-- 每一项 -->
      <div
        class="contentItem"
        v-for="item in contentList"
        :key="item.id"
        @click="dialogShow(item)"
      >
        <div class="leftImg" v-if="item.img == null ? false : true">
          <img :src="item.backImgSrc" alt="" />
        </div>
        <div class="rightIntroduce">
          <div class="rightTop">
            <span>{{ item.theme }}</span>
            <span>{{ item.createdate }}</span>
          </div>
          <div class="rightBottom" width="300px">{{ item.content }}</div>
        </div>
      </div>
      <!-- 没有公告 -->
      <div class="noContent" v-if="contentList.length == 0">
        <svg class="icon noContentimg" aria-hidden="true">
          <use href="#icon-notice"></use>
        </svg>
      </div>
    </div>
    <!-- 公告弹框 -->
    <el-dialog
      :title="noticeItem.theme"
      :visible.sync="dialogVisible"
      width="430px"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div class="dialogContent">
        <img
          :src="backImgSrcItem"
          alt=""
          v-if="noticeItem.img == '' ? false : true"
        />
        <div>{{ noticeItem.content }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- 取消 -->
        <el-button @click="dialogVisible = false">{{
          $t("label.emailtocloudcc.button.back")
        }}</el-button>
        <!-- 确定 -->
        <el-button type="primary" @click="dialogVisible = false">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DeleteEdit from "./DeleteEdit"; //引入编辑删除组件
import * as request from "./api.js";
export default {
  components: {
    DeleteEdit,
  },
  props: {
    NoticeData: {
      type: Object,
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      noticeLoading: false,
      token: this.$CCDK.CCToken.getToken(),
      noticeParameters: { groupType: "" }, //公告参数tab切换时请求数据传
      tabList: [
        // "全部",
        // "促销活动",
        // "培训",
        // "全部1",
        // "促销活动1",
        // "培训1",
      ], //tab栏数据
      noticeItem: "", //每一条公告
      activeName: "first",
      dialogVisible: false, //弹框显示
      contentList: [], //数据
      // backImgSrc: "", //图片地址
      backImgSrcItem: "", //点击对应的图片地址
    };
  },
  mounted() {
    // 页面初始显示全部公告
    this.getHomePageData();
  },
  methods: {
    // 将时间秒数转换成日期
    mapDateMethod(timeDate) {
      var date = new Date(timeDate);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var mm = date.getMinutes();
      mm = mm < 10 ? "0" + mm : mm;
      var ss = date.getSeconds();
      ss = ss < 10 ? "0" + ss : ss;
      return y + "-" + m + "-" + d + " " + h + ":" + mm + ":" + ss;
    },
    // 初始进入页面获取 全部 数据
    getHomePageData() {
      this.noticeLoading = true;
      let op = {
        id: this.NoticeData.i,
        otherConditions: JSON.stringify({ groupType: "" }),
      };
      request.getHomePageData(op).then((res) => {
        if (res.result == true) {
          this.noticeLoading = false;
          // tabs 菜单栏
          if (res.data && res.data.filterList) {
            if (res.data.filterList.length > 0) {
              this.tabList = res.data.filterList;
              this.tabList.splice(0, 0, {
                key: this.$i18n.t("label.emailobject.view.all"),
              }); //全部
            }
          }
          if (res.data && res.data.qryLists && res.data.qryLists.length > 0) {
            // 全部数据内容
            this.contentList = res.data.qryLists;
            this.contentList.map((va) => {
              va.backImgSrc = `${this.$cookies.get(
                "domainName"
              )}/querysome.action?m=viewImg&fileid=${va.img}&binding=${
                this.token
              }`;
              va.createdate = this.mapDateMethod(va.createdate);
            });
          }
        }
      });
    },
    // 切换tabs 获取对应的数据
    handleClick(tab) {
      if (tab.name === this.$i18n.t("label.emailobject.view.all")) {
        //全部
        this.getHomePageData();
      } else {
        this.noticeParameters.groupType = tab.name;
        let op = {
          id: this.NoticeData.i,
          otherConditions: JSON.stringify(this.noticeParameters),
        };
        request.getHomePageData(op).then((res) => {
          if (
            res &&
            res.data &&
            res.data.qryLists &&
            res.data.qryLists.length > 0
          ) {
            // 全部数据内容
            this.contentList = res.data.qryLists;
            this.contentList.map((va) => {
              va.backImgSrc = `${this.$cookies.get(
                "domainName"
              )}/querysome.action?m=viewImg&fileid=${va.img}&binding=${
                this.token
              }`;
              va.createdate = this.mapDateMethod(va.createdate);
            });
          }
        });
      }
    },
    handleClose(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      done();
      // })
      // .catch((_) => {});
    },
    // 点击 展示大图 详情
    dialogShow(item) {
      this.dialogVisible = true;
      this.noticeItem = item;
      this.backImgSrcItem = `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${item.img}&binding=${this.token}`;
    },
  },
  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.delete_edit {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1000;
}
.container {
  padding-top: 10px;
  background-color: #fff;
  height: 100%;
  width: 100%;
  padding: 20px;

  .titleDiv {
    height: 30px;
    width: calc(100% - 30px);
    position: absolute;
    top: 0px;
    background-color: #fff;
    font-weight: 700;
    font-size: 14px;
    color: #0e0d0d;
    display: flex;
    align-items: center;
    div {
      position: absolute;
      top: 15px;
      z-index: 100;
    }
  }
  .tab {
    height: 38px;
    width: calc(100% - 30px);
    position: absolute;
    background-color: #fff;
    top: 30px;
    padding-right: 20px;
  }
  .content {
    margin-top: 65px;
    height: 80%;
    overflow-y: scroll;
    width: 100%;
    .contentItem {
      display: flex;
      justify-content: space-between;
      height: 120px;
      width: 100%;
      border-bottom: 0.5px solid #e0dfdf;
      margin-bottom: 10px;
      padding: 0 5px 0 0;
      cursor: pointer;
      .leftImg {
        height: 90%;
        width: 35%;
        margin-right: 1%;
        img {
          display: block;
          height: 100%;
          width: 100%;
        }
      }
      .rightIntroduce {
        height: 90%;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        .rightTop {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
          :nth-child(1) {
            font-weight: 700;
            font-size: 12px;
          }
          :nth-child(2) {
            color: #acacac;
            font-size: 12px;
          }
        }
        .rightBottom {
          color: #666666;
        }
      }
    }
    .noContent {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
.noContentimg {
  width: 35%;
  height: 100%;
  margin: auto;
}
.dialogContent {
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  align-items: center;
  img {
    width: 99%;
    display: block;
    margin-bottom: 15px;
  }
  div {
    width: 99%;
    padding-bottom: 15px;
  }
}
.el-tab-pane {
  display: none;
}
::v-deep .el-tabs__item {
  font-weight: 700;
  font-size: 12px;
  color: #080707;
  padding-right: 30px;
}
</style>
