<!--
  审批中心
-->
<template>
  <div class="card_div">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="refresh-date">
          <div>
            {{ $t("label.manageAll.title") }}
            <!-- 审批中心 -->
          </div>
        </div>
        <div class="right-box">
          <!-- {{homePageObject.type}} -->
          <el-button @click="manageAll" size="mini">
            {{ $t("label.manage.all") }}
            <!-- 管理全部 -->
          </el-button>
          <DeleteEdit
            :cpnName="tableData.name"
            :cpnId="tableData.i"
            v-if="isShowExtendBtnDropdown"
            :cpnObject="tableData"
            :isEdit="true"
          />
        </div>
      </div>
      <div class="content_center">
        <div class="tabsTable_content">
          <!-- 表格切换 -->
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <!-- :label="`全部(${amount})`" -->
            <el-tab-pane
              :label="`${$t('label.quickbooks.view.all')}(${amount})`"
              name="all"
            ></el-tab-pane>
            <el-tab-pane
              :label="`${item.name} (${item.count})`"
              :name="item.prefix"
              v-for="item in cpnTabData"
              :key="item.id"
              :loading="true"
            />
          </el-tabs>
          <!-- 表格 -->
          <el-table
            :data="tableDataData"
            :stripe="false"
            style="width: 100%"
            v-loading="loading"
            height="calc(100% - 48px)"
            class="wang_table"
          >
            <!-- height="calc(100% - 48px)" -->
            <!-- 无数据 -->
            <div slot="empty" style="height: 200px; line-height: 200px">
              {{ $t("label.dashboard.noRecord") }}
            </div>
            <!-- 委派人 -->
            <el-table-column
              prop="actname"
              :label="$t('label.relationobject.wpr')"
              min-width="140"
            ></el-table-column>
            <!-- 类型 -->
            <el-table-column
              prop="objtype"
              :label="$t('label.wei.polltype')"
              min-width="100"
            ></el-table-column>
            <!-- 相关项 -->
            <el-table-column
              prop="objname"
              :label="$t('label.relateobj')"
              min-width="100"
            >
              <template slot-scope="scope">
                <!-- <div class="objnameStyle" @click="objnameTurn(scope.row.objid)">
                  {{ scope.row.objname }}
                </div>-->
                <!-- 右击出现新的标签页 -->
                <a
                  :href="`#/commonObjects/detail/${scope.row.objid}/DETAIL`"
                  target="_blank"
                  class="objnameStyle"
                  @click.prevent="objnameTurn(scope.row.objid)"
                  >{{ scope.row.objname }}</a
                >
              </template>
            </el-table-column>
            <!-- 提交人 -->
            <el-table-column
              prop="actor"
              :label="$t('label.forecast.forehistory.submitor')"
              min-width="100"
            ></el-table-column>
            <!-- 提交时间 -->
            <el-table-column
              prop="actdate"
              :label="$t('label.refertime')"
              min-width="150"
            ></el-table-column>
            <!-- 摘要 -->
            <el-table-column
              prop="zhaiyao"
              :label="$t('chatter.feed.menutype.feed')"
              min-width="300"
            >
              <template slot-scope="scope">
                <div v-html="scope.row.zhaiyao">{{ scope.row.zhaiyao }}</div>
              </template>
            </el-table-column>
            <el-table-column min-width="30">
              <template slot-scope="scope">
                <el-popover
                  placement="bottom-start"
                  width="112"
                  trigger="hover"
                  popper-class="operationClass"
                >
                  <div class="moreOperation">
                    <div @click="forApproval(scope.row)">
                      <!--进行审批  -->
                      {{ $t("label.approveorrefuse") }}
                    </div>
                    <div
                      v-show="scope.row.isInsertApproval !== 'true'"
                      @click="redistribution(scope.row)"
                    >
                      <!-- 重新分配 -->
                      {{ $t("label.redistribution") }}
                    </div>
                  </div>
                  <svg
                    class="icon operationImg"
                    aria-hidden="true"
                    slot="reference"
                  >
                    <use href="#icon-operation1" />
                  </svg>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="turnPageBox">
          <div class="recordTotal">
            <!-- 记录{recordTotal}条 -->
            {{
              $t("vue.homepage.auditcard.record", { recordTotal: recordTotal })
            }}
            <!-- 记录 -->
            <!-- {{ $t("chatter.fieldtrack.record") }}
            &nbsp;&nbsp;&nbsp;{{ recordTotal }}-->
            <!-- 条 -->
            <!-- {{ $t("label_tabpage_bar") }} -->
          </div>
          <div class="turnPage">
            <el-button @click="previousPage" :disabled="page === 1">
              <!-- 上一页 -->
              {{ $t("label.page.previous") }}
            </el-button>
            <el-button @click="nextPage" :disabled="page > pageNumTotal - 1">
              <!--下一页  -->
              {{ $t("label.page.next") }}
            </el-button>
          </div>
          <div class="jump">
            <!-- 中日韩对应的样式：第{0}页 -->
            <div v-if="$i18n.locale === 'zh' || $i18n.locale === 'jp'">
              {{ $t("label.mr.title.button.group1") }}
              <input type="text" v-model="page" />
              {{ $t("label.brief.page") }}
            </div>
            <!-- 其他语言对应的样式：页{0} -->
            <div v-else>
              {{ $t("label.brief.page") }}
              <input type="text" v-model="page" />
            </div>
            <button @click="jumpPage">GO</button>
            <!-- 共{0}页 -->
            {{ $t("label.paging.totalofpages", [pageNumTotal]) }}
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import * as request from "./api.js";
import DeleteEdit from "./DeleteEdit";
export default {
  components: {
    DeleteEdit,
  },
  props: {
    tableData: {
      type: Object,
      default: () => ({}),
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.init();
  },

  data() {
    return {
      activeName: "all", //切换默认显示--全部这个表格
      tableDataData: [], // 表格数据
      recordTotal: "0", //记录总条数
      amount: "0", //全部记录条数
      page: 1, //页数
      pageSize: 5, //每页条数
      pageNumTotal: "1", //总页数
      cpnTabData: [], //组件tab切换的菜单数据
      currentTab: "all", //当前选择的tab
      objid: "", //相关项ID
      loading: false,
      debounce: null,
      cancle: null,
      source: null,
    };
  },
  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      }
      return undefined;
    },
  },
  methods: {
    async init() {
      // 页面初次加载时请求数据
      await this.getApprovalListList();
      // 获取组件数据（审批中心分类tab数据，除全部分类的所有自定义分类）
      this.getHomePageData();
    },
    //点击相关项跳转详情
    objnameTurn(objId) {
      this.$router.push({
        path: `/commonObjects/detail/${objId}/DETAIL`,
      });
    },
    //获取审批中心数据
    async getApprovalListList() {
      this.loading = true;
      let { data } = await request.getApprovalList({
        type: this.currentTab,
        page: this.page,
        pageSize: this.pageSize,
      });
      let dataLenth = data.data.length;
      this.tableDataData = data.data;
      this.pageNumTotal = data.allpage;
      this.recordTotal = data.totalnum;
      if (this.currentTab == "all") {
        this.amount = data.totalnum;
      }
      this.objid = data.objid;
      this.loading = false;
      this.$nextTick(() => {
        let dom = document.querySelector(
          ".wang_table .el-table__body-wrapper .el-table__body"
        );

        let domHeight = dom?.offsetHeight;
        this.$bus.$emit("audit_table_boxparams", {
          domHeight,
          currentTab: this.currentTab,
          totalnum: dataLenth, //当前页审批条数数量
        });
      });
      // 数据加载完成
      return Promise.resolve();
    },
    //获取主页组件数据--审批中心tab切换
    async getHomePageData() {
      const { data } = await request.getHomePageData({
        id: this.tableData.i,
      });
      this.cpnTabData = data;
    },
    //点击tab切换
    async handleClick(tab) {
      this.page = 1;
      if (this.cancelRquest) {
        this.cancelRquest();
      }
      this.currentTab = tab.name;
      this.getApprovalListList();
    },
    //上一页
    previousPage() {
      if (this.page > 1) {
        this.page = this.page - 1;
        this.getApprovalListList();
      } else {
        // this.$message.info("已经是第一页");
        this.$message.info(this.$i18n.t("label.manageAll.pagenum1"));
      }
    },
    //下一页
    nextPage() {
      if (this.page < this.pageNumTotal) {
        this.page = this.page + 1;
        this.getApprovalListList();
      } else {
        // this.$message.info("已经是最后一页");
        this.$message.info(this.$i18n.t("label.manageAll.pagenum2"));
      }
    },
    // 跳转页面
    jumpPage() {
      if (this.page <= this.pageNumTotal) {
        this.getApprovalListList();
      } else {
        this.$message.error(this.$i18n.t("label.field.input.number"));
      }
    },
    // 进行审批
    forApproval(value) {
      let option = {
        objid: value.objid,
        workItemid: value.workItemid || value.id,
        objname: value.objname,
      };
      this.$router.push({
        path: "/approvalCenter/approvalRequest",
        query: { value: JSON.stringify(option) },
      });
      this.$Bus.$emit("approval-show-or-hidden", true);
    },
    // 重新分配
    redistribution(value) {
      this.$router.push({
        path: "/approvalCenter/distributionAagain",
        query: { value: value.id },
      });
    },
    //管理全部
    manageAll() {
      this.$Bus.$emit("cancel-not-loading", true);
      this.$router.push({
        path: "/approvalCenter/approvalList",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .box-card {
  height: 100% !important;
  .content_center {
    height: 100% !important;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .tabsTable_content {
      height: calc(100% - 44px) !important;
      .el-table {
        height: calc(100% - 40px) !important;
        font-size: 12px !important;
        .el-table__body-wrapper.is-scrolling-none {
          height: calc(100% - 48px) !important;
          .el-table__body {
            // 注释自适应大小
            // height: 100% !important;
            .el-table__row {
              // min-height: 64px;
            }
          }
        }
      }
    }
  }
}
::v-deep .el-card__body {
  width: 100%;
  height: calc(100% - 54px) !important;
  padding-top: 0 !important;
}

::v-deep .clearfix::after {
  display: none !important;
}
::v-deep .clearfix::before {
  display: none !important;
}
::v-deep .clearfix {
  font-weight: bold;
  ::v-deep div:last-child .el-button {
    width: 74px;
    height: 24px;
    font-size: 12px;
    padding: 0;
  }
}

.el-tabs {
  height: 40px !important;
  ::v-deep .el-tabs__item {
    font-size: 12px !important;
  }
  .el-tabs__item.is-top.is-active.is-active {
    color: #006dcc !important;
  }

  .el-tabs__item:hover {
    color: #006dcc !important;
  }
}
::v-deep .el-table .cell {
  word-break: break-word;
}
::v-deep .el-table td,
.el-table th {
  padding: 10px 0;
}
.operationImg {
  width: 16px;
  height: 16px;
}
.operationImg:hover {
  cursor: pointer;
}

.objnameStyle {
  color: #006dcc;
}
.objnameStyle:hover {
  cursor: pointer;
}
//翻页样式
.turnPageBox {
  padding-top: 8px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // 左
  .recordTotal {
    align-items: left;
    justify-content: center;
  }
  // 中
  .turnPage {
    display: flex;
    color: #acacac;
    margin-left: 108px;
    .el-button {
      // background: none;
      // border: none;
      font-size: 12px;
      // &:hover {
      //   background: none;
      // }
    }
  }
  // 右
  .jump {
    display: flex;
    align-items: center;
    input {
      width: 26px;
      height: 22px;
      outline: none;
      border: 1px solid #ccc;
      text-align: center;
    }
    button {
      margin: 0 5px;
      border: 1px solid #ccc;
      // background: none;
      font-size: 12px;
      text-align: center;
      outline: none;
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
// 设置panle 底部border 为 transparent
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: "";
  position: absolute;
  background-color: transparent !important;
  z-index: 1;
}

.el-popover.operationClass {
  padding: 10px 0 !important;
  min-width: 0;
  .moreOperation {
    color: #333333;
    font-size: 12px;
    div {
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background: #006dcc;
        color: #fff;
      }
    }
  }
}
</style>
