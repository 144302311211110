<!--
  我的任务
-->
<template>
  <div class="box-card" v-loading="taskLIoading">
    <el-card class="content">
      <!-- 表头 -->
      <div slot="header" class="clearfix">
        <div class="my-mission">{{ taskTitle }}</div>
        <div class="myimgs">
          <svg class="icon myimgs-blue" v-popover:popover2 aria-hidden="true">
            <use href="#icon-blue"></use>
          </svg>
          <!-- v-popover:popover2 图片上鼠标划上记录悬浮框事件 -->
          <DeleteEdit
            class="taskButton"
            :cpnName="MyTaskData.name"
            :cpnId="MyTaskData.i"
            v-if="isShowExtendBtnDropdown"
            :cpnObject="MyTaskData"
            :isEdit="true"
          />
        </div>
        <!-- 我的任务事件弹框 -->
        <!-- width="120" -->
        <el-popover
          ref="popover2"
          placement="bottom-end"
          :visible-arrow="false"
          trigger="hover"
          popper-class="colPopovers"
        >
          <div class="col_operation">
            <!-- 右上角切换状态 -->
            <div
              v-for="(item, index) in missionPopup"
              :key="index"
              class="whole"
            >
              <div
                @click="taskEvents(item, index, item.type)"
                class="operation"
              >
                {{ item.today }}
              </div>
              <div class="checkaMark" v-if="typeCur === index">
                <i class="el-icon-check"></i>
              </div>
            </div>
          </div>
        </el-popover>
      </div>
      <!-- 内容区 -->
      <div class="eventBox">
        <!-- 暂无数据时显示这个div -->
        <temporarily-no-data v-if="MyTaskDatas.length == 0" />
        <!-- 有数据时显示这个div -->
        <div
          v-for="(item, index) in MyTaskDatas"
          :key="index"
          class="cyclic-data"
        >
          <div class="time-con">
            <div class="timeBusiness-box">
              <div class="timeBusiness">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    :label="item.id"
                    type="checkbox"
                    @change="selectEvent($event, index)"
                  >
                    <el-tooltip
                      class="task-subject"
                      effect="dark"
                      :content="item.subject"
                      placement="top"
                    >
                      <span
                        v-if="item.belongtoname"
                        class="checkbox-event"
                        :class="{
                          strikethrough: checkList.indexOf(item.id) > -1,
                        }"
                        @click.stop.prevent="myMissionJumps(item)"
                      >
                        <a
                          :href="`#/commonObjects/detail/${item.id}/DETAIL`"
                          target="_blank"
                          >{{ item.subject }}</a
                        >
                      </span>

                      <span
                        v-else
                        class="checkbox-event"
                        :class="{
                          strikethrough: checkList.indexOf(item.id) > -1,
                        }"
                        @click.stop.prevent="myMissionJumps(item)"
                      >
                        <!-- 无主题 -->
                        {{ $t("label.weixin.no.name") }}
                      </span>
                    </el-tooltip>
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="mouseStroke" v-if="item.subject">
                {{ item.belongtoname }}
              </div>
              <div class="mouseStroke" v-if="item.subject == ''">
                {{ $t("vue_label_homepage_noassignee") }}
                <!-- 无分配人 -->
              </div>
            </div>
            <!-- 返回时间 -->
            <div
              v-if="item.expiredate"
              class="textData"
              :style="{ color: item.isBeforeToday ? 'red' : '#080707' }"
            >
              {{ item.expiredate }}
            </div>
          </div>
          <!-- 动态获取的事件 -->
          <el-popover
            placement="right-start"
            width="270"
            trigger="hover"
            popper-class="myPopper"
          >
            <div class="popover_content">
              <div class="popover_header">
                <div>
                  <p>价值100万的京东业务机会</p>
                </div>
              </div>

              <div class="popover_footer">
                <!-- 查看更多 -->
                {{ $t("label_tabpage_lookatmorez") }}
              </div>
            </div>
          </el-popover>
        </div>
      </div>
      <!-- 底部区域 -->
      <div class="bottom-box">
        <!-- 分割线 -->
        <!-- <div class="line"></div> -->
        <!-- 底部内容 -->
        <div class="viewTasks" @click.prevent="viewContent">
          <!-- 查看全部任务 -->
          <!-- 右击出现新的标签页 -->
          <a href="#/commonObjects/views/table/Task/task/bfa" target="_blank">
            <!-- {{
            $t("label_tabpage_lookatmorez")
          }} -->
            {{ $t("component_todaytask_label_all") }}
          </a>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import * as request from "./api.js";
import DeleteEdit from "./DeleteEdit";
// import PopoverCpn from "./PopoverCpn";
import TemporarilyNoData from "@/components/TemporarilyNoData/index.vue"; // 暂无数据
export default {
  props: {
    MyTaskData: {
      type: Object,
      default: () => ({}),
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    DeleteEdit,
    // PopoverCpn,
    TemporarilyNoData,
  },
  data() {
    return {
      // 我的任务loading
      taskLIoading: false,
      // 返回的时间显示和隐藏
      // isShowTimestamp: true,
      // 状态值颜色
      // stateColor: true,
      // 选中后返回保存的数据
      // saveData: [],
      // 改变状态的值
      statusValue: "",
      // 改变状态传给后台的值
      objectData: [],
      id: "",
      // iscompleted: "",
      // 今天以前的字体颜色
      // isBeforeToday: false,
      // 时间戳
      timeStamp: "",
      // 任务参数
      taskParameters: { type: "" },
      // 我的任务标题
      taskTitle: this.$i18n.t("label.mytask"), //"我的任务"
      // 选项框事件
      checkList: [],
      // 对号
      typeCur: "",
      /**
       * 我的任务数据
       */
      MyTaskDatas: [],
      //?我的任务弹框数据
      missionPopup: [
        {
          id: 0,
          today: this.$i18n.t("label.task.today"), //"今日任务"
          type: "todaytask",
        },
        {
          id: 1,
          today: this.$i18n.t("label.mytask"), //"我的任务"
          type: "mytask",
        },
        {
          id: 2,
          today: this.$i18n.t("label.weixin.allexpired"), //所有逾期",
          type: "overdue",
        },
        {
          id: 3,
          today: this.$i18n.t("vue_label_homepage_assignedtasks"), //已分配任务
          type: "mybelongto",
        },
      ],
      // countryCode: $cookies.get("countryCode"),
    };
  },
  created() {
    this.getMyTaskData();
    this.timeStamp = Date.now();
    this.timeDiscoloration();
  },
  methods: {
    // 获取数据
    async getMyTaskData() {
      this.taskLIoading = true;
      if (this.taskTitle === this.$i18n.t("label.task.today")) {
        //"今日任务"
        this.taskParameters.type = "todaytask";
      } else if (this.taskTitle === this.$i18n.t("label.mytask")) {
        //"我的任务"
        this.taskParameters.type = "mytask";
      } else if (this.taskTitle === this.$i18n.t("label.weixin.allexpired")) {
        //"所有逾期"
        this.taskParameters.type = "overdue";
      } else if (
        this.taskTitle === this.$i18n.t("vue_label_homepage_assignedtasks")
      ) {
        //"已分配任务"
        this.taskParameters.type = "mybelongto";
      }
      let res = await request.getHomePageData({
        id: this.MyTaskData.i,
        otherConditions: JSON.stringify(this.taskParameters),
      });
      this.taskLIoading = false;
      // 选中和未选中回显
      res.data.data.map((item) => {
        if (item.iscompleted === "1") {
          this.checkList.push(item.id);
        }
      });
      // // 今天以前的字段变红
      res.data.data.forEach((item) => {
        // .toLocaleDateString(),new Date(item.expiredate).toLocaleDateString())
        //
        if (this.timeStamp > item.expiredate) {
          // this.isBeforeToday = true;
          item.isBeforeToday = true;
        } else {
          // this.isBeforeToday = false;
          item.isBeforeToday = false;
        }
      });
      this.MyTaskDatas = res.data.data;
      //
    },
    timeDiscoloration() {
      // 今天以前的字段变红
      // this.MyTaskDatas.forEach(item => {
      //
      //   // .toLocaleDateString(),new Date(item.expiredate).toLocaleDateString())
      //   if (this.timeStamp > item.expiredate) {
      //     this.isBeforeToday = true;
      //   } else {
      //     this.isBeforeToday = false;
      //   }
      // });
    },

    // 任务事件
    taskEvents(item, index) {
      this.typeCur = index;
      this.taskTitle = item.today;
      if (this.taskTitle === this.$i18n.t("label.task.today")) {
        //"今日任务"
        this.taskParameters.type = "todaytask";
        let arrType = this.taskParameters;
        this.getDropDownBoxData(item, arrType);
        this.getMyTaskData();
      } else if (this.taskTitle === this.$i18n.t("label.mytask")) {
        //"我的任务"
        this.taskParameters.type = "mytask";
        let arrType = this.taskParameters;
        this.getDropDownBoxData(item, arrType);
        this.getMyTaskData();
      } else if (this.taskTitle === this.$i18n.t("label.weixin.allexpired")) {
        // "所有逾期"
        this.taskParameters.type = "overdue";
        let arrType = this.taskParameters;
        this.getDropDownBoxData(item, arrType);
        this.getMyTaskData();
      } else if (
        this.taskTitle === this.$i18n.t("vue_label_homepage_assignedtasks")
      ) {
        //"已分配任务"
        this.taskParameters.type = "mybelongto";
        let arrType = this.taskParameters;
        this.getDropDownBoxData(item, arrType);
        this.getMyTaskData();
      }
    },
    // 鼠标划上下拉框事件请求
    async getDropDownBoxData(item, type) {
      let res = await request.getHomePageData({
        id: this.MyTaskData.i,
        otherConditions: JSON.stringify(type),
      });
      this.MyTaskDatas = res.data.data;

      this.MyTaskDatas.forEach((item) => {
        // .toLocaleDateString(),new Date(item.expiredate).toLocaleDateString())
        //
        if (this.timeStamp > item.expiredate) {
          // this.isBeforeToday = true;

          item.isBeforeToday = true;
        } else {
          // this.isBeforeToday = false;
          item.isBeforeToday = false;
        }
      });
      this.MyTaskDatas.map((item) => {
        if (item.iscompleted === "1") {
          this.checkList.push(item.id);
        }
      });
    },
    // 选中时事件
    selectEvent(e, index) {
      this.MyTaskDatas.forEach((item, indexs) => {
        if (e === true && index === indexs) {
          this.id = item.id;
          this.statusValue = "1";
          const { countryCode } = this.$store.state.userInfoObj;
          let objectDatas = {
            id: this.id,
            iscompleted: this.statusValue,
            /**
             * 王惠清 say：
             * 如果countrycode是中文的话 传中文没问题，中文的key就是已结束
             * countryCode是USA的话传这个： Completed
             * 目前咱们就两个语种
             */
            status: countryCode === "USA" ? "Completed" : "已结束",
          };
          this.objectData.push(objectDatas);

          this.saveStatus(this.objectData);
        } else if (e === false && index === indexs) {
          this.saveStatus([
            {
              id: this.MyTaskDatas[index].id,
              iscompleted: "0",
            },
          ]);
        }
      });
    },
    // 保存事件
    async saveStatus(objectDatas) {
      let res = await request.save({
        objectApi: "Task",
        data: JSON.stringify(objectDatas),
      });
      this.saveData = res.data;
    },
    // 查看内容点击跳转
    viewContent() {
      this.$router.push({
        path: `/commonObjects/views/table/Task/task/bfa`,
      });
    },
    // 点击跳转
    // 点击跳转详情页
    myMissionJumps(option) {
      this.$router.push({
        path: `/commonObjects/detail/${option.id}/DETAIL`,
      });
    },
  },

  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      } else if (this.homePageObject.type == "PersonalPageCpn") {
        return true;
      }
      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #006dcc !important;
}
::v-deep .el-card__body {
  height: 100%;
  padding: 10px 0px !important;
}
.strikethrough {
  text-decoration: line-through;
  color: #c1c1c0;
}
.box-card {
  height: 100%;
  width: 100%;
  background-color: #fff;
  .content {
    height: 100%;
    width: 100%;
    background-color: #fff;
    ::v-deep .el-card__header {
      display: flex;
      justify-content: space-between;
      height: 58px;
      .my-mission {
        font-weight: bold;
      }
      .myimgs {
        position: absolute;
        right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        .myimgs-blue {
          width: 56px;
          height: 26px;
          border: 2px solid #eeedec;
          border-radius: 5px;
        }
      }
    }
    .eventBox {
      height: 73%;
      padding: 0 30px;
      .noData {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        div {
          margin-left: 53px;
          padding-top: 20px;
        }
      }
      .cyclic-data {
        height: 19%;
        .time-con {
          display: flex;
          justify-content: space-between;
          .timeBusiness-box {
            width: calc(100% - 100px);
            ::v-deep .el-checkbox__label {
              width: 100%;
              // line-height: 11px;
            }
            ::v-deep .el-checkbox-group {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .item {
              //  margin: 4px;
            }
          }
          .checkbox-event {
            width: 100%;
            // display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // display: inline-block;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            font-size: 12px;
            color: #006dcc;
            // a {
            //   display: inline-block;
            //   width: 152vh;
            //   overflow: hidden;
            //   word-break: keep-all;
            //   white-space: nowrap;
            //   text-overflow: ellipsis;
            // }
          }
          .mouseStroke {
            padding: 0 0 0 23px;
            font-size: 12px;
            // cursor: pointer;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .textData {
            width: 100px;
            text-align: center;
          }
        }
      }
    }
    .bottom-box {
      width: 100%;
      position: fixed;
      bottom: 21px;
      height: 6%;
      border-top: 1px solid #dcdbda;
      .line {
        width: 100%;
        height: 1px;
        border: 1px solid #dcdbda;
      }
      .viewTasks {
        font-size: 12px;
        text-align: center;
        margin: 13px auto;
        cursor: pointer;
        color: #006dcc;
        // position: absolute;
        // top: 14px;
        // left: 48%;
      }
    }
  }
}
::v-deep .jumpDetailP {
  color: #3e3e3c !important;
}
::v-deep .el-checkbox__input {
  line-height: 0;
}
</style>
<style lang="scss">
.colPopovers {
  padding: 5px 0;
  .col_operation {
    .whole {
      height: 30px;
      display: flex;
      align-items: center;
      padding: 5px 15px;
      .operation {
        width: 100%;
        cursor: pointer;
      }
      &:hover {
        color: #fff;
        background-color: #cccccc;
      }
    }
  }
}
//   //记录悬浮卡片样式
//   .popover_content {
//     display: flex;
//     flex-direction: column;
//     height: 400px;
//     //头固定
//     .popover_header {
//       display: flex;
//       height: 45px;
//       border-bottom: 1px solid #dcdcdc;
//       padding: 0 10px;
//       div:first-child {
//         img {
//           margin-top: 10px;
//         }
//       }
//       div:last-child {
//         p {
//           margin-top: 12px;
//           margin-left: 2px;
//           color: #080707;
//         }
//       }
//     }
//     //中间滚动条部分
//     .popover_center {
//       flex: 1;
//       overflow: auto;
//       padding: 10px 0 10px 10px;
//       .center1 {
//         display: flex;
//         flex-wrap: wrap;
//         border-bottom: 1px solid #dcdcdc;
//         div {
//           width: 50%;
//           p:first-child {
//             color: #666;
//             font-size: 12px;
//             margin-top: 2px;
//           }
//           p:last-child {
//             margin-top: -8px;
//           }
//         }
//         div:first-child {
//           p:last-child {
//             color: #0f75cf;
//             font-size: 12px;
//           }
//         }
//         div:nth-child(2) {
//           p:last-child {
//             color: #080707;
//             font-size: 12px;
//           }
//         }
//         div:last-child {
//           p:last-child {
//             color: #0f75cf;
//             font-size: 12px;
//           }
//         }
//       }
//       .center2 {
//         border-bottom: 1px solid #dcdcdc;
//         .title {
//           display: flex;
//           div:first-child {
//             img {
//               margin-top: 10px;
//             }
//           }
//           div:last-child {
//             p {
//               margin-top: 12px;
//               margin-left: 2px;
//               color: #080707;
//             }
//           }
//         }
//         .center2_content {
//           display: flex;
//           flex-wrap: wrap;
//           div {
//             width: 50%;
//             p:first-child {
//               color: #666;
//               font-size: 12px;
//               margin-top: 2px;
//             }
//             p:last-child {
//               margin-top: -8px;
//             }
//           }
//           div:first-child {
//             p:last-child {
//               width: 70%;
//               // color: #0f75cf;
//               border-bottom: 1px dashed #0f75cf;
//               font-size: 12px;
//             }
//           }
//         }
//       }
//       .center3 {
//         border-bottom: 1px solid #dcdcdc;
//         .title {
//           display: flex;
//           div:first-child {
//             img {
//               margin-top: 10px;
//             }
//           }
//           div:last-child {
//             p {
//               margin-top: 12px;
//               margin-left: 2px;
//               color: #080707;
//             }
//           }
//         }
//         .center3_content {
//           display: flex;
//           flex-wrap: wrap;
//           div {
//             width: 50%;
//             p:first-child {
//               color: #666;
//               font-size: 12px;
//               margin-top: 2px;
//             }
//             p:last-child {
//               margin-top: -8px;
//             }
//           }
//           div {
//             p:last-child {
//               width: 70%;
//               color: #0f75cf;
//               border-bottom: 1px dashed #0f75cf;
//               font-size: 12px;
//             }
//           }
//         }
//       }
//     }
//     //尾固定
//     .popover_footer {
//       height: 45px;
//       color: #0f75cf;
//       text-align: right;
//       font-size: 12px;
//       padding-top: 18px;
//       padding-right: 18px;
//       cursor: pointer;
//     }
//   }
//   //滚动条样式
//   ::-webkit-scrollbar {
//     width: 10px;
//     // height: 300px;
//     background: #f3f6f9;
//     border-radius: 2px;
//     opacity: 0.6;
//     border: 1px solid #dadee4;
//   }
//   ::-webkit-scrollbar-thumb {
//     width: 6px;
//     // height: 120px;
//     background: #888888;
//     border-radius: 4px;
//   }
// }
</style>

