<!--满意度评价-->
<template>
  <div class="box" v-loading='loading'>
    <div class="box_head">
      <div class="box_title">
        <!-- 满意度评价 -->
        <span>{{$t('c542')}}</span>
        <svg aria-hidden="true" style="margin-left: 8px" @click="refresh()">
          <use href="#icon-shuaxin-changtai"></use>
        </svg>
      </div>
      <!-- 编辑删除组件 -->
      <DeleteEdit
        class="delete_edit"
        v-if="isShowExtendBtnDropdown"
        :isEdit="true"
        :cpnName="SatisfactionData.name"
        :cpnId="SatisfactionData.i"
        :cpnObject="SatisfactionData"
      />
    </div>
    <div class="card_box">
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: space-around;
          margin-top: 16px;
           margin-left: 16px;
        "
      >
        <div class="card">
          <!-- 收到评价 -->
          <p>{{$t('c543')}}</p>
          <p>{{ receivedEvaluation }}</p>
        </div>
        <div class="card">
          <!-- 好评 -->
          <p>{{$t('label.evaluation.praise')}}</p>
          <p>
            {{ praise }}
            <svg class="" aria-hidden="true" style="width: 28px; height: 28px">
              <use href="#icon-assess_facce1_good"></use>
            </svg>
          </p>
          <el-progress
            :percentage="parseInt(praise)"
            color="#00C56D"
            :show-text="false"
          ></el-progress>
        </div>
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: space-around;
          margin-top: 40px;
          margin-left: 16px;
        "
      >
        <div class="card">
          <!-- 一般 -->
          <p>{{$t('label.evaluation.general')}}</p>
          <p>
            {{ commonly }}
            <svg class="" aria-hidden="true" style="width: 28px; height: 28px">
              <use href="#icon-assess_face1_commonly"></use>
            </svg>
          </p>
          <el-progress
            :percentage="parseInt(commonly)"
            color="#F9D200"
            :show-text="false"
          ></el-progress>
        </div>
        <div class="card">
          <!-- 差评 -->
          <p>{{$t('lable.evaluation.badreview')}}</p>
          <p>
            {{ negativeComment }}
            <svg class="" aria-hidden="true" style="width: 28px; height: 28px">
              <use href="#icon-assess_face1_bad"></use>
            </svg>
          </p>
          <el-progress
            :percentage="parseInt(negativeComment)"
            color="#FF6C11"
            :show-text="false"
          ></el-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { satisfactionEvaluation } from "./api";
import DeleteEdit from "./DeleteEdit"; //引入编辑删除组件
export default {
  components: {
    DeleteEdit,
  },
  props: {
    SatisfactionData: {
      type: Object,
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // Application: "channel",
      commonly: "",
      negativeComment: "",
      praise: "",
      receivedEvaluation: "",
      loading:""
    };
  },
  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      }
      return undefined
    },
  },
  mounted() {
    this.satisfactionEvaluation();
  },
  methods: {
    async satisfactionEvaluation() {
       let res= await satisfactionEvaluation({});
        const { commonly, negativeComment, praise, receivedEvaluation } =res.data
         this.commonly = commonly;
         this.negativeComment = negativeComment;
         this.praise = praise;
         this.receivedEvaluation = receivedEvaluation;
         this.loading=false;
    },
    //刷新
    refresh() {
      this.loading=true;
      this.satisfactionEvaluation();
    },
  },
};
</script>
<style lang='scss' scoped>
.box {
  height: 100%;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #e1e1e1;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  .box_head {
    position: relative;
    padding: 14px 20px 14px 20px;
    display: flex;
    justify-content: space-between;
    .box_title {
      span {
        font-size: 14px;
        font-weight: bold;
        color: #393939;
      }
    }
    .box_select {
      font-size: 14px;
      font-weight: 400;
      color: #393939;
    }
    svg {
      width: 14px;
      height: 14px;
    }
    .delete_edit {
      position: absolute;
      right: 0;
      z-index: 1000;
    }
  }
  .card_box {
    display: flex;
    flex-wrap: wrap;
    margin: 6px 30px 40px 40px;
    .card {
      width: 270px;
      height: 74px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      p:nth-child(1) {
        font-size: 16px;
        font-family: Microsoft YaHei-Semibold, Microsoft YaHei;
        font-weight: 600;
        color: #888888;
      }
      p:nth-child(2) {
        font-size: 30px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #393939;
      }
    }
  }
  ::v-deep .el-progress-bar__outer {
    background: none;
  }
}
</style>