<template>
  <!-- 季度业绩 -->
  <div class="card_div">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="refresh-date">
          <!-- 季度业绩 -->
          <div>{{ $t("vue_label_homepage_quarterlyperformance") }}</div>
          <i
            ref="loadimgbox"
            class="el-icon-refresh-right refresh-class"
            @click="fnrefresh"
          ></i>
          <!-- <i 
           ref="loadimgbox"
           @click="fnrefresh"
           class="iconfont iconshuaxin-changtai refresh-class"
          ></i> -->
          <span class="date">
            {{ $t("label.productsales.refreshTime") }}
            <!-- 上次刷新时间  -->
            {{ refreshTime }}</span
          >
        </div>
        <DeleteEdit
          class="deleteEditJdyj"
          :cpnName="AchievementCardData.name"
          :cpnId="AchievementCardData.i"
          v-if="isShowExtendBtnDropdown"
          :cpnObject="AchievementCardData"
          :isEdit="true"
        />
      </div>
      <div class="content">
        <!-- 左侧数据 -->
        <div class="markInfor">
          <div>
            <p class="title">{{ $t("vue_label_homepage_won") }}</p>
            <p class="won">{{ currency }} {{ end | ThousandSeparator }}</p>
          </div>
          <div>
            <p class="title">
              <!-- 已赢单+未处理 -->
              {{ $t("vue_label_homepage_won&unprocessed") }}
              <!-- 未处理 -->
              <!-- {{ $t("label.weixin.untreated") }}  -->
            </p>
            <p class="unprocessed">
              {{ currency }} {{ untreated | ThousandSeparator }}
            </p>
          </div>
          <div>
            <p class="title">
              {{ $t("label.htyj.mubiao") }}
              <!-- 目标 -->
            </p>
            <span class="title-span mubiao"
              >{{ currency }} {{ target[0] | ThousandSeparator }}</span
            >
            <span
              class="iconfont iconbianjiqianming-changtai iconfont-span"
              v-popover:popover1
            ></span>
          </div>
        </div>
        <!-- 右侧图表 -->
        <div class="canvas-box">
          <v-chart
            ref="creditChart"
            autoresize
            :options="chartOptions"
            style="width: 100%; height: 100%"
          ></v-chart>
        </div>
      </div>

      <!-- 点击目标的气泡 -->
      <el-popover
        popper-class="popoverStyle"
        placement="bottom-start"
        title
        width="280"
        height="143"
        trigger="hover"
        ref="popover1"
      >
        <div class="CNY-box">
          <div class="editTitle">
            {{ $t("label.modify") }}
            <!-- 编辑目标 -->
          </div>
          <div class="setNumber">
            <span>{{ currency }}</span>
            <input type="text" v-model="setTarget" />
          </div>
          <div class="butts">
            <el-button @click="fnCancel()">
              {{ $t("button_partner_cancel") }}
              <!-- 取消 --></el-button
            >
            <el-button type="primary" @click="fnSave()">
              <!-- 保存 -->
              {{ $t("label.save") }}</el-button
            >
          </div>
        </div>
      </el-popover>
    </el-card>
  </div>
</template>
<script>
import * as request from "./api.js";
import DeleteEdit from "./DeleteEdit";
export default {
  props: {
    AchievementCardData: {
      type: Object,
      default: () => ({}),
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    DeleteEdit,
  },
  mounted() {
    this.getHomePageData();
    // 监听index发来的超全局刷新事件，进行单个组件请求
    this.$bus.$on("refreshOverallData", this.getRefresTime);
  },
  destroyed() {
    this.$bus.$off("refreshOverallData",this.getRefresTime);
  },
  data() {
    return {
      chartOptions: {}, //图表数据渲染
      month: [], //月份
      hasEnded: [], // 已赢单
      finishenNotProcessed: [], // 已赢单+未处理
      target: [0], //目标值
      end: 0, //已赢单值
      untreated: 0, //未处理
      setTarget: "", //设置的目标值
      refreshTime: "", //刷新时间
      currency: "", //币种
      // maxNumber: 0,//目标值和所有数据对比的最大值
    };
  },
  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      }
      return undefined
    },
  },
  methods: {
    // 添加百分号和科学计数法
    moneyAddPercent(money) {
      // if (money.indexOf("e") > -1) {
      //   return money;
      // } else
      // if (money.length > 5) {
      //   money = parseFloat((money + "").replace(/[^\d\.-]/g, "")).toExponential(
      //     2
      //   );
      //   return money;
      // } else {
      money = parseFloat((money + "").replace(/[^\d.-]/g, "")) + "";
      let d = money.split(".")[0].split("").reverse();
      let q = "";
      for (let i = 0; i < d.length; i++) {
        q += d[i] + ((i + 1) % 3 == 0 && i + 1 != d.length ? "," : "");
      }
      // return '￥'+q.split("").reverse().join("") + "." + y;
      return q.split("").reverse().join("");
      // }
    },
    // 判断是否未正确数值不包含文字字母
    fncheckNumber(num) {
      var reg = /^[0-9]+.?[0-9]*$/;
      if (reg.test(num) && num.length <= 12) {
        return true;
      }
      return false;
    },
    // 刷新按钮
    fnrefresh() {
      this.$refs.loadimgbox.classList.add("loadimg");
      this.getRefresTime();
    },
    // 刷新时间接口
    async getRefresTime() {
      let parms = { target: this.target[0].toString() };
      let res = await request.getHomePageData({
        id: this.AchievementCardData.i,
        otherConditions: JSON.stringify(parms),
        refresh: "true",
      });
      this.end = res.data.end;
      this.setTarget = res.data.target;
      this.finishenNotProcessed = res.data.finishenNotProcessed;
      this.month = res.data.month;
      this.hasEnded = res.data.hasEnded;
      this.untreated = res.data.untreated;
      this.refreshTime = res.data.refreshTimeStr;
      this.draw();
    },

    // 获取数据
    async getHomePageData() {
      let res = await request.getHomePageData({
        id: this.AchievementCardData.i,
      });
      this.end = res.data.end;
      this.target = [];
      this.target.push(res.data.target);
      this.setTarget = res.data.target;
      this.finishenNotProcessed = res.data.finishenNotProcessed;
      this.month = res.data.month;
      this.hasEnded = res.data.hasEnded;
      this.untreated = res.data.untreated;
      this.refreshTime = res.data.refreshTimeStr;
      this.currency = res.data.currency;
      this.finishenNotProcessed.reduce(function (a, b) {
        return b > a ? b : a;
      });

      this.hasEnded.reduce(function (a, b) {
        return b > a ? b : a;
      });

      this.draw();
    },
    // 设置目标值接口
    async saveSetTarget() {
      let parms = { target: this.target[0] };
      let res = await request.getHomePageData({
        id: this.AchievementCardData.i,
        otherConditions: JSON.stringify(parms),
        refresh: "true",
      });
      this.target = [];
      this.target.push(res.data.target);
      this.draw();
    },
    //保存按钮
    fnSave() {
      if (this.fncheckNumber(this.setTarget)) {
        this.target = [];
        this.target.push(this.setTarget);
        this.saveSetTarget();
        this.$refs.popover1.doClose();
      } else {
        this.$message.error(this.$i18n.t("vue_label_notice_homepage1")); //"请输入12位之内的正确的目标值!"
        this.setTarget = "";
        this.$refs.popover1.doClose();
      }
    },
    //取消按钮
    fnCancel() {
      this.$refs.popover1.doClose();
    },
    formatNum(strNum) {
      if (strNum.length <= 3) {
        return strNum;
      }
      if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(strNum)) {
        return strNum;
      }
      var a = RegExp.$1,
        b = RegExp.$2,
        c = RegExp.$3;
      var re = new RegExp();
      re.compile("(\\d)(\\d{3})(,|$)");
      while (re.test(b)) {
        b = b.replace(re, "$1,$2$3");
      }
      return a + "" + b + "" + c;
    },
    //图表
    draw() {
      let that = this;
      this.chartOptions = {
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let str = params[0].name + "<br />";
            if (params.length === 3) {
              params.forEach((item) => {
                str +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:' +
                  item.color +
                  '"></span>' +
                  item.seriesName +
                  " : " +
                  that.$options.filters.ThousandSeparator(item.value) +
                  " " +
                  that.currency +
                  "<br />";
              });
            } else {
              params.forEach((item) => {
                str +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:' +
                  item.color +
                  '"></span>' +
                  item.seriesName +
                  " : " +
                  that.$options.filters.ThousandSeparator(item.value) +
                  " " +
                  that.currency +
                  "<br />";
              });
              str +=
                '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:rgba(75,202,130, 1)"></span>' +
                that.$i18n.t("label.htyj.mubiao") +
                " : " +
                that.$options.filters.ThousandSeparator(that.target) +
                " " +
                that.currency;
            }

            return str;
          },
        },
        // 图例
        legend: {
          itemHeight: 5,
          itemWidth: 5,
          left: "10px",
          data: [
            {
              name: this.$i18n.t("vue_label_homepage_won"), //"已赢单"
              icon: "rect",
            },

            {
              name: this.$i18n.t("vue_label_homepage_won&unprocessed"),
              icon: "rect",
            },
            {
              name: this.$i18n.t("label.htyj.mubiao"), //"目标值",
              icon: "rect",
            },
          ],
        },
        grid: {
          left: " 150px",
          right: "5%",
          bottom: "4%",
          top: "20%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.month,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            // 目标，是一个标示线
            name: this.$i18n.t("label.htyj.mubiao"), //目标
            type: "line",
            areaStyle: {},
            itemStyle: {
              normal: {
                color: "#4BCA82",
                lineStyle: {
                  color: "#4BCA82",
                  width: 1,
                },
              },
            },
            // 标示线
            markLine: {
              silent: true,
              symbolSize: 1,
              label: {
                normal: {
                  show: true,
                  position: "middle", //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                  formatter: function () {
                    return that.formatNum(that.target[0]);
                  },
                },
              },
              lineStyle: {
                width: 1,
              },
              data: [
                {
                  lineStyle: {
                    //警戒线的样式  ，虚实  颜色
                    type: "solid",
                    color: "#4BCA82",
                  },
                  yAxis: this.target[0], // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                },
              ],
            },
          },

          {
            // 已赢单
            name: this.$i18n.t("vue_label_homepage_won"),
            type: "line",
            data: this.hasEnded,
            showSymbol: false,
            symbolSize: 1,
            itemStyle: {
              normal: {
                color: "rgba(255,183,90, 1)",
                lineStyle: {
                  color: "#ffb75a",
                  width: 1,
                },
              },
            },
            areaStyle: {},
          },
          {
            // 已赢单+未处理
            name: this.$i18n.t("vue_label_homepage_won&unprocessed"), //"已赢单+未处理"
            type: "line",
            data: this.finishenNotProcessed,
            showSymbol: false,
            symbolSize: 1,
            itemStyle: {
              normal: {
                color: "rgba(82,183,217, 1)",
                lineStyle: {
                  color: "#52B7D9",
                  width: 1,
                },
              },
            },
            areaStyle: {},
          },

          {
            // 目标值
            name: this.$i18n.t("label.htyj.mubiao"), //"目标值",
            showSymbol: false,
            symbolSize: 1,
            type: "line",
            data: this.target,
            itemStyle: {
              normal: {
                color: "rgba(75,202,130, 1)",
                lineStyle: {
                  color: "#000000",
                  width: 0,
                },
              },
            },
            areaStyle: {},
          },
        ],
      };
      this.$refs.loadimgbox &&
        this.$refs.loadimgbox.classList.remove("loadimg");
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes loadimg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadimg {
  animation: loadimg 2s linear infinite;
}
.deleteEditJdyj {
  position: absolute;
  right: 30px;
}
::v-deep .box-card {
  height: 100%;
  .el-card__header {
    //   padding: 20px !important;
    //   border: 0px;
    //   .clearfix {
    //     height: 20px;
    //     line-height: 20px;
    //     div:first-child {
    //       font-size: 14px;
    //       font-weight: 500;
    //       color: #080707;
    //       float: left;
    //     }
    //     .img_date {
    //       margin-top: 1px;
    //       .date {
    //         margin-right: 14px;
    //         font-size: 12px;
    //         float: left;
    //         color: #acacac;
    //       }
    //       i {
    //         width: 16px;
    //         height: 16px;
    //         float: left;
    //         margin: 0 3px 0 10px;
    //         cursor: pointer;
    //       }
    //     }
    //   }
  }
  .el-card__body {
    height: calc(100% - 60px);
    .content {
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;
      .markInfor {
        position: absolute;
        top: 100px;
        left: 10px;
        z-index: 99;
        width: 150px;
        word-wrap: break-word;
        word-break: normal;
        font-size: 14px;
        .won {
          color: #ffb75a;
        }
        .unprocessed {
          color: #52b7d9;
        }
        .mubiao {
          color: #4bca82;
        }
        div {
          padding-bottom: 20px;
          text-align: left;

          // img {
          //   margin-left: 5px;
          //   width: 12px;
          //   height: 12px;
          // }
          p {
            margin: 0px;
          }
          .title {
            color: #545453;
            font-size: 12px;
            font-weight: 400;
          }

          .iconfont-span {
            margin-left: 5px;
            color: royalblue;
            cursor: pointer;
            font-size: 16px !important;
          }
        }
      }
      .canvas-box {
        width: 100%;
      }
    }
  }
}
.butts {
  display: flex;
  justify-content: flex-end;
  padding-right: 23px;
  .el-button {
    padding: 6px 8px;
    border-radius: 4px;
  }
  // ::v-deep .el-button--default {
  //   background-color: #fff;
  //   color: #302c2c;
  //   border-color: #dddbda;
  // }
  // primary
  ::v-deep .el-button--primary {
    // background-color: #005fb2;
    // border-color: #005fb2;
    margin-right: 6.5px;
  }
}
.CNY-box {
  width: 250px;
  height: 130px;
  margin-top: 12px;
  margin-left: 21px;
  .editTitle {
    color: #080707;
  }
  .setNumber {
    height: 34px;
    margin: 10px 0 17px 0;
    color: #080707;
    input {
      border: 1px solid #dddbda;
      border-radius: 3px;
      height: 34px;
      width: 185px;
      padding-left: 5px;
    }

    span {
      margin-right: 11px;
      font-size: 12px;
    }
  }
  .cancel,
  .save {
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
  }
  .cancel {
    border: 1px solid #dddbda;
    color: #1b5297;
    float: right;
    margin-right: 20px;
    border-radius: 3px;
  }
  .save {
    background: #1b5297;
    color: #fff;
    float: right;
    margin-right: 30px;
  }
}
</style>
