<!--
  仪表板首页顶部中的组件：时间人员筛选
-->
<template>
  <div class="div_content">
    <div
      v-show="
        timeUseLocation == 'bulletinboard'
          ? timeConditionTo || personConditionTo
          : true
      "
    >
      {{ $t("vue_label_report_drill") }}：
    </div>
    <!-- 时间下拉框：timeConditionTo为false表示该仪表板没有设置时间筛选项，选择框禁用 -->
    <el-button
      plain
      class="select_div select_time"
      :disabled="!timeConditionTo"
      v-show="timeUseLocation == 'bulletinboard' ? timeConditionTo : true"
    >
      <el-select
        v-model="form.timeValue"
        :placeholder="' ' + $t('time')"
        filterable
        clearable
        @change="fnchooseTime"
        size="mini"
        id="custom_time"
        :disabled="!timeConditionTo"
      >
        <el-option-group
          v-for="(item, index) in timeOptions"
          :key="index"
          :label="item.name"
        >
          <el-option
            v-for="(item1, index1) in item.options"
            :key="index1"
            :label="item1.name || `${item1.yearname}-${item1.quartername}`"
            :value="`${item1.startdate},${item1.enddate}`"
          ></el-option>
        </el-option-group> </el-select
    ></el-button>
    <!-- 时间选择器 -->
    <el-date-picker
      v-model="form.timeValueArr"
      type="datetimerange"
      :range-separator="$t('label.forecast.customforecast.submit.zhi')"
      :start-placeholder="$t('label.schedulejob.setup.schedule.startdate')"
      :end-placeholder="$t('label_chatter_endData')"
      value-format="yyyy-MM-dd HH:mm:ss"
      ref="datePickerRef"
      class="date_style"
      @change="fnchooseCustomTime"
    >
    </el-date-picker>
    <!-- 
      :class="{
        userActive: form.userLabel !== this.$i18n.t('label_tabpage_rolez'),
      }" -->
    <!-- 用户下拉框：timeConditionTo为false表示该仪表板没有设置人员筛选项，选择框禁用 -->
    <el-button
      plain
      class="select_div select_person"
      v-popover:userPopover
      :disabled="!personConditionTo"
      v-show="timeUseLocation == 'bulletinboard' ? personConditionTo : true"
    >
      <div style="padding-left: 5px;height:28px;line-height:28px">{{ form.userLabel }}</div>
      <i class="el-icon-arrow-down"></i>
    </el-button>
    <!-- 用户下拉选择之后的tree气泡 -->
    <el-popover
      placement="bottom-start"
      width="300"
      trigger="click"
      ref="userPopover"
      popper-class="user_popper"
      :tabindex="-1"
      @hide="popperClose"
    >
      <el-tree
        :data="treeData"
        ref="tree"
        show-checkbox
        check-on-click-node
        node-key="id"
        default-expand-all
        :props="defaultProps"
      >
      </el-tree>
    </el-popover>
  </div>
</template>

<script>
import timePersonnelFiltrateMin from "@/mixin/timePersonnelFiltrate";

export default {
  mixins: [timePersonnelFiltrateMin],
  props: {
    // 主页看板没有设置筛选条件时隐藏
    timeUseLocation: {
      type: String,
      default: "",
    },
    dashboardid: {
      // 仪表板id
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // value: "",
      form: {
        timeValue: "", // 选择的时间
        timeValueArr: [], // 选择的自定义时间范围
        userValue: "", // 选择的用户value
        userLabel: this.$i18n.t("label_tabpage_rolez"), // 选择的用户label
      },
      defaultProps: {
        children: "childrenList",
        id: "roleId",
        label: "roleName",
      },
    };
  },
  watch: {
    // 每次切换仪表板时，均需要判断该仪表板下是否设置了筛选项配置
    dashboardid: {
      handler: function () {
        this.isSetCondition();
      },
      immediate: true,
    },
    // 监听选择框的禁选操作，清空表单
    timeConditionTo: {
      handler() {
        if (!this.timeConditionTo) {
          this.form.timeValue = "";
          this.form.timeValueArr = [];
          this.form.userValue = "";
          this.form.userLabel = this.$i18n.t("label_tabpage_rolez");
        }
      },
    },
  },
  mounted() {
    this.getDashboardConditionTime(); // 获取仪表板条件时间
    this.getUserList(); // 获取用户所属角色下所有用户
    // 筛选条件配置项中修改条件之后点击应用
    this.$bus.$on("startGetCondition", this.ebFn);
  },
  destroyed() {
    this.$bus.$off("startGetCondition", this.ebFn);
  },
  methods: {
    ebFn(){
      this.isSetCondition(); // 判断该仪表板是否设置了筛选项配置
    },
    // 切换时间
    async fnchooseTime() {
      if (this.form.timeValue.indexOf(undefined) > -1) {
        // 属于自定义时间
        this.$refs.datePickerRef.$el.click(); // 手动控制日期时间选择器打开
        return;
      }
      this.chooseTime();
    },
    // 选择自定义时间范围
    fnchooseCustomTime(value) {
      this.form.timeValue = value.join(",");
      this.chooseTime();
    },
    // 选择完时间，作用于图表
    async chooseTime() {
      let e = {
        type: "time",
        detailTimeKey: this.form.timeValue,
        detailUserKey: this.form.userValue,
      };
      // 切换时间时，获取仪表板下所有图表
      await this.getChartsByDashboardId();
      // 切换时间时，获取该仪表板下设置的筛选条件
      await this.getDashboardConditionByDashboardId();
      // 选择完时间后，作用于图表，获取图表数据
      this.$bus.$emit("getChartsByCondition", e);
    },
    // 监听用户下拉tree气泡关闭方法，获取已选择的用户
    async popperClose() {
      this.form.userLabel = ""; // 清空
      this.form.userValue = ""; // 清空
      let nodeArr = this.$refs.tree.getCheckedNodes();
      // 将选择的所有用户label拼接
      nodeArr.map((item) => {
        this.form.userLabel += item.roleName;
        this.form.userValue += `${item.roleId},`;
      });
      // 选择完人员后，作用于图表，获取图表数据
      let e = {
        type: "user",
        detailTimeKey: this.form.timeValue,
        detailUserKey: this.form.userValue,
      };
      // 切换人员时，获取仪表板下所有图表
      await this.getChartsByDashboardId();
      // 切换人员时，获取该仪表板下设置的筛选条件
      await this.getDashboardConditionByDashboardId();
      // 选择完人员后，作用于图表，获取图表数据
      this.$bus.$emit("getChartsByCondition", e);
      // 判断是否清空了角色
      if (this.form.userLabel === "") {
        this.form.userLabel = this.$i18n.t("label_tabpage_rolez");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.div_content {
  outline: none;
  float: left;
  display: flex;
  align-items: center;
  font-size: 12px;
  ::v-deep .select_div {
    width: 100px;
    height: 28px;
    padding: 0px;
    margin-right: 5px;
    color: #c0c4cc !important;
    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 4px;
        font-size: 12px;
      }
    }
  }
  .el-button.is-disabled,
  .el-button.is-disabled:hover {
    background-color: #f4f4f5;
  }
  .select_time {
    ::v-deep .el-input__inner {
      height: 26px;
      border: none;
      padding: 0;
      padding-left:3px;
      .el-input__icon {
        line-height: 24px;
      }
    }
    ::v-deep .el-input.is-disabled .el-input__inner,
    ::v-deep .el-input.is-disabled .el-input__inner:hover {
      background-color: #f4f4f5 !important;
    }
  }
  .date_style {
    margin-left: -100px;
    width: 100px;
    z-index: -1;
  }
  .select_person {
    span {
      width: 100%;
      i {
        width: 35px;
        font-size: 14px;
      }
    }
  }
  .userActive {
    border: 1px solid #006dcc;
    div {
      color: #006dcc;
    }
  }
}
::v-deep .el-picker-panel {
  top: 208px !important;
  left: 570px !important;
}
</style>

<style>
.user_popper {
  max-height: 266px;
  overflow-y: auto;
}
</style>