<!--
 * @Author: chenwc
 * @Date: 2021-011-08 15:38:38
 * @Description: 工作量排行榜组件
 * @FilePath: src\views\projectManagement\homePage\WorkloadRanking\workloadRanking.vue
-->
<template>
  <el-card class="workloadRankingBox" v-loading="workload">
    <!-- 头部 -->
    <div slot="header" class="flexBox">
      <!-- 工作量排行榜 -->
      <div class="headerTitle">
        <div class="name-refresh">
          {{ $t("front-homepageobject-module-v1-workload-ranking") }}
          <i
            :class="loadingFlag ? 'loadimg' : ''"
            class="el-icon-refresh-right refresh-class loadingIvcon"
            @click="refresh()"
          ></i>
        </div>

        <div class="date-refresh">
          {{ $t("label.productsales.refreshTime") }}
          <!-- 上次刷新时间  -->
          {{ refreshTime }}
        </div>
      </div>
      <div class="leftBox">
        <el-radio-group v-model="selected" @change="changeValue" size="small">
          <!-- 本月 -->
          <el-radio-button label="month">{{
            $t("label.brief.thismonth")
          }}</el-radio-button>
          <!-- 本季度 -->
          <el-radio-button label="quarter">{{
            $t("this.season")
          }}</el-radio-button>
          <!-- 本年度 -->
          <el-radio-button label="year">{{
            $t("front-homepageobject-module-v1-year")
          }}</el-radio-button>
        </el-radio-group>

        <!-- <svg class="img" aria-hidden="true" @click="refresh">
                <use href="#icon-sx-copy"></use>
            </svg> -->
        <!-- 删除组件 -->
        <DeleteEdit
          :cpnName="tableData.name"
          :cpnId="tableData.i"
          v-if="isShowExtendBtnDropdown"
          :cpnObject="tableData"
          :isEdit="true"
        />
      </div>
    </div>
    <!-- 主体 -->
    <div>
      <el-table
        :data="tableDatavalue"
        :height="tableHeight"
        style="width: 100%"
      >
        <!-- 排序 -->
        <el-table-column
          prop="sort"
          :label="$t('label.sort')"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            <div class="centerBox" v-if="scope.row.sort === 1">
              <svg class="sortSvg" aria-hidden="true">
                <use href="#icon-trophy_1"></use>
              </svg>
            </div>
            <div class="centerBox" v-if="scope.row.sort === 2">
              <svg class="sortSvg" aria-hidden="true">
                <use href="#icon-trophy_2"></use>
              </svg>
            </div>
            <div class="centerBox" v-if="scope.row.sort === 3">
              <svg class="sortSvg" aria-hidden="true">
                <use href="#icon-trophy_3"></use>
              </svg>
            </div>
            <span v-if="scope.row.sort > 3">{{ scope.row.sort }}</span>
          </template>
        </el-table-column>
        <!-- 用户 -->
        <el-table-column
          align="center"
          prop="username"
          width="120"
          :label="$t('label.tabpage.userz')"
        >
          <template slot-scope="scope">
            <span class="usernameBox">{{ scope.row.username }}</span>
          </template>
        </el-table-column>
        <!-- 报工工时 -->
        <el-table-column
          align="center"
          prop="workhours"
          :label="$t('front-project-module-v1-actual_working_hours')"
        >
          <template slot-scope="scope">
            <el-progress
              :percentage="scope.row.percentage"
              :stroke-width="14"
              :text-inside="true"
              :format="handleTiem"
              class="progressBox"
            ></el-progress>
          </template>
        </el-table-column>

        <!-- 任务数量 -->
        <!-- <el-table-column
          align="center"
          prop="tasknum"
          :label="$t('front-gantt-module-v1-task-number')"
          width="90"
        >
        </el-table-column> -->
        <!-- 问题数量 -->
        <!-- <el-table-column
          align="center"
          prop="problemnum"
          :label="$t('front-gantt-module-v1-problem-number')"
          width="90"
        >
        </el-table-column> -->
      </el-table>
    </div>
  </el-card>
</template>

<script>
import { queryWorkloadRank } from "./api";
import DeleteEdit from "@/views/homePage/components/DeleteEdit.vue";
export default {
  name: "workloadRanking",
  components: {
    DeleteEdit,
  },
  props: {
    tableData: {
      type: Object,
      default: () => ({}),
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    let time = new Date();
    let year = time.getFullYear();
    let month =
      time.getMonth() + 1 > 9 ? time.getMonth() + 1 : `0${time.getMonth() + 1}`;
    //   当前月有多少天
    let date = new Date(year, time.getMonth() + 1, 0).getDate();
    let timeObj = {
      startDate: `${year}-${month}-01 00:00:00`,
      endDate: `${year}-${month}-${date} 23:59:59`,
    };
    return {
      loadingFlag: false, // loading
      tableHeight: "", // 表格高度
      selected: "month", // 选项按钮选中值
      timeObj, // 查询时间条件
      maxworkhours: 0, // 最大工时
      tableDatavalue: [],
      refreshTime: "2022-02-09 17:58:12", //上次刷新时间
      workload:true,//loading
    };
  },
  created() {
    // 浏览器高度
    let bodyHeight = document.documentElement.clientHeight;
    this.tableHeight = (bodyHeight / 9) * this.tableData.h - 80;
    //   获取视图数据
    this.refresh();
  },
  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    // 处理保工工时数
    handleTiem(val) {
      return `${parseInt((val / 100) * this.maxworkhours)}h`;
    },
    //   更新视图数据
    refresh() {
      this.loadingFlag = true;
      this.workload=true;
      queryWorkloadRank(this.timeObj).then((res) => {
        if (res.result) {
          setTimeout(() => {
            this.workload=false;
          }, 500);
          let tableDatavalue = res.data.list;
          this.refreshTime = res.data.refreshDate;
          this.maxworkhours = Number(tableDatavalue[0].workhours)
            ? Number(tableDatavalue[0].workhours)
            : 0;
          tableDatavalue.forEach((item, index) => {
            item.sort = index + 1;
            if (this.maxworkhours === 0) {
              item.percentage = 0;
            } else {
              item.percentage =
                (Number(item.workhours) / this.maxworkhours) * 100;
            }
          });
          this.tableDatavalue = tableDatavalue;
          this.loadingFlag = false;
        }
      });
    },
    // 选项按钮改变
    changeValue() {
      // 处理时间参数
      this.handleTime();
      // 更新视图数据
      this.refresh();
    },
    // 处理时间
    handleTime() {
      let time = new Date();
      let year = time.getFullYear();
      let month =
        time.getMonth() + 1 > 9
          ? time.getMonth() + 1
          : `0${time.getMonth() + 1}`;
      let monthNumber = time.getMonth() + 1;
      if (this.selected === "month") {
        // 月
        //   当前月有多少天
        let date = new Date(year, time.getMonth() + 1, 0).getDate();
        this.timeObj = {
          startDate: `${year}-${month}-01 00:00:00`,
          endDate: `${year}-${month}-${date} 23:59:59`,
        };
      } else if (this.selected === "quarter") {
        // 季度
        if (monthNumber < 4) {
          this.timeObj = {
            startDate: `${year}-01-01 00:00:00`,
            endDate: `${year}-03-31 23:59:59`,
          };
        } else if (monthNumber < 7) {
          this.timeObj = {
            startDate: `${year}-04-01 00:00:00`,
            endDate: `${year}-06-30 23:59:59`,
          };
        } else if (monthNumber < 10) {
          this.timeObj = {
            startDate: `${year}-07-01 00:00:00`,
            endDate: `${year}-09-30 23:59:59`,
          };
        } else {
          this.timeObj = {
            startDate: `${year}-10-01 00:00:00`,
            endDate: `${year}-12-31 23:59:59`,
          };
        }
      } else {
        // 年
        this.timeObj = {
          startDate: `${year}-01-01 00:00:00`,
          endDate: `${year}-12-31 23:59:59`,
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.loadingIvcon {
  margin-left: 10px;
  cursor: pointer;
}
//刷新
@keyframes loadimg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadimg {
  animation: loadimg 2s linear infinite;
}
.workloadRankingBox {
  width: 100%;
  height: 100%;
  // overflow: auto;
  .centerBox {
    display: flex;
    justify-content: center;
  }
  .sortSvg {
    display: block;
    height: 20px;
    width: 20px;
  }
  ::v-deep .el-table::before {
    display: none;
  }
  ::v-deep .el-table th.is-leaf {
    border-bottom: none;
  }
  ::v-deep .el-table td {
    border-bottom: none;
  }
  .progressBox {
    width: calc(100% - 40px);
    ::v-deep .el-progress-bar__innerText {
      position: absolute;
      color: #606266;
    }
    ::v-deep .el-progress-bar__inner {
      border-radius: 0;
    }
    ::v-deep .el-progress-bar__outer {
      overflow: visible;
      border-radius: 0;
    }
  }
  ::v-deep .el-radio-button__inner {
    padding: 4px 10px;
  }
  ::v-deep .el-table th > .cell {
    font-weight: bold;
    color: #333333;
    word-break: break-word;
  }

  .usernameBox {
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .flexBox {
    display: flex;
    justify-content: space-between;
    .headerTitle {
      font-weight: bold;
      color: #333333;
      .name-refresh{
        display: flex;
        align-items: center;
      }
    }
    .leftBox {
      display: flex;
      align-items: center;
      ::v-deep .el-radio-button:first-child .el-radio-button__inner {
        border-radius: 3px 0px 0px 3px;
      }
      //  .img {
      //   margin-left: 12px;
      //   width: 16px;
      //   height: 16px;
      // }
    }
  }
}
.date-refresh {
  color: #acacac;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
}
</style>