<!--
  简报
-->
<template>
  <el-card class="box-card">
    <!-- 头 -->
    <div slot="header" class="brief_header clearfix">
      <!-- 头部标题 -->
      <div class="titleAndRefresh">
        <div>
          <!-- 简报 -->
          {{ $t("label.brief") }}
        </div>
        <div>
          <i
            ref="loadimgbox"
            class="el-icon-refresh-right refresh-class"
            @click="fnrefresh"
          ></i>
          <span class="date">
            {{ $t("label.productsales.refreshTime") }}
            <!-- 上次刷新时间  -->
            {{ refreshTime }}</span
          >
        </div>
      </div>
      <!-- 头部下拉框 -->
      <div class="dropDown">
        <!-- 请选择 -->
        <el-select
          v-model="timeValue"
          :placeholder="this.$i18n.t('label.select.please')"
          @change="getHomePageDataRefresh"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
        <!-- {{homePageObject}} -->
        <DeleteEdit
          :cpnName="BriefData.name"
          :cpnId="BriefData.i"
          class="edit-box"
          v-if="isShowExtendBtnDropdown"
          :cpnObject="BriefData"
          :isEdit="true"
        />
      </div>
    </div>
    <!-- 内容 -->
    <div class="brief_content">
      <!-- 1 -->
      <div class="content">
        <div class="content_left">
          <div class="left_top">
            <!-- 新增 -->
            {{ $t("label.brief.new") }}
          </div>
          <div class="left_bottom">
            <div>
              <p>
                <!-- 潜在客户 -->
                {{ $t("label.brief.lead") }}
              </p>
              <p class="customer">{{ briefReportData.lead_new_num }}</p>
            </div>
            <div>
              <p>
                <!-- 客户 -->
                {{ $t("label.weixin..xsld.kehu") }}
              </p>
              <p class="customer">{{ briefReportData.account_new_num }}</p>
            </div>
            <div>
              <p>
                <!-- 业务机会 -->
                {{ $t("lable.quote.syncing.opportunity") }}
              </p>
              <p class="customer">{{ briefReportData.opportunity_new_num }}</p>
            </div>
          </div>
        </div>
        <div class="content_right">
          <div class="left_top">
            <!-- 新增 -->
            {{ $t("label.brief.follow") }}
          </div>
          <div class="left_bottom">
            <div>
              <p>
                <!-- 电话 -->
                {{ $t("label.mr.title.phone") }}
              </p>
              <p class="customer">{{ briefReportData.event_phone_num }}</p>
            </div>
            <div>
              <p>
                <!-- 邮件 -->
                {{ $t("label.ems.emails") }}
              </p>
              <p class="customer">{{ briefReportData.event_email_num }}</p>
            </div>
            <div>
              <p>
                <!-- 签到拜访 -->
                {{ $t("label.brief.baifang") }}
              </p>
              <p class="customer">{{ briefReportData.event_qdbf_num }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 2 -->
      <div class="content">
        <div class="content_left">
          <div class="left_top">
            <!-- 成交额 -->
            {{ $t("label.brief.won") }}
          </div>
          <div class="left_bottom">
            <div>
              <p>
                <!-- 合同数 -->
                {{ $t("label.brief.contracts") }}
              </p>
              <p class="customer">
                {{ briefReportData.contract_num }}
                <!-- 个 -->
                {{ $t("label.some") }}
              </p>
            </div>
            <div>
              <p>
                <!-- 合同金额 -->
                {{ $t("label.brief.contract.amount") }}
              </p>
              <p class="customer">{{ briefReportData.htje }}</p>
            </div>
            <div>
              <p>
                <!-- 回款金额 -->
                {{ $t("label.brief.payments") }}
              </p>
              <p class="customer">{{ briefReportData.hkje }}</p>
            </div>
          </div>
        </div>
        <div class="content_right">
          <div class="left_top">
            <!-- 预计关闭机会 -->
            {{ $t("label.brief.opportunity2") }}
          </div>
          <div class="left_bottom">
            <div>
              <p>
                <!-- 机会总数 -->
                {{ $t("label.brief.opportunities") }}
              </p>
              <p class="customer">
                {{ briefReportData.opportunity_total_num }}
                <!-- 个 -->
                {{ $t("label.some") }}
              </p>
            </div>
            <div>
              <p>
                <!-- 丢单机会数 -->
                {{ $t("label.brief.lost.opportunities") }}
              </p>
              <p class="customer">
                {{ briefReportData.opportunity_diu_num }}
                <!-- 个 -->
                {{ $t("label.some") }}
              </p>
            </div>
            <div>
              <p>
                <!-- 赢单机会数 -->
                {{ $t("label.brief.close.opportunities") }}
              </p>
              <p class="customer">
                {{ briefReportData.opportunity_ying_num }}
                <!-- 个 -->
                {{ $t("label.some") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import * as request from "./api.js";
import DeleteEdit from "./DeleteEdit";
export default {
  components: {
    DeleteEdit,
  },
  props: {
    BriefData: {
      type: Object,
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      briefReportData: [], //数据
      refreshTime: "", //刷新时间
      timeValue: "thisweek",
      options: [
        {
          id: "thisweek",
          label: this.$i18n.t("this.week"), //本周
        },
        {
          id: "lastweek",
          label: this.$i18n.t("label.brief.lastweek"), //上周,
        },
        {
          id: "thismonth",
          label: this.$i18n.t("this.month"), //本月,
        },
        {
          id: "lastmonth",
          label: this.$i18n.t("label.event.lastmonth"), //上月,
        },
      ],
    };
  },
  created() {
    this.getHomePageData();
  },
  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      }
      return undefined
    },
  },
  mounted() {
    this.$bus.$on("refreshOverallData", this.getHomePageDataRefresh);
  },
  destroyed() {
    this.$bus.$off("refreshOverallData",this.getHomePageDataRefresh);
  },
  methods: {
    // 获取简报数据接口
    async getHomePageData() {
      let res = await request.getHomePageData({
        id: this.BriefData.i,

        // refresh: "true",
      });
      this.briefReportData = res.data.data;
      this.refreshTime = res.data.refreshTimeStr;
    },
    //刷新时间接口
    async getHomePageDataRefresh() {
      var params = { period: this.timeValue };
      let res = await request.getHomePageData({
        id: this.BriefData.i,
        otherConditions: JSON.stringify(params),
        refresh: "true",
      });
      this.briefReportData = res.data.data;
      this.refreshTime = res.data.refreshTimeStr;
      this.$refs.loadimgbox.classList.remove("loadimg");
    },

    // 刷新按钮
    fnrefresh() {
      this.$refs.loadimgbox.classList.add("loadimg");
      this.getHomePageDataRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
//刷新
@keyframes loadimg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadimg {
  animation: loadimg 2s linear infinite;
}

.box-card {
  width: 100%;
  height: 100%;
  ::v-deep .el-card__body {
    padding: 0 !important;
    height: calc(100% - 61px);
    .brief_content {
      height: 100%;
    }
  }
}
::v-deep .clearfix {
  height: 100%;
}
::v-deep .clearfix::before {
  display: none !important;
}
::v-deep .clearfix::after {
  display: none !important;
}
//头部样式
.brief_header {
  display: flex;
  justify-content: space-between;
  .edit-box {
    cursor: pointer;
  }
  .titleAndRefresh {
    display: flex;
    div:first-child {
      font-size: 14px;
      color: #303133;
      font-weight: bold;
    }
    div:last-child {
      // margin-left: 4px;
      i {
        margin: 0px 3px 0 10px;
        font-size: 14px;
        margin-top: -4px;
        cursor: pointer;
      }
      span {
        font-size: 12px;
        color: #acacac;
      }
    }
  }

  //头部下拉
  .dropDown {
    display: flex;
    width: 124px;
    height: 24px;
    align-items: center;
    ::v-deep .el-select {
      height: 24px;
      margin-right: 4px;
    }
    ::v-deep .el-input__inner {
      height: 24px !important;
      line-height: 24px;
      font-size: 12px;
      padding: 0 6px;
    }
    ::v-deep .el-input__icon {
      line-height: 25px;
    }
  }
}
//内容样式
.brief_content {
  height: 100%;
  .content:first-child {
    border-bottom: 0.5px solid #e0dfdf;
  }
  .content {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // border: 0.5px solid #e0dfdf;
    // padding: 10px 10px;
    .content_left {
      border-right: 0.5px solid #e0dfdf;
    }
    .content_left,
    .content_right {
      width: 50%;
      height: 100%;
      padding: 10px 20px;
      .left_top {
        height: 20%;
        color: #706e6c;
        font-size: 12px;
        margin-top: 4px;
      }
      .left_bottom {
        height: 80%;
        display: flex;
        justify-content: space-between;
        padding-top: 8px;
        div {
          width: 33%;
          font-size: 12px;
          display: flex;
          vertical-align: middle;
          justify-content: center;
          flex-direction: column;
          p:first-child {
            color: #080707;
          }
          .customer {
            flex-grow: 1;
            vertical-align: middle;
            align-items: center;
            display: flex;
          }
        }
      }
    }
  }
}
</style>