<!--
  销售数据
-->
<template>
  <el-card class="box-card content_center">
    <div slot="header" class="clearfixF">
      <div class="titleAndRefresh">
        <!-- 销售数据 -->
        <div>{{ $t("label.salescloud.salesdashboard") }}</div>
        <div>
          <i
            ref="loadimgbox"
            class="el-icon-refresh-right refresh-class"
            @click="fnrefresh"
          ></i>
          <span class="date">
            {{ $t("label.productsales.refreshTime") }}
            <!-- 上次刷新时间  -->
            {{ refreshTime }}</span
          >
        </div>
      </div>
      <div class="right_select_operation">
        <!-- 选择条件下拉框 -->
        <!-- 请选择-->
        <div class="select_div">
          <el-select
            v-model="form.condition"
            :placeholder="$t('label.select.please')"
            @change="getHomePageData"
          >
            <el-option
              v-for="item in conditions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <DeleteEdit
          :cpnName="SalesDatas.name"
          :cpnId="SalesDatas.i"
          v-if="isShowExtendBtnDropdown"
          :cpnObject="SalesDatas"
          :isEdit="true"
        />
      </div>
    </div>
    <div class="chart_div">
      <!-- 合同排行榜 -->
      <el-card class="box-card" v-loading="loadings">
        <div slot="header" class="clearfix">
          <span> {{ $t("label.productsales.contract.phb") }}</span>
        </div>
        <chartsComponent
          v-if="
            showData.htphb.yAxis &&
            showData.htphb.yAxis.length > 0 &&
            showData.htphb.series &&
            showData.htphb.series.length > 0
          "
          dashboardtype="bar_0"
          :groupOneArr="showData.htphb.yAxis"
          :valueDataArr="showData.htphb.series"
        />
        <div
          v-if="
            showData.htphb.yAxis &&
            showData.htphb.yAxis.length == 0 &&
            showData.htphb.series &&
            showData.htphb.series.length == 0
          "
          class="nodata"
        >
          <svg class="icon" aria-hidden="true">
            <use href="#icon-a-BI-defaultmap"></use>
          </svg>
          <div class="title">{{ $t("vue_label_SMS_nodata") }}</div>
        </div>
      </el-card>
      <!-- 回款排行榜 -->
      <el-card class="box-card" v-loading="loadings">
        <div slot="header" class="clearfix">
          <span> {{ $t("label.productsales.payment.phb") }}</span>
        </div>
        <chartsComponent
          v-if="
            showData.hkphb.yAxis &&
            showData.hkphb.series &&
            showData.hkphb.yAxis.length > 0 &&
            showData.hkphb.series.length > 0
          "
          dashboardtype="bar_0"
          :groupOneArr="showData.hkphb.yAxis"
          :valueDataArr="showData.hkphb.series"
        />
        <div
          v-if="
            showData.hkphb.yAxis &&
            showData.hkphb.series &&
            showData.hkphb.yAxis.length == 0 &&
            showData.hkphb.series.length == 0
          "
          class="nodata"
        >
          <svg class="icon" aria-hidden="true">
            <use href="#icon-a-BI-defaultmap"></use>
          </svg>
          <div class="title">{{ $t("vue_label_SMS_nodata") }}</div>
        </div>
      </el-card>
      <!-- 销售漏斗 -->
      <el-card class="box-card" v-loading="loadings">
        <div slot="header" class="clearfix">
          <span>
            {{ $t("label.stdashbord.xsld.title") }}
          </span>
        </div>
        <chartsComponent
          dashboardtype="funnel"
          v-if="showData.xsld.data && showData.xsld.data.length > 0"
          :dataArr="showData.xsld.data"
          @clickFunnel="clickFunnel"
        />
        <div
          v-if="showData.xsld.data && showData.xsld.data.length == 0"
          class="nodata"
        >
          <svg class="icon" aria-hidden="true">
            <use href="#icon-a-BI-defaultmap"></use>
          </svg>
          <div class="title">{{ $t("vue_label_SMS_nodata") }}</div>
        </div>
      </el-card>
      <!-- 合同业绩 -->
      <el-card class="box-card" v-loading="loadings">
        <div slot="header" class="clearfix">
          <span> {{ $t("label.productsales.htyj") }}</span>
        </div>
        <v-chart
          autoresize
          :options="showData.htyj"
          style="width: 100%; height: 100%"
        ></v-chart>
      </el-card>
    </div>
  </el-card>
</template>

<script>
import * as HomePageApi from "./api.js";
import DeleteEdit from "./DeleteEdit";
import chartsComponent from "@/components/Chart/chartsComponent.vue"; // 图表

export default {
  props: {
    SalesDatas: {
      type: Object,
      default: () => ({}),
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
    // 当前缩放组件的对象参数
    currentResizedLayout: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    DeleteEdit,
    chartsComponent,
  },
  data() {
    return {
      showData: {
        htphb: {}, // 合同排行榜
        hkphb: {}, // 汇款排行榜
        xsld: {}, // 销售漏斗
        htyj: {}, // 合同业绩
      },
      refreshTime: "", //刷新时间
      loadings: false, //组件是否刷新状态
      form: {
        condition: "month", // 选择的条件，默认取本月
      },
      conditions: [
        {
          value: "month",
          label: this.$i18n.t("this.month"),
        },
        {
          value: "season",
          label: this.$i18n.t("this.season"),
        },
        {
          value: "year",
          label: this.$i18n.t("this.year"),
        },
        {
          value: "all",
          label: this.$i18n.t("label.quickbooks.view.all"),
        },
      ], // 条件下拉内容
    };
  },
  created() {
    this.getHomePageData();
  },
  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      }
      return undefined
    },
  },
  mounted() {
    this.$bus.$on("refreshOverallData", this.getHomePageDataRefresh);
  },
  destroyed() {
    this.$bus.$off("refreshOverallData",this.getHomePageDataRefresh);
  },
  watch: {
    // 监听组件缩放的对象（改变漏斗图的大小自适应）
    currentResizedLayout: {
      handler() {
        const resizeEvent = new Event("resize");
        window.dispatchEvent(resizeEvent);
      },
      deep: true,
    },
  },
  methods: {
    // 刷新按钮
    fnrefresh() {
      this.$refs.loadimgbox.classList.add("loadimg");
      this.getHomePageDataRefresh();
      setTimeout(() => {
        this.$refs.loadimgbox.classList.remove("loadimg");
      }, 1000);
    },
    // 渲染合同业绩-指针图
    drawGaugeChart() {
      this.showData.htyj = {
        tooltip: {
          formatter: "{a} : {c}",
          trigger: "item",
          textStyle: {
            fontSize: 12,
          },
        },
        series: [
          {
            name: this.showData.htyj.currency,
            type: "gauge",
            radius: "90%",
            startAngle: 180, //开始角度
            endAngle: 0, //结束角度
            min: 0,
            max: this.showData.htyj.htje,
            center: ["50%", "60%"],
            splitLine: {
              distance: -30,
              length: 30,
              lineStyle: {
                color: "#fff",
                width: 2,
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 10,
                color: [
                  [0.3, "#FCB95C"],
                  [0.4, "#2CC7BE"],
                  [1, "#4087F2"],
                ],
                shadowBlur: 1.8,
                shadowColor: "rgba(113, 54, 54, 1)",
              },
            },
            axisLabel: {
              show: true,
              color: "#333",
              distance: 15,
              fontSize: 9,
              formatter: function (value) {
                var num = 1000.0; //byte
                if (value <= num) return value; //千以内
                if (value > num && value < Math.pow(num, 2))
                  return (value / num).toFixed(1) + "K"; //千
                if (value > num && value < Math.pow(num, 3))
                  return (value / Math.pow(num, 2)).toFixed(1) + "M"; //百万
                return (value / Math.pow(num, 3)).toFixed(1) + "G"; //十亿
              },
            },
            pointer: {
              width: "3%", //指针的宽度
              length: "80%", //指针长度，按照半圆半径的百分比
              itemStyle: {
                color: "auto",
              },
            },
            detail: {
              formatter: "{value}",
              offsetCenter: ["0", "30%"],
              textStyle: {
                fontWeight: "bolder",
                fontSize: 18,
              },
            },
            title: {
              //设置仪表盘中间显示文字样式
              offsetCenter: ["0", "20%"],
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                fontSize: 14,
              },
            },
            data: [{ value: this.showData.htyj.htje }],
          },
        ],
      };
    },
    //获取组件数据
    async getHomePageData() {
      let { data } = await HomePageApi.getHomePageData({
        id: this.SalesDatas.i,
      });
      this.refreshTime = data.refreshTimeStr;
      this.showData = data[this.form.condition]; // 获取对应选择条件下的图表数据
      this.drawGaugeChart(); // 合同业绩
    },
    //刷新组件数据
    async getHomePageDataRefresh() {
      this.loadings = true;
      var params = { period: this.timeValue };
      let { data } = await HomePageApi.getHomePageData({
        id: this.SalesDatas.i,
        otherConditions: JSON.stringify(params),
        refresh: "true",
      });
      this.loadings = false;
      this.refreshTime = data.refreshTimeStr;
      this.$refs.loadimgbox.classList.remove("loadimg");
      this.showData = data[this.form.condition]; // 获取对应选择条件下的图表数据
      this.drawGaugeChart(); // 合同业绩
    },
    // 点击销售漏斗，跳转至销售漏斗菜单
    clickFunnel() {
      this.$router.push({
        path: "/commonObjects/hopper",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//刷新
@keyframes loadimg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadimg {
  animation: loadimg 2s linear infinite;
}
.clearfixF {
  display: flex;
  justify-content: space-between;
}
.titleAndRefresh {
  display: flex;
  div:first-child {
    font-size: 14px;
    color: #303133;
    // font-weight: bold;
  }
  div:last-child {
    margin-left: 4px;
    i {
      margin: 0px 5px 0 10px;
      font-size: 14px;
      margin-top: -4px;
      margin: 0px 3px 0 10px;
      cursor: pointer;
    }
    span {
      font-size: 12px;
      color: #acacac;
    }
  }
}
.right_select_operation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .select_div {
  }
  ::v-deep .el-select {
    width: 100px;
    height: 30px;
  }
  ::v-deep .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-input__icon {
    height: 30px;
    line-height: 30px;
  }
}
.content_center {
  height: 100%;
  ::v-deep .el-card__body {
    height: calc(100% - 58px);
    .chart_div {
      height: 100%;
      display: flex;
      justify-content: space-between;

      flex-wrap: wrap;
      .el-card {
        width: 49.8%;
        height: 50%;
        margin-bottom: 8px;
        .nodata {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          svg {
            width: 190px;
            height: 156px;
            margin: 50px auto 0;
          }
          .title {
            margin-top: 20px;
            text-align: center;
            color: #acacac;
          }
        }

        /* display: flex;
        justify-content: space-between;
        flex-wrap: wrap; */
      }
    }
  }
}
</style>