// 标准组件name多语言key和id映射文件
export const objKey={
    // 简报
    brief: 'label.brief',
    //季度
    jdyj: 'vue_label_homepage_quarterlyperformance',
    // 最近记录
    zjjl: 'component_newrecords_label_title',
    // 审批中心
    spzx: 'label.manageAll.title',
    //"销售数据"
    xssj: 'label.salescloud.salesdashboard',
    //我的事件
    todayevent: 'label.myevent',
    // 任务视图
    mytask: 'vue_label_homepage_taskview',
    // 30天未联系的业务机会
    opp30: 'vue_label_homepage_30daysnocontact',
    //临近业务机会
    opplj: 'label.productsales.oppor.approach',
    //已逾期的业务机会
    oppoverdue: 'label.productsales.oppor.overdue',
    //公告
    Bulletin: 'label.callboard',
    //渠道的解决时间
    Channel: 'c540',
    //客服个案统计图
    CustomerService: 'c1735',
    //满意度评价
    Satisfaction: 'c542',
    // 同事圈
    tsquan: 'label.version.chatter.cooperation',
    //工作量排行榜
    gzlphb: 'front-homepageobject-module-v1-workload-ranking',
    //项目甘特图
    xmlbhgtt: 'front-gantt-module-v1-project-gantt',
    
}