<!--
  通用筛选条件
-->
<template>
  <!-- 筛选配置项 -->
  <el-dialog
    :title="$t('vue_label_report_setdrill')"
    :visible="isShowRemittanceListAlert"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="content">
      <div class="filter_content">
        <div class="condition">
          <!-- 通用筛选条件 -->
          {{ $t("vue_label_report_drillcondition") }}
        </div>
        <div class="type-box">
          <!-- 时间类型 -->
          <!-- 标题栏 -->
          <div class="time-name">
            <i
              :class="timeImg ? 'el-icon-caret-bottom' : 'el-icon-caret-right'"
              @click="fntimeImg()"
            ></i>
            <div class="time-type">
              <!-- "时间"类型 -->
              "{{ $t("time") }}"{{ $t("label.wei.polltype") }}
            </div>
          </div>
          <!-- 下拉框列表 -->
          <div
            v-for="(item, index) in dashboardCondition[0].data"
            :key="`time${index}`"
            class="time-box"
          >
            <div class="select" v-if="timeImg">
              <!-- 图表下拉框 -->
              <!-- 请选择  -->
              <el-select
                filterable
                v-model="item.chartid"
                :placeholder="$t('label.select.please')"
                @change="getChartDataById(item.chartid, index, 'time')"
              >
                <el-option
                  v-for="(item1, index1) in allCharts"
                  :disabled="
                    dashboardCondition[0].data.some(
                      (item) => item.chartid === item1.id
                    )
                  "
                  :key="`time-chartid${index1}`"
                  :label="item1.name"
                  :value="item1.id"
                ></el-option>
              </el-select>
              <span>{{ $t("vue.dashboard.setdrill.inThe") }}</span>
              <!-- vue.dashboard.setdrill.inThe  (中的) -->
              <!-- 数据源对象/对象下拉框 -->
              <!-- 请选择  -->
              <el-select
                v-model="item.objid"
                :placeholder="$t('label.select.please')"
                filterable
                @change="changeSourceObject(item.objid, index, 'time')"
              >
                <el-option
                  v-for="(item1, index1) in dashboardConditions[0].data[index]
                    ? dashboardConditions[0].data[index].sourceFiled
                    : []"
                  :key="`time-objid${index1}`"
                  :label="item1.label"
                  :value="item1.value || item1.id"
                  v-show="item1.value || item1.id"
                ></el-option>
              </el-select>
              <span>{{ $t("vue.dashboard.setdrill.inThe") }}</span>
              <!-- vue.dashboard.setdrill.inThe  (中的) -->
              <!-- 字段列表下拉框 -->
              <!-- 请选择  -->
              <el-select
                v-model="item.fieldid"
                filterable
                :placeholder="$t('label.select.please')"
              >
                <el-option
                  v-for="(item1, index1) in dashboardConditions[0].data[index]
                    ? dashboardConditions[0].data[index].TypeFiled
                    : []"
                  :key="`time-fieldid${index1}`"
                  :label="item1.labelName"
                  :value="item1.id"
                ></el-option>
              </el-select>

              <div class="minus-sign" @click="fnminuSign(index, 'time')">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-minus"></use>
                </svg>
              </div>
              <div class="plus" @click="fnplus('time', index)">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-plus"></use>
                </svg>
              </div>
            </div>
          </div>

          <!-- 用户类型 -->
          <!-- 标题栏 -->
          <div class="time-name">
            <i
              :class="
                personImg ? 'el-icon-caret-bottom' : 'el-icon-caret-right'
              "
              @click="fnpersonImg()"
            ></i>
            <div class="time-type">
              <!-- "用户"类型 -->
              "{{ $t("label.user") }}"{{ $t("label.wei.polltype") }}
            </div>
          </div>
          <!-- 下拉框列表 -->
          <div
            v-for="(item, index) in dashboardCondition[1].data"
            :key="`user${index}`"
            class="time-box"
          >
            <div class="select" v-if="personImg">
              <!-- 图表下拉框 -->
              <!-- 请选择  -->
              <el-select
                v-model="item.chartid"
                :placeholder="$t('label.select.please')"
                filterable
                @change="getChartDataById(item.chartid, index, 'user')"
              >
                <el-option
                  v-for="(item1, index1) in allCharts"
                  :disabled="
                    dashboardCondition[1].data.some(
                      (item) => item.chartid === item1.id
                    )
                  "
                  :key="`user-chartid${index1}`"
                  :label="item1.name"
                  :value="item1.id"
                ></el-option>
              </el-select>
              <span>{{ $t("vue.dashboard.setdrill.inThe") }}</span>
              <!-- vue.dashboard.setdrill.inThe  (中的) -->
              <!-- 数据源对象/对象下拉框 -->
              <!-- 请选择  -->
              <el-select
                v-model="item.objid"
                :placeholder="$t('label.select.please')"
                filterable
                @change="changeSourceObject(item.objid, index, 'user')"
              >
                <el-option
                  v-for="(item1, index1) in dashboardConditions[1].data[index]
                    ? dashboardConditions[1].data[index].sourceFiled
                    : []"
                  :key="`user-objid${index1}`"
                  :label="item1.label"
                  :value="item1.value || item1.id"
                  v-show="item1.value || item1.id"
                ></el-option>
              </el-select>
              <span>{{ $t("vue.dashboard.setdrill.inThe") }}</span>
              <!-- vue.dashboard.setdrill.inThe  (中的) -->
              <!-- 字段列表下拉框 -->
              <!-- 请选择  -->
              <el-select
                filterable
                v-model="item.fieldid"
                :placeholder="$t('label.select.please')"
              >
                <el-option
                  v-for="(item1, index1) in dashboardConditions[1].data[index]
                    ? dashboardConditions[1].data[index].TypeFiled
                    : []"
                  :key="`user-fieldid${index1}`"
                  :label="item1.labelName"
                  :value="item1.id"
                  v-show="item1.id"
                ></el-option>
              </el-select>

              <div class="minus-sign" @click="fnminuSign(index, 'user')">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-minus"></use>
                </svg>
              </div>
              <div class="plus" @click="fnplus('user', index)">
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-plus"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="screenCancel">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="screenApplication">
        <!-- 应用 -->
        {{ $t("label.apply") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import mixin from "@/mixin/homepageAndDashboardMixin.js";
import * as request from "./api.js";

export default {
  props: {
    isShowRemittanceListAlert: {
      type: Boolean,
      default: false,
    },
    dashboardid: {
      type: String,
      default: "",
    },
  },
  mixins: [mixin],
  data() {
    return {
      timeSelectIdsArr: [], //时间筛选项数量控制
      personSelectIdsArr: [], //人员筛选数量控制
      // treeSelectIdsArr: [], //角色树筛选数量控制
      timePlusFlag: true, //时间类型能否继续添加筛选条件
      personPlusFlag: true, //人员类型能否继续添加筛选条件
      // treePlusFlag: true, //角色树类型能否继续添加筛选条件
      timeImg: true, //时间筛选项显示隐藏
      personImg: true, //人员筛选项显示隐藏
      // treeImg: true, //角色树筛选项显示隐藏
      reportInfo: {}, // 报表信息对象
      sourceFiledArr: [], // 报表数据源对象集合
      allCharts: [], // 当前仪表板中的所有图表
      // 筛选条件选择项
      dashboardCondition: [
        {
          type: "time",
          data: [
            {
              chartid: "", // 图表id
              objid: "", // 对象id
              fieldid: "", // 字段id
            },
          ],
        },
        {
          type: "user",
          data: [
            {
              chartid: "", // 图表id
              objid: "", // 对象id
              fieldid: "", // 字段id
            },
          ],
        },
      ],
      // 筛选条件下拉内容
      dashboardConditions: [
        {
          type: "time",
          data: [
            {
              sourceFiled: [], // 每个组件下面的对象
              TypeFiled: [], // 数据源下的类型字段
            },
          ],
        },
        {
          type: "user",
          data: [
            {
              sourceFiled: [], // 每个组件下面的对象
              TypeFiled: [], // 数据源下的类型字段
            },
          ],
        },
      ],
    };
  },
  watch: {
    // 监听弹框的显示，当弹框显示时，也去请求获取筛选条件的接口
    isShowRemittanceListAlert: {
      async handler() {
        if (this.isShowRemittanceListAlert) {
          await this.getChartsByDashboardId();
          await this.getDashboardConditionByDashboardId();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    // 获取仪表板筛选项配置
    async getDashboardConditionByDashboardId() {
      let params = {
        dashboardid: this.dashboardid, // 仪表板id
      };
      let { data, result } = await request.getDashboardConditionByDashboardId(
        params
      );
      if (result && data.length > 0) {
        // 先判断筛选项配置中的图表是否还存在于仪表板中，若是不在表示该图表已被删除，则删除对该图表设置的筛选条件
        data.map((item) => {
          this.$set(item, "isHasChart", true);
          if (this.allCharts.every((items) => items.id !== item.chartid)) {
            this.$set(item, "isHasChart", false);
          }
        });
        data = data.filter((item) => item.isHasChart);
        this.$store.commit("setDashboardCondition", data); // 将返回的筛选条件存储到vuex中
        // 清空数据
        this.dashboardCondition = [
          {
            type: "time",
            data: [],
          },
          {
            type: "user",
            data: [],
          },
        ];
        this.dashboardConditions = [
          {
            type: "time",
            data: [],
          },
          {
            type: "user",
            data: [],
          },
        ];
        // 将后台返回的数据放到对应的data中
        data.map((item) => {
          if (item.type === "time") {
            // 时间类型字段
            this.dashboardCondition[0].data.push(item);
            this.dashboardConditions[0].data.push({
              sourceFiled: [],
              TypeFiled: [],
            });
          } else {
            // 用户类型
            this.dashboardCondition[1].data.push(item);
            this.dashboardConditions[1].data.push({
              sourceFiled: [],
              TypeFiled: [],
            });
          }
        });
        // 调用接口获取每个的下拉内容及赋值
        this.dashboardCondition.map((item) => {
          item.data.map(async (item1, index1) => {
            await this.getChartDataById(item1.chartid, index1, item.type, true);
            this.changeSourceObject(item1.objid, index1, item.type, true);
          });
        });
        // 判断data中是否有数据，若是没有，push个空的表单进去
        this.dashboardCondition.map((item) => {
          if (item.data.length === 0) {
            item.data.push({
              chartid: "",
              objid: "",
              fieldid: "",
            });
          }
        });
        this.dashboardConditions.map((item) => {
          if (item.data.length === 0) {
            item.data.push({
              sourceFiled: [],
              TypeFiled: [],
            });
          }
        });
      }
    },
    // 获取仪表板中的所有图表
    async getChartsByDashboardId() {
      let params = {
        dashboardid: this.dashboardid,
      };
      let {
        data: { charts },
      } = await request.getChartsByDashboardId(params);
      this.allCharts = charts; // 获取所有图表列表
    },
    // 切换图表，根据图表id获取使用的报表数据源/对象
    /*-- 
      图表使用不同数据源时，获取对象集合方式不同，获取字段集合方式相同。
      还需要区分是配置的哪种类型，因此给dashboardConditions集合中不同的type的data赋值。
    */
    async getChartDataById(id, index, type, isGetData) {
      // isGetData：是否是通过查询接口直接获取数据
      if (!isGetData) {
        // 非直接获取的数据，则清空
        if (type === "time") {
          this.dashboardCondition[0].data[index].objid = "";
          this.dashboardCondition[0].data[index].fieldid = "";
          this.dashboardConditions[0].data[index].sourceFiled = [];
          this.dashboardConditions[0].data[index].TypeFiled = [];
        } else {
          this.dashboardCondition[1].data[index].objid = "";
          this.dashboardCondition[1].data[index].fieldid = "";
          this.dashboardConditions[1].data[index].sourceFiled = [];
          this.dashboardConditions[1].data[index].TypeFiled = [];
        }
      }
      let params = {
        id: id,
        isRefresh: "false", // 是否采用数据库数据，即最新数据
        isSaveCacheData: "false",
      };
      let {
        data: { reportInfo },
        data: { objectInfo },
      } = await request.getChartDataById(params);
      this.reportInfo = reportInfo;
      this.setSourceFiled(reportInfo, objectInfo, index, type); // 获取对象集合
    },
    // 根据后端返回的图表数据确定属于的数据源及配置的类型，获取对象集合
    setSourceFiled(reportInfo, objectInfo, index, type) {
      this.sourceFiledArr = [];
      // 获取对象集合
      if (reportInfo) {
        // 报表数据源
        this.sourceFiledArr.push(
          {
            value: this.reportInfo.objectAId,
            label: this.reportInfo.objectALabel,
          },
          {
            value: this.reportInfo.objectBId,
            label: this.reportInfo.objectBLabel,
          },
          {
            value: this.reportInfo.objectCId,
            label: this.reportInfo.objectCLabel,
          },
          {
            value: this.reportInfo.objectDId,
            label: this.reportInfo.objectDLabel,
          }
        );
      } else {
        // 列表视图数据源
        this.sourceFiledArr.push(objectInfo);
      }
      // 确定配置类型
      if (type === "time") {
        this.dashboardConditions[0].data[index].sourceFiled =
          this.sourceFiledArr;
      } else {
        this.dashboardConditions[1].data[index].sourceFiled =
          this.sourceFiledArr;
      }
    },
    /*--
      切换数据源对象，获取对应字段集合
    */
    async changeSourceObject(value, index, type, isGetData) {
      if (!isGetData) {
        // 非直接获取的数据，则清空
        if (type === "time") {
          this.dashboardCondition[0].data[index].fieldid = "";
          this.dashboardConditions[0].data[index].TypeFiled = [];
        } else {
          this.dashboardCondition[1].data[index].fieldid = "";
          this.dashboardConditions[1].data[index].TypeFiled = [];
        }
      }
      let TypeFiledArr = await this.getAllFields(value); // 获取字段集合
      // 获取配置类型字段
      this.getFieldsByTypes(type, index, TypeFiledArr);
    },
    // 根据后端返回的图表数据确定属于的数据源及配置的类型，获取字段集合
    async getAllFields(value) {
      let TypeFiledArr = []; // 字段集合
      let params = {
        objectid: value, // 对象id
      };
      let { data } = await request.getFieldsByObjIdForChart(params);
      TypeFiledArr = data.listObjectFields.concat(data.listSummaryFields); // 两个集合合并成一个，作为排序字段集合
      return TypeFiledArr;
    },
    // 获取时间类型字段、用户类型字段，参数含义：筛选类型、所属index、字段集合
    getFieldsByTypes(type, index, TypeFiledArr) {
      if (type === "time") {
        // 时间类型字段：筛选出字段类型为F和D的，表示时间类型字段
        this.dashboardConditions[0].data[index].TypeFiled = TypeFiledArr.filter(
          (item) => item.schemefieldType === "F" || item.schemefieldType === "D"
        );
      } else if (type === "user") {
        // 创建人、所有人、最后修改人
        this.dashboardConditions[1].data[index].TypeFiled = TypeFiledArr.filter(
          (item) =>
            item.schemefieldName === "createbyid" ||
            item.schemefieldName === "ownerid" ||
            item.schemefieldName === "lastmodifybyid"
        );
      }
    },
    //取消按钮
    screenCancel() {
      this.$emit("screenCancel");
      this.clearData();
    },
    //应用按钮
    screenApplication() {
      /*--
        参数名	必选	类型	说明
        dashboardid	是	string	仪表板id
        dashboardCondition	是	JSONArray	仪表板condition
        type	是	string	类型（time,user）
        data	是	JSONArray	具体数值
        chartid	是	string	chartid
        objid	是	string	objid
        fieldid	是	string	fieldid
      */
      //  判断是否有空值，若是选择的图表为空则不包含进去
      this.dashboardCondition.map((item) => {
        item.data.map((item1, index1, data) => {
          if (!item1.chartid) {
            // chartid为空
            data.splice(index1, 1);
          }
        });
      });
      let params = {
        dashboardid: this.dashboardid,
        dashboardCondition: this.dashboardCondition,
      };
      this.$emit("screenApplication", params);
      this.clearData();
    },
    // 时间筛选点击收起图标隐藏筛选项
    fntimeImg() {
      this.timeImg = !this.timeImg;
    },
    // 员工筛选点击收起图标隐藏筛选项
    fnpersonImg() {
      this.personImg = !this.personImg;
    },
    // 增加条件，点击加号增加筛选项
    fnplus(value) {
      //超过选项则不能添加筛选条件
      //时间类型
      if (this.allCharts.length <= this.dashboardCondition[0].data.length) {
        this.timePlusFlag = false;
      } else {
        this.timePlusFlag = true;
      }
      //人员类型
      if (this.allCharts.length <= this.dashboardCondition[1].data.length) {
        this.personPlusFlag = false;
      } else {
        this.personPlusFlag = true;
      }
      // 添加筛选条件
      if (value == "time" && this.timePlusFlag) {
        this.dashboardCondition[0].data.push({
          chartid: "",
          objid: "",
          fieldid: "",
        });
        this.dashboardConditions[0].data.push({
          sourceFiled: [],
          TypeFiled: [],
        });
      } else if (value == "user" && this.personPlusFlag) {
        this.dashboardCondition[1].data.push({
          chartid: "",
          objid: "",
          fieldid: "",
        });
        this.dashboardConditions[1].data.push({
          sourceFiled: [],
          TypeFiled: [],
        });
      }
    },
    // 删除条件，点击减号删除筛选项
    fnminuSign(i, value) {
      if (value == "time") {
        if (this.dashboardCondition[0].data.length > 1) {
          this.dashboardCondition[0].data.splice(i, 1);
        } else {
          this.dashboardCondition[0].data = [
            {
              chartid: "",
              objid: "",
              fieldid: "",
            },
          ];
          this.dashboardConditions[0].data = [
            {
              sourceFiled: [],
              TypeFiled: [],
            },
          ];
          this.timeSelectIdsArr = [];
        }
      } else if (value == "user") {
        if (this.dashboardCondition[1].data.length > 1) {
          this.dashboardCondition[1].data.splice(i, 1);
        } else {
          this.dashboardCondition[1].data = [
            {
              chartid: "",
              objid: "",
              fieldid: "",
            },
          ];
          this.dashboardConditions[1].data = [
            {
              sourceFiled: [],
              TypeFiled: [],
            },
          ];
          this.personSelectIdsArr = [];
        }
      }
    },
    // 清空数据
    clearData() {
      this.dashboardCondition = [
        {
          type: "time",
          data: [
            {
              chartid: "", // 图表id
              objid: "", // 对象id
              fieldid: "", // 字段id
            },
          ],
        },
        {
          type: "user",
          data: [
            {
              chartid: "", // 图表id
              objid: "", // 对象id
              fieldid: "", // 字段id
            },
          ],
        },
      ];
      this.dashboardConditions = [
        {
          type: "time",
          data: [
            {
              sourceFiled: [], // 每个组件下面的对象
              TypeFiled: [], // 数据源下的类型字段
            },
          ],
        },
        {
          type: "user",
          data: [
            {
              sourceFiled: [], // 每个组件下面的对象
              TypeFiled: [], // 数据源下的类型字段
            },
          ],
        },
      ];
    },
    // 点击其他地方关闭弹框，使数据清空
    handleClose() {
      this.clearData();
      this.$emit("screenCancel");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  min-width: 680px !important;
  margin-top: 0 !important;
  max-height: 100%;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  ::v-deep .el-dialog__body {
    height: calc(100% - 120px);
  }
  ::v-deep .el-dialog__header {
    background: #999999;
  }
  ::v-deep .el-dialog__title {
    color: #fff;
  }
  ::v-deep .el-icon-close:before {
    color: #fff;
  }
}

::v-deep .el-dialog__body {
  background: #fff !important;
}
::v-deep .el-dialog__footer {
  background: #fff !important;
}

::v-deep .el-card__body {
  padding: 20px;
  padding-bottom: 0px;
}
.content {
  background: #fff;
  .filter_content {
    flex: 1;
    overflow: auto;
    .type-box {
      margin-left: 35px;
      margin-bottom: 30px;
    }
    .time-name {
      display: flex;
      align-items: center;
      margin: 15px 0 10px 0;
      i {
        font-size: 20px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
    .condition {
      font-size: 12px;
      color: #0e0d0d;
      // margin: 26px 0 0 27px;
      height: 20px;
      min-width: 72px;
      // margin-top: 73px;
    }
    .select {
      ::v-deep .el-select {
        width: 115px;
      }
      ::v-deep .el-input__icon {
        line-height: 25px;
      }
      ::v-deep .el-input__inner {
        height: 30px;
      }
      ::v-deep .el-input {
        font-size: 12px;
      }
      span {
        margin: 0 9px;
        font-size: 12px;
      }
      .plus {
        height: 38px;
        line-height: 38px;
        width: 14px;
        display: inline-block;
        img {
          width: 14px;
          height: 14px;
          // margin-top: 26px;
        }
      }
      .minus-sign {
        display: inline-block;
        height: 38px;
        line-height: 38px;
        margin: 0 20px 0 29px;
        img {
          width: 14px;
          height: 14px;
          // margin-top: 26px;
        }
      }
    }
  }
}

.time-type {
  width: 230px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  background: #f8f8f8;
  border-radius: 3px;
  border: 1px solid #e3e2e2;
  text-align: center;
}

.select {
  ::v-deep .el-select {
    width: 115px;
  }
  ::v-deep .el-input__icon {
    line-height: 25px;
  }
  ::v-deep .el-input__inner {
    height: 30px;
  }
  ::v-deep .el-input {
    font-size: 12px;
  }
  span {
    margin: 0 9px;
    font-size: 12px;
  }

  margin-left: 50px;
}

// 筛选器样式结束

.box-card img {
  width: 12px;
  height: 12px;
  float: right;
}

// 插件弹框

.el-message-box__message span:nth-child(2) {
  padding: 3px 50px;
  border: 1px solid #e2e2e2;
  margin-left: 20px;
}
</style>