<!--客服云个案统计图-->
<template>
  <div class="chart_box" v-loading="loading">
    <div class="chart_left">
      <div class="box_head">
        <div class="box_title">
          <!--当前个案统计数据-->
          <span>{{ $t("c541") }}</span>
          <svg aria-hidden="true" style="margin-left: 8px" @click="refresh()">
            <use href="#icon-shuaxin-changtai"></use>
          </svg>
        </div>
        <!-- 统计数据日期选择下拉组件 -->
        <StatisticalSelect ref="StatisticalSelect" :Application="Application" />
        <!-- 编辑删除组件 -->
        <DeleteEdit
          class="delete_edit"
          v-if="isShowExtendBtnDropdown"
          :isEdit="true"
          :cpnName="CustomerServiceData.name"
          :cpnId="CustomerServiceData.i"
          :cpnObject="CustomerServiceData"
        />
      </div>
      <div id="myChart" style="height: 450px"></div>
      <div class="box_foot">
        <div class="tongji" 
        v-for="item in tongji" :key="item.index" 
        @click="choose(item)"
        >
          <!-- 收到个案总数 -->
          <span style="margin-left:15px">{{item.title}}</span>
          <div style="display:flex;">
             <svg class="" aria-hidden="true" style="width: 60px; height: 24px;">
            <use :href="item.status===false?item.icons:item.icon"></use>
          </svg>
           <svg class="" aria-hidden="true" style="width: 40px; height: 24px">
            <use :href="item.status===false?item.btnIcons:item.btnIcon"></use>
          </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="chart_right">
      <div class="right_box">
        <div class="wai_box">
          <div class="dataAnalysis">
            <!-- 未解决 -->
            <p>{{ $t("c535") }}</p>
            <p>{{ unresolved }}</p>
          </div>
          <div class="dataAnalysis">
            <!-- 收到 -->
            <p>{{ $t("c536") }}</p>
            <p>{{ received }}</p>
          </div>
        </div>
        <div class="wai_box">
          <div class="dataAnalysis">
            <!-- 平均响应时间 -->
            <p>{{ $t("c537") }}</p>
            <p>{{ AverageResponseTime }}</p>
          </div>
          <div class="dataAnalysis">
            <!-- 平均解决时间 -->
            <p>{{ $t("c538") }}</p>
            <p>{{ averageResolutionTime }}</p>
          </div>
        </div>
        <div class="wai_box">
          <div class="dataAnalysis">
            <!-- 信息来源占比最大 -->
            <p>{{ $t("c539") }}</p>
            <p>
              {{ largestProportion }}
              <span v-if="parseInt(largestProportionpercentage) == 0">{{
                largestProportionpercentage
              }}</span>
              <span v-else>({{ largestProportionpercentage }})</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteEdit from "./DeleteEdit"; //引入编辑删除组件
import StatisticalSelect from "./StatisticalSelect"; //引入统计数据日期选择下拉组件
export default {
  components: {
    DeleteEdit,
    StatisticalSelect,
  },
  props: {
    CustomerServiceData: {
      type: Object,
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      Application: "customer",
      loading: false,
      timeArr: [],
      receivedArr: [],
      shelveArr: [],
      upgradeArr: [],
      closeArr: [],
      averageResolutionTime: "",
      largestProportion: "",
      largestProportionpercentage: "",
      received: "",
      unresolved: "",
      Maxvalue: "",
      AverageResponseTime: "",
      myCharts: null, //图表
      chooseCase: [], //选择的个案
      options: [], //控制图表显示隐藏
      tongji:[
        {
          title:this.$i18n.t('c557'),
          icon:"#icon-icon_casechart_receive",
          icons:"#icon-icon_casechart_receive_def",
          btnIcon:"#icon-icon_finish_green",
          btnIcons:"#icon-icon_finish_grey",
          status:true,
        },
          {
           title:this.$i18n.t('c558'),
           icon:"#icon-icon_casechart_holdon",
           icons:"#icon-icon_casechart_def",
           btnIcon:"#icon-icon_finish_orange",
           btnIcons:"#icon-icon_finish_grey",
           status:true,
        },
          {
           title:this.$i18n.t('c559'),
           icon:"#icon-icon_casechart_escalated",
           icons:"#icon-icon_casechart_def",
           btnIcon:"#icon-icon_finish_red",
           btnIcons:"#icon-icon_finish_grey",
           status:true,
        },
          {
           title:this.$i18n.t('c560'),
           icon:"#icon-icon_casechart_close",
           icons:"#icon-icon_casechart_def",
           btnIcon:"#icon-icon_finish_blue",
           btnIcons:"#icon-icon_finish_grey",
           status:true,
        },
      ]
    };
  },
  watch: {
    chooseCase() {
      this.drawLine();
    },
  },
  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      }
      return undefined
    },
  },
  mounted() {
    setTimeout(() => {
      this.drawLine();
    }, 1);
  },
  methods: {
    choose(item) {
      let name=item.title;
      if (localStorage.getItem("locale") === "en") {//判断那种语言应用相应数据
        this.options = {
          "Received Cases": true,
          "On Hold Cases": true,
          "Escalated Cases": true,
          "Closed Cases": true,
        };
      } else {
        this.options = {
          '收到的个案': true,
          '搁置的个案': true,
          '升级的个案': true,
          '关闭的个案': true,
        };
      }//判断是否存在该数据 如果没有就加，如果重复就删除（点击第二次删除）
      if (this.chooseCase.indexOf(name) == -1) {
         this.chooseCase.push(name);
         item.status=false;
      } else {
        let index = this.chooseCase.indexOf(name);
        this.chooseCase.splice(index, 1);
        item.status=true;
      }//表格联动处理
      for (let key in this.options) {
        for (let i = 0; i < this.chooseCase.length; i++) {
          if (this.chooseCase[i] === key && this.options[key] === false) {
            this.options[key] = true;
          }
          if (this.chooseCase[i] === key && this.options[key] === true) {
            this.options[key] = false;
          }
        }
      }
    },
    //刷新
    refresh() {
      this.loading = true;
      this.$refs.StatisticalSelect.getIndividualCase(
        this.$refs.StatisticalSelect.value1,
        this.$refs.StatisticalSelect.value2
      );
    }, //图表
    drawLine() {
      let myCharts = window.echarts.init(document.getElementById("myChart"));
      this.myCharts = myCharts;
      let option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "#393939",
          textStyle: {
            color: "#FFFFFF",
            fontSize: "12",
            fontWeight: "400",
            fontFamily: "Microsoft YaHei-Regular, Microsoft YaHei",
          },
        },
        color: ["#31C985", "#FEA143", "#F55F64", "#5789FD"],
        legend: {
          show: false,
          data: [
            this.$i18n.t("c557"),
            this.$i18n.t("c558"),
            this.$i18n.t("c559"),
            this.$i18n.t("c560"),
          ],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.timeArr,
            axisTick: {
              //y轴刻度线
              show: false,
            },
            axisLabel: {
              show: true,
              interval: 0,
              rotate: -40,
              fontSize: 10,
              margin: 10,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            max: this.Maxvalue,
          },
        ],
        series: [
          {
            name: this.$i18n.t("c557"), //收到的个案
            type: "line",
            stack: "Ad",
            smooth: true, //true 为平滑曲线，false为直线
            symbolSize: 10, //折线点的大小
            data: this.receivedArr,
          },
          {
            name: this.$i18n.t("c558"), //搁置的个案
            type: "bar",
            stack: "Ad",
            // emphasis: {
            //   focus: "series",
            // },
            barWidth: "30",
            data: this.shelveArr,
          },
          {
            name: this.$i18n.t("c559"), //升级的个案
            type: "bar",
            stack: "Ad",
            barWidth: "20",
            data: this.upgradeArr,
          },
          {
            name: this.$i18n.t("c560"), //关闭的个案
            type: "bar",
            stack: "Ad",
            barWidth: "20",
            data: this.closeArr,
          },
        ],
      };
      myCharts.resize();
      window.addEventListener("resize", this.windowResizeFn);
      option.legend.selected = this.options;
      // 
      myCharts.setOption(option, true);
    },
    windowResizeFn(myCharts) {
      myCharts.resize();
      document.querySelectorAll(".wai_box .dataAnalysis")[0].style = "";
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResizeFn);
  },
};
</script>

<style lang="scss" scoped>
.chart_box {
  height: 100%;
  display: flex;
  background: #fff;
  .chart_left {
    width: 65%;
    .box_head {
      width: 100%;
      position: relative;
      padding: 14px 20px 14px 20px;
      display: flex;
      justify-content: space-between;
      .box_title {
        span {
          font-size: 14px;
          font-weight: bold;
          color: #393939;
        }
      }
      .box_select {
        font-size: 14px;
        font-weight: 400;
        color: #393939;
      }
      svg {
        width: 14px;
        height: 14px;
      }
      .delete_edit {
        position: absolute;
        right: 0;
        z-index: 1000;
      }
      .slelect_div {
        display: flex;
        flex-wrap: wrap;
        span {
          display: block;
          width: 116px;
          height: 32px;
          padding-left: 12px;
          line-height: 32px;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          cursor: pointer;
          &:hover {
            background: #f3f7ff;
            color: #2d6cfc;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
          }
        }
        .active {
          width: 116px;
          height: 32px;
          background: #eeeeee;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
        }
      }
    }
    .box_foot {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .tongji {
        width: 297px;
        height: 60px;
        background: #ffffff;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        margin: 32px 0 16px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        span{
          display: block;
        }
      }
    }
  }
  .chart_right {
    width: 35%;
    min-width: 400px;
    .right_box {
      display: flex;
      flex-wrap: wrap;
      margin-top: 124px;
      .wai_box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        div:nth-child(1) {
          margin-left: 50px;
        }
        div:nth-child(2) {
          margin-right: 50px;
        }
      }
      .dataAnalysis {
        width: 200px;
        height: 74px;
        background: #ffffff;
        box-shadow: inset 1px 0px 0px 0px #e1e1e1;
        border-radius: 0px 0px 0px 0px;
        margin: 17px 0px 16px 0px;
        opacity: 1;
        p:nth-child(1) {
          font-size: 16px;
          font-family: Microsoft YaHei-Semibold, Microsoft YaHei;
          font-weight: 600;
          color: #888888;
          padding: 4px 12px 8px 12px;
        }
        p:nth-child(2) {
          width: 280px;
          font-size: 30px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #393939;
          padding-left: 12px;
        }
      }
    }
  }
}
</style>