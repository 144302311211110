<!--
 * @Author: chenwc
 * @Date: 2021-011-08 15:38:38
 * @Description: 项目甘特图
 * @FilePath: src\views\homePage\components\projectGantt.vue
-->
<template>
  <el-card class="projectListBox">
    <!-- 头部 -->
    <div slot="header" class="headerBox">
      <!-- 显示项目切换行 -->
      <div class="leftOne" v-show="!isShow">
        <!-- 项目列表 -->
        <div class="flexBox">
          <div class="titleLeft">
            {{ $t("front-gantt-module-v1-project-gantt-list") }}
            <!-- 刷新 -->
            <i
              :class="loadingFlag ? 'loadimg' : ''"
              class="el-icon-refresh-right refresh-class loadingIvcon"
              @click="refresh('once')"
            ></i>
          </div>
          <el-radio-group v-model="selected" @change="refresh('once')">
            <!-- 全部 -->
            <el-radio label="all">{{ $t("label.category.1") }}</el-radio>
            <el-radio label="warning">
              <svg class="img" aria-hidden="true" style="margin-top: 1px">
                <use href="#icon-earlyWarning_red"></use>
              </svg>
            </el-radio>
            <el-radio label="normal">
              <svg class="img" aria-hidden="true" style="margin-top: 1px">
                <use href="#icon-earlyWarning_green"></use>
              </svg>
            </el-radio>
          </el-radio-group>

          <!-- 搜索图标 -->
          <svg class="img searchImg" aria-hidden="true" @click="changeIsShow">
            <use href="#icon-search"></use>
          </svg>
        </div>
        <div class="date-refresh">
          {{ $t("label.productsales.refreshTime") }}
          <!-- 上次刷新时间  -->
          {{ refreshTime }}
          <span v-show="totalNum"> {{ $t("c60") }}:{{ totalNum }} </span>
        </div>
      </div>
      <!-- 显示项目搜索框 -->
      <div class="flexBox" v-if="isShow">
        <!-- 搜索您的项目... -->
        <el-autocomplete
          ref="autocomplete"
          :autofocus="true"
          style="width: 100%; padding: 0 10px; top: 0.5vw"
          v-model="projectName"
          popper-class="poverList"
          :fetch-suggestions="querySearch"
          :placeholder="$t('front-gantt-module-v1-search-project')"
          :trigger-on-focus="false"
          @select="handleSelect"
          @keyup.enter.native="projectkey"
        >
          <i
            slot="suffix"
            @click="clearFun"
            class="el-input__icon el-icon-close"
          ></i
        ></el-autocomplete>
      </div>
      <!-- 右侧显示区 -->
      <div class="title">
        <div>
          <span class="fontBox">{{ $t("label.projectManagement.gantt") }}</span>
          <!-- 当前展示的项目名称 -->
          <span class="projectName">{{ currProject.name }}</span>
          <!-- 完成百分比 -->
          <span class="fontBox"
            >{{ $t("label.projectManagement.percent.complete") }}：</span
          >
          <span class="projectName">{{ currProject.completepercentage }}</span>
        </div>

        <!-- 删除组件 -->
        <DeleteEdit
          :cpnName="tableData.name"
          :cpnId="tableData.i"
          v-if="isShowExtendBtnDropdown"
          :cpnObject="tableData"
          :isEdit="true"
        />
      </div>
    </div>
    <div class="projectList">
      <div class="leftBox" v-loading="loading">
        <el-table
          border=""
          ref="tableRef"
          :data="tableDatavalue"
          :height="cardHeight"
          highlight-current-row
          @row-click="clickRow"
          v-loadmore="loadMore"
          class="tableBox"
        >
          <!-- 预警 -->
          <el-table-column prop="date" align="center" width="70">
            <template slot="header">
              <el-popover
                placement="top-start"
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
                trigger="hover"
                popper-class="my-tooltip table-head-tooltip"
                :content="$t('front-gantt-module-v1-early-warning')"
              >
                <span slot="reference">
                  {{ $t("front-gantt-module-v1-early-warning") }}
                </span>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <svg class="img" aria-hidden="true">
                <use
                  :href="
                    scope.row.warningstatus === 'warning'
                      ? '#icon-earlyWarning_red'
                      : '#icon-earlyWarning_green'
                  "
                ></use>
              </svg>
            </template>
          </el-table-column>
          <!-- 项目名称 -->
          <el-table-column prop="name" align="center">
            <template slot="header">
              <el-popover
                placement="top-start"
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
                trigger="hover"
                popper-class="my-tooltip table-head-tooltip"
                :content="$t('label.projectManagement.entryname')"
              >
                <span slot="reference">
                  {{ $t("label.projectManagement.entryname") }}
                </span>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <el-popover title="" :open-delay="500" trigger="hover">
                <ul>
                  <!-- 项目名称 -->
                  <li>
                    {{ $t("label.projectManagement.entryname") }}：{{
                      scope.row.name
                    }}
                  </li>
                  <!-- 所有人 -->
                  <li>
                    {{ $t("label.chatter.owner") }}：{{ scope.row.ownername }}
                  </li>
                  <!-- 项目状态 -->
                  <li>
                    {{ $t("front-gantt-module-v1-project-status") }}：{{
                      scope.row.status
                    }}
                  </li>
                  <!-- 完成百分比 -->
                  <li>
                    {{ $t("label.projectManagement.percent.complete") }}：{{
                      scope.row.completepercentage
                    }}
                  </li>
                  <!-- 开始日期 -->
                  <li>
                    {{ $t("label.chatter.startDate") }}：{{
                      (scope.row.startdate ? scope.row.startdate : "")
                        | datayear
                    }}
                  </li>
                  <!-- 结束日期 -->
                  <li>
                    {{ $t("label.chatter.endData") }}：{{
                      (scope.row.enddate ? scope.row.enddate : "") | datayear
                    }}
                  </li>
                </ul>
                <span slot="reference" class="overBox">{{
                  scope.row.name
                }}</span>
              </el-popover>
            </template>
          </el-table-column>
          <!-- 负责人 -->
          <el-table-column
            prop="ownername"
            width="96"
            align="center"
            show-overflow-tooltip
          >
            <template slot="header">
              <el-popover
                placement="top-start"
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
                trigger="hover"
                popper-class="my-tooltip table-head-tooltip"
                :content="$t('label_person_in_charge')"
              >
                <span slot="reference">
                  {{ $t("label_person_in_charge") }}
                </span>
              </el-popover>
            </template>
          </el-table-column>
          <!-- 查看 -->
          <el-table-column
            prop="id"
            width="70"
            show-overflow-tooltip
            align="center"
            :label="$t('label.emailtocloudcc.button.view')"
          >
            <template>
              <el-link type="primary" :underline="false">
                <!-- 详情 -->
                {{ $t("label.detailinf") }}
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="rightBox">
        <!-- 甘特图组件 -->
        <CanttComponent
          :style="{ height: cardHeight + 'px' }"
          class="ganttBox"
          ref="ganttRef"
          :isHomePage="true"
          :proId="projectId"
          @projectGetDate="refresh"
          :key="projectId"
        ></CanttComponent>
      </div>
    </div>
  </el-card>
</template>

<script>
import DeleteEdit from "@/views/homePage/components/DeleteEdit.vue";
import { queryProjectList, getProjectInfo } from "./api";
import CanttComponent from "@/components/cloudcc-gantt/views/index.vue";
import debounce from "lodash.debounce";
export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 10;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value();
          }
        });
      },
    },
  },
  components: { DeleteEdit, CanttComponent },
  name: "projectGantt",
  props: {
    tableData: {
      type: Object,
      default: () => ({}),
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  provide: {
    //   甘特图左侧宽度
    lefeWith: 0,
  },
  data() {
    return {
      projectName: "", // 项目搜索名称
      isShow: false, // 是否显示项目搜索框
      loadingFlag: false, // 刷新loading
      currProject: {}, // 当前展示的项目数据
      cardHeight: 400, // 甘特图高度
      selected: "all", // 选中值
      projectId: "", // 甘特图展示项目id
      // 项目列表list
      tableDatavalue: [],
      refreshTime: "2022-02-09 17:58:12", //上次刷新时间
      totalNum: 0, //总条数
      page: 1, //页数
      pagesize: 15, //每页条数
      nextpage: true, //是否有下一页
      loading: false, //列表loading
    };
  },
  filters: {
    datayear(val) {
      if (val && val !== null) {
        return val.split(" ")[0];
      }
    },
  },
  watch: {
    tableData() {
      // 浏览器高度
      let bodyHeight = document.documentElement.clientHeight;
      let bodyWidth = document.documentElement.clientWidth;
      this.refresh();
      //   获取卡片高度，经过计算，动态设置甘特图和左侧表格高度.浏览器高度平均分成9份
      this.cardHeight =
        (bodyHeight / 9) * this.tableData.h - (bodyWidth / 100) * 7;
      this.$nextTick(() => {
        this.$refs["ganttRef"].ganttoading = true;
        this.$refs.ganttRef.getPIdMilestoneData();
      });
    },
  },
  created() {
    // 浏览器高度
    let bodyHeight = document.documentElement.clientHeight;
    let bodyWidth = document.documentElement.clientWidth;
    this.refresh();
    //   获取卡片高度，经过计算，动态设置甘特图和左侧表格高度.浏览器高度平均分成9份
    this.cardHeight =
      (bodyHeight / 9) * this.tableData.h - (bodyWidth / 100) * 4 + 20;
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs["ganttRef"].ganttoading = true;
      this.$refs.ganttRef.getPIdMilestoneData();
    });
    //   隐藏甘特图拖动条
    let gantt = document.getElementsByClassName("gt-table-slider-line");
    gantt[0].style.display = "none";
  },
  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      }else{
        return true
      }
    },
  },
  methods: {
    // 滚动事件
    loadMore() {
      let that = this;
      // let dom = document.querySelector('.el-table__body-wrapper')
      let dom = document.getElementsByClassName("el-table__body-wrapper")[
        document.getElementsByClassName("el-table__body-wrapper").length - 1
      ];
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 0.5) {
          //等于0证明已经到底，可以请求接口
          if (that.nextpage == "false") {
            that.loading = true;
            that.refresh("next");
          }
        }
      });
    },
    changeIsShow() {
      this.isShow = true;
      this.$nextTick(() => {
        this.$refs.autocomplete.focus();
      });
    },
    projectkey() {
      getProjectInfo({ name: this.projectName }).then((res) => {
        if (res.result) {
          res.data.forEach((item) => {
            item.value = item.name;
            item.completepercentage = item.completePercentage;
            item.startdate = this.handleTime(item.startDate);
            item.enddate = this.handleTime(item.endDate);
            item.status = this.handleStatus(item.completePercentage);
          });
          this.tableDatavalue = res.data;
          this.totalNum = res.data.length;
          this.projectId = res.data[0].id;
          this.$nextTick(() => {
            this.$refs.tableRef.setCurrentRow(this.tableDatavalue[0]);
            this.$refs["ganttRef"].ganttoading = true;
            this.$refs.ganttRef.getPIdMilestoneData();
            //   选中第一行
            document.querySelector(".poverList").style = "display:none";
          });
        }
      });
    },
    //   判断项目状态
    handleStatus(completePercentage) {
      if (completePercentage) {
        if (completePercentage === "100%") {
          // 完成
          return this.$i18n.t("label.emailtocloudcc.button.complete");
        } else if (completePercentage === "0%") {
          // 未开始
          return this.$i18n.t("label.nostart");
        } else {
          // 进行中
          return this.$i18n.t("label.starting");
        }
      } else {
        return "";
      }
    },
    //   时间转换格式
    handleTime(timeStr) {
      if (isNaN(Number(timeStr))) {
        return "";
      } else {
        let time = new Date(Number(timeStr));
        let month =
          time.getMonth() + 1 > 9
            ? time.getMonth() + 1
            : "0" + time.getMonth() + 1;
        let date = time.getDate() > 9 ? time.getDate() : "0" + time.getDate();
        let hours =
          time.getHours() > 9 ? time.getHours() : "0" + time.getHours();
        let minutes =
          time.getMinutes() > 9 ? time.getMinutes() : "0" + time.getMinutes();
        let seconds =
          time.getSeconds() > 9 ? time.getSeconds() : "0" + time.getSeconds();
        return `${time.getFullYear()}-${month}-${date} ${hours}:${minutes}:${seconds}`;
      }
    },
    //   清空
    clearFun() {
      this.projectName = "";
      this.isShow = false;
      //   重新请求数据
      this.refresh("once");
    },
    //   项目选中
    handleSelect(item) {
      this.currProject = Object.assign({}, item);
      this.projectId = item.id;
      this.tableDatavalue = [this.currProject];
      this.$nextTick(() => {
        this.$refs["ganttRef"].ganttoading = true;
        this.$refs.ganttRef.getPIdMilestoneData();
        //   选中第一行
        this.$refs.tableRef.setCurrentRow(this.tableDatavalue[0]);
      });
    },
    //   项目搜索
    querySearch(queryString, cb) {
      let results = [];
      if (queryString) {
        getProjectInfo({ name: queryString }).then((res) => {
          if (res.result) {
            res.data.forEach((item) => {
              item.value = item.name;
              item.completepercentage = item.completePercentage;
              item.startdate = this.handleTime(item.startDate);
              item.enddate = this.handleTime(item.endDate);
              item.status = this.handleStatus(item.completePercentage);
            });
            results = res.data;
            // 调用 callback 返回建议列表的数据
            cb(results);
          }
        });
      } else {
        // 调用 callback 返回建议列表的数据
        cb(results);
      }
    },
    //   左侧表格行点击事件
    clickRow(row, column) {
      if (column.property === "id") {
        // 进入详情
        const newPage = this.$router.resolve({
          path: `/commonObjects/detail/${row.id}/DETAIL`,
          query: { objId: row.id, from: "/homePageObject/standardPage" },
        });
        window.open(newPage.href, "_blank");
        //  this.$router.push({
        //   path: `/commonObjects/detail/${row.id}/DETAIL`,
        //   query: { objId: row.id }
        // });
      } else {
        this.projectId = row.id;
        this.currProject = row;
        this.$nextTick(() => {
          this.$refs["ganttRef"].ganttoading = true;
          this.$refs.ganttRef.getPIdMilestoneData();
        });
      }
    },
    //   获取项目list数据
    refresh: debounce(function (item) {
      this.loadingFlag = true;
      if (item && item == "next") {
        this.page++;
      } else if (item && item == "once") {
        this.tableDatavalue = [];
        this.page = 1;
        this.currProject = {};
        this.projectId = "";
      } else {
        this.tableDatavalue = [];
      }
      let params = {
        warningStatus: this.selected === "all" ? "" : this.selected,
        cloudccPageSize: this.pagesize,
        cloudccPagination: this.page,
      };

      queryProjectList(params).then((res) => {
        if (res.result) {
          if (res.data.list && res.data.list.length > 0) {
            this.nextpage = res.data.isLast;
            this.tableDatavalue = this.tableDatavalue.concat(res.data.list);
            this.refreshTime = res.data.refreshDate;
            if (JSON.stringify(this.currProject) == "{}") {
              this.currProject = this.tableDatavalue[0];
            }
            if (this.projectId == "") {
              this.projectId = this.tableDatavalue[0].id;
            }
            // this.totalNum = res.data.list.length||0
          }
          this.$nextTick(() => {
            this.$refs["ganttRef"].ganttoading = true;
            this.$refs.ganttRef.getPIdMilestoneData();
            //   选中第一行
            this.$refs.tableRef.setCurrentRow(this.currProject);
          });
          this.loadingFlag = false;
          this.loading = false;
        }
      });
    }, 300),
  },
};
</script>
<style lang="scss" scoped>
.loadingIvcon {
  padding: 0 10px;
  cursor: pointer;
}
//刷新
@keyframes loadimg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadimg {
  animation: loadimg 2s linear infinite;
}
// 超出省略号
.overBox {
  // display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-card__header {
  padding: 0;
}
.projectListBox {
  width: 100%;
  height: 100%;
  overflow: auto;
  .img {
    width: 16px;
    height: 16px;
  }
  .img1 {
    position: absolute;
    top: -1px;
  }
  .searchImg {
    margin-right: 10px;
    cursor: pointer;
  }
  .headerBox {
    display: flex;
    .leftOne {
      border-right: 1px solid #ccc;
    }
    .flexBox {
      width: calc(25vw + 1px);
      height: 2.5vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
    }
    .projectName {
      padding-left: 10px;
      padding-right: 30px;
    }
    .fontBox {
      font-weight: bold;
      color: #333333;
    }
    .titleLeft {
      width: 40%;
      padding-left: 10px;
      // font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .el-radio-group {
      width: 50%;
    }
    ::v-deep .el-radio {
      margin-right: 7px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      float: left;
      margin-top: 4px;
    }

    .title {
      flex: 1;
      display: flex;
      height: 4vw;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
    }
  }
}

.projectList {
  display: flex;

  .leftBox {
    width: 25vw;
    min-width: 256px;
    z-index: 99;
    border-right: 1px solid #ebeef5;
    border-top: 1px solid #ccc;
    //  修改饿了么表格样式
    ::v-deep .el-table td {
      height: 38px;
      line-height: 38px;
      padding: 0;
    }
    ::v-deep .el-table th {
      height: 60px;
      line-height: 60px;
      padding: 0;
      font-weight: bold;
      color: #333333;
      background: #fafaf9;
    }
    .tableBox {
      width: 100%;
    }
  }
  .rightBox {
    flex: 1;
    border-left: 1px solid #dedcda;
    .ganttBox {
      width: calc(100% - 1px);
    }
  }
}
::v-deep .el-table tbody tr:hover > td {
  background-color: #ecf5ff !important;
}
::v-deep .gt-root {
  margin-left: 0px !important;
}
.date-refresh {
  padding-left: 10px;
  color: #acacac;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    float: right;
    padding-right: 10px;
    color: #333;
  }
}
</style>