var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadings),expression:"loadings"}],staticClass:"box-card myCanvas"},[_c('div',{staticClass:"dashboard-content",on:{"mouseenter":function($event){return _vm.enterEcharts()},"mouseleave":function($event){return _vm.leaveEcharts()}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.useLocation !== 'chartFullScreen'),expression:"useLocation !== 'chartFullScreen'"}],ref:"refreshTimeRef",class:!_vm.appreport ? 'head-bar' : 'head-app'},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('img',{attrs:{"id":"logo","src":require("@/assets/images/company-logo.svg"),"alt":""}})]),(_vm.isShowLeftChartInfo)?_c('div',{staticClass:"head-bar-left"},[_c('svg',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.showData.chartInfo.linkageid && _vm.status === '1' && _vm.isEnterEcharts
          ),expression:"\n            showData.chartInfo.linkageid && status === '1' && isEnterEcharts\n          "}],staticClass:"icon linkageImg",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.changeStatus('2')}}},[_c('use',{attrs:{"href":"#icon-chuantou_01"}})]),_c('svg',{directives:[{name:"show",rawName:"v-show",value:(
            ((!_vm.showData.chartInfo.linkageid && _vm.showData.chartInfo.isurl) ||
              _vm.status === '2') &&
            _vm.isEnterEcharts
          ),expression:"\n            ((!showData.chartInfo.linkageid && showData.chartInfo.isurl) ||\n              status === '2') &&\n            isEnterEcharts\n          "}],staticClass:"icon linkageImg",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.changeStatus('1')}}},[_c('use',{attrs:{"href":"#icon-pierce_right_01"}})]),(!_vm.isEnterEcharts)?_c('div',{staticClass:"blank"}):_vm._e(),_c('div',{staticClass:"name_time_div"},[_c('div',{staticClass:"name_div"},[_c('el-tooltip',{staticClass:"item",attrs:{"popper-class":"my-tooltip","effect":"dark","content":_vm.cpnObject.name ? _vm.cpnObject.name : _vm.showData.name,"placement":"bottom-start","open-delay":500}},[_c('div',{staticClass:"title_word"},[_vm._v(" "+_vm._s(_vm.cpnObject.name ? _vm.cpnObject.name : _vm.showData.name)+" ")])]),_c('el-tooltip',{staticClass:"item",attrs:{"popper-class":"my-tooltip","effect":"dark","content":_vm.$t('c656') + '：' + _vm.showData.refreshDate,"placement":"top"}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnterEcharts),expression:"isEnterEcharts"}],ref:"loadimgbox",staticClass:"el-icon-refresh-right refresh-class",on:{"click":_vm.fnrefresh}})])],1),(_vm.showData.chartInfo && _vm.showData.chartInfo.linkageGroupOneID)?_c('div',{staticClass:"time_div"},[_vm._v(" "+_vm._s(_vm.linkageContent)+" ")]):_vm._e()])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.useLocation === 'report' && !_vm.appreport ? 'true' : _vm.isEnterEcharts
        ),expression:"\n          useLocation === 'report' && !appreport ? 'true' : isEnterEcharts\n        "}],staticClass:"head-bar-right"},[(!_vm.changeBlueYes)?_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowRightFullScreen),expression:"isShowRightFullScreen"}],staticClass:"icon screenfullImg",attrs:{"aria-hidden":"true"},on:{"mouseover":_vm.overfullChange}},[_c('use',{attrs:{"href":"#icon-fullScreenWhite"}})]):_c('svg',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowRightFullScreen),expression:"isShowRightFullScreen"}],staticClass:"icon screenfullImg",attrs:{"aria-hidden":"true"},on:{"click":_vm.clickChartFullScreen,"mouseout":_vm.outfullChange}},[_c('use',{attrs:{"href":"#icon-fullScreenBlue"}})]),_c('svg',{directives:[{name:"popover",rawName:"v-popover:popover2",arg:"popover2"},{name:"show",rawName:"v-show",value:(_vm.isShowExtendBtnDropdown),expression:"isShowExtendBtnDropdown"}],staticClass:"icon menu_dot",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":"#icon-operation1"}})])])]),(!_vm.isEmpty && !_vm.isChartDataEmpty && !_vm.DashboardData.chartEmpty)?_c('div',{staticClass:"chart_div"},[(_vm.dashboardtype === 'number')?_c('statistics-chart',{attrs:{"statisticsValue":_vm.changeMicrometerLevel(_vm.showData.statisticsValue),"colorValue":_vm.showData.colorValue,"unit":_vm.showData.chartInfo.unit || _vm.showChartInfos.unit},on:{"clickNumber":_vm.linkagegetData}}):_vm._e(),(_vm.dashboardtype === 'digital')?_c('digital-chart',{attrs:{"mapSummaryField":_vm.showData.mapSummaryField
            ? _vm.showData.mapSummaryField
            : _vm.DashboardData.mapSummaryField,"currentData":_vm.digitalChartData ? _vm.digitalChartData.currentData : '',"growthRate":_vm.digitalChartData ? _vm.digitalChartData.growthRate : '',"lastData":_vm.digitalChartData ? _vm.digitalChartData.lastData : '',"chartInfo":_vm.showData.chartInfo.onegroupdatetype
            ? _vm.showData.chartInfo
            : _vm.DashboardData.chartInfo},on:{"clickNumber":_vm.linkagegetData}}):(
          _vm.dashboardtype === 'tableDetail' ||
          _vm.dashboardtype === 'report_summary'
        )?_c('form-chart',{attrs:{"dashboardtype":_vm.dashboardtype,"tableData":_vm.showData.tableData,"colData":_vm.showData.colData,"mapGroupOneField":_vm.showData.mapGroupOneField || {},"mapGroupTwoField":_vm.showData.mapGroupTwoField,"sourceType":_vm.showData.sourceType},on:{"clickTable":_vm.linkagegetData}}):(_vm.dashboardtype === 'sequential')?_c('sequential-chart',{attrs:{"sequentialChartId":_vm.sequentialChartId ||
          _vm.DashboardData.reportid ||
          _vm.DashboardData.reportInfo.id},on:{"clickTable":_vm.linkagegetData}}):_c('chartsComponent',{attrs:{"dashboardtype":_vm.showChartInfos.dashboardtype,"isEditStatus":_vm.isEditStatus,"groupOneArr":_vm.showChartInfos.groupOneArr,"listChartData":_vm.showChartInfos.listChartData,"valueDataArr":_vm.showChartInfos.valueDataArr,"useLocation":_vm.useLocation,"dataArr":_vm.showChartInfos.dataArr,"seriesArr":_vm.showChartInfos.seriesArr,"dimensionsArr":_vm.showChartInfos.dimensionsArr,"sourceArr":_vm.showChartInfos.sourceArr,"min":_vm.showChartInfos.min,"max":_vm.showChartInfos.max,"mid1":_vm.showChartInfos.mid1,"mid2":_vm.showChartInfos.mid2,"isshowpercent":_vm.showChartInfos.isshowpercent,"isshowvalue":_vm.showChartInfos.isshowvalue,"mapGroupOneFieldLabelName":_vm.showChartInfos.mapGroupOneFieldLabelName,"mapGroupOneField":_vm.showChartInfos.mapGroupOneField,"mapSummaryFieldLabelName":_vm.showChartInfos.mapSummaryFieldLabelName,"mapSummaryWay":_vm.showChartInfos.mapSummaryWay,"objectLable":_vm.showChartInfos.objectLable,"reportName":_vm.showChartInfos.reportName,"unit":_vm.showChartInfos.unit,"showData":_vm.showData,"status":_vm.status},on:{"linkagegetData":_vm.linkagegetData}})],1):_vm._e(),(
        _vm.useLocation === 'chartFullScreen'
          ? _vm.DashboardData.chartEmpty
          : _vm.isChartDataEmpty
      )?_c('div',{staticClass:"nodata"},[_c('svg',{staticClass:"icon",class:_vm.showData.chartInfo && _vm.showData.chartInfo.height <= 3
            ? 'chart-width'
            : '',attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":"#icon-a-BI-defaultmap"}})]),_c('div',{staticClass:"title",class:_vm.showData.chartInfo && _vm.showData.chartInfo.height >= 3
            ? 'titlechart'
            : ''},[_vm._v(" "+_vm._s(_vm.$t("vue_label_SMS_nodata"))+" ")])]):_vm._e(),(
        _vm.useLocation === 'chartFullScreen'
          ? _vm.DashboardData.chartEmpty
          : _vm.isChartDataEmpty
      )?_c('div',{staticClass:"noData_2"},[(_vm.isDelete)?_c('temporarily-no-data',{attrs:{"titleWord":_vm.$t('vue_label_homepage_chart_deleted')}}):_c('temporarily-no-data')],1):_vm._e()]),_c('el-dialog',{attrs:{"title":_vm.$t('label.reportexport'),"visible":_vm.isShowExport,"width":"400px","before-close":_vm.handleClose,"close-on-click-modal":false,"modal-append-to-body":false,"append-to-body":true},on:{"update:visible":function($event){_vm.isShowExport=$event}}},[_c('div',{staticClass:"addchoose"},[_c('el-button',{attrs:{"loading":_vm.waterMarkLoading},on:{"click":function($event){return _vm.exportPdf('png')}}},[_vm._v(" "+_vm._s(_vm.$t("front-lightning-module-v1-dashborad-export-picture"))+" ")]),_c('el-button',{attrs:{"loading":_vm.waterMarkLoading},on:{"click":function($event){return _vm.exportPdf('pdf')}}},[_vm._v(" "+_vm._s(_vm.$t("front-lightning-module-v1-dashborad-export-PDF"))+" ")])],1)]),_c('el-popover',{ref:"popover2",attrs:{"placement":"bottom","trigger":"hover","popper-class":"colPopover"}},[_c('div',{staticClass:"moreOperation"},[_c('div',{on:{"click":_vm.fnchooseEdit}},[_vm._v(" "+_vm._s(_vm.$t("pagecreator_page_button_edit"))+" ")]),_c('div',{on:{"click":_vm.fnchoosedelete}},[_vm._v(" "+_vm._s(_vm.$t("component_setup_tabs_label_delete"))+" ")]),_c('div',{on:{"click":_vm.exportChart}},[_vm._v(_vm._s(_vm.$t("label.reportexport")))]),(_vm.isLinkage)?_c('div',{on:{"click":_vm.fnchooseLinkage}},[_vm._v(" "+_vm._s(_vm.$t("vue_label_dashboard_linkagesettings"))+" ")]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }