<template>
  <el-card class="box-card myCanvas" v-loading="loadings">
    <!-- 
      顶部标题部分：以下情况不显示
        1.图表全屏；
    -->
    <div
      @mouseenter="enterEcharts()"
      @mouseleave="leaveEcharts()"
      class="dashboard-content"
    >
      <div
        :class="!appreport ? 'head-bar' : 'head-app'"
        ref="refreshTimeRef"
        v-show="useLocation !== 'chartFullScreen'"
      >
        <div v-show="false">
          <img id="logo" src="@/assets/images/company-logo.svg" alt="" />
        </div>
        <!-- 左侧：图表名称、刷新按钮、刷新时间 -->
        <div class="head-bar-left" v-if="isShowLeftChartInfo">
          <!-- 联动穿透图标，可以切换 -->
          <!-- 联动状态，如果均设置则默认显示联动 -->
          <svg
            class="icon linkageImg"
            aria-hidden="true"
            v-show="
              showData.chartInfo.linkageid && status === '1' && isEnterEcharts
            "
            @click="changeStatus('2')"
          >
            <use href="#icon-chuantou_01"></use>
          </svg>
          <!-- 穿透状态 -->
          <svg
            class="icon linkageImg"
            aria-hidden="true"
            @click="changeStatus('1')"
            v-show="
              ((!showData.chartInfo.linkageid && showData.chartInfo.isurl) ||
                status === '2') &&
              isEnterEcharts
            "
          >
            <use href="#icon-pierce_right_01"></use>
          </svg>
          <div class="blank" v-if="!isEnterEcharts"></div>
          <!-- 图标右侧的图表名称和刷新时间，换行 -->
          <div class="name_time_div">
            <div class="name_div">
              <!-- 如果是个人主页或简档主页就显示布局组件的 name，如果是仪表板页面就显示仪表组件自己的name -->
              <el-tooltip
                popper-class="my-tooltip"
                class="item"
                effect="dark"
                :content="cpnObject.name ? cpnObject.name : showData.name"
                placement="bottom-start"
                :open-delay="500"
              >
                <!-- 顶部名称，是组件时显示组件名称，在仪表板时显示仪表板名称 -->
                <div class="title_word">
                  {{ cpnObject.name ? cpnObject.name : showData.name }}
                </div>
              </el-tooltip>
              <!-- 刷新 -->
              <el-tooltip
                popper-class="my-tooltip"
                class="item"
                effect="dark"
                :content="$t('c656') + '：' + showData.refreshDate"
                placement="top"
              >
                <i
                  ref="loadimgbox"
                  class="el-icon-refresh-right refresh-class"
                  @click="fnrefresh"
                  v-show="isEnterEcharts"
                ></i>
              </el-tooltip>
            </div>
            <div
              v-if="showData.chartInfo && showData.chartInfo.linkageGroupOneID"
              class="time_div"
            >
              {{ linkageContent }}
            </div>
          </div>
        </div>
        <!-- 右侧：全屏按钮、操作菜单 -->
        <div
          class="head-bar-right"
          v-show="
            useLocation === 'report' && !appreport ? 'true' : isEnterEcharts
          "
        >
          <!-- 图表全屏图标 -->
          <svg
            class="icon screenfullImg"
            v-if="!changeBlueYes"
            v-show="isShowRightFullScreen"
            @mouseover="overfullChange"
            aria-hidden="true"
          >
            <use href="#icon-fullScreenWhite"></use>
          </svg>
          <svg
            class="icon screenfullImg"
            @click="clickChartFullScreen"
            v-else
            v-show="isShowRightFullScreen"
            @mouseout="outfullChange"
            aria-hidden="true"
          >
            <use href="#icon-fullScreenBlue"></use>
          </svg>
          <svg
            class="icon menu_dot"
            v-popover:popover2
            v-show="isShowExtendBtnDropdown"
            aria-hidden="true"
          >
            <use href="#icon-operation1"></use>
          </svg>
        </div>
      </div>
      <!-- 卡片内容：echarts -->
      <div
        class="chart_div"
        v-if="!isEmpty && !isChartDataEmpty && !DashboardData.chartEmpty"
      >
        <!-- 统计数字图表 -->
        <statistics-chart
          v-if="dashboardtype === 'number'"
          :statisticsValue="changeMicrometerLevel(showData.statisticsValue)"
          :colorValue="showData.colorValue"
          :unit="showData.chartInfo.unit || showChartInfos.unit"
          @clickNumber="linkagegetData"
        />

        <!-- 数字图 -->
        <digital-chart
          v-if="dashboardtype === 'digital'"
          @clickNumber="linkagegetData"
          :mapSummaryField="
            showData.mapSummaryField
              ? showData.mapSummaryField
              : DashboardData.mapSummaryField
          "
          :currentData="digitalChartData ? digitalChartData.currentData : ''"
          :growthRate="digitalChartData ? digitalChartData.growthRate : ''"
          :lastData="digitalChartData ? digitalChartData.lastData : ''"
          :chartInfo="
            showData.chartInfo.onegroupdatetype
              ? showData.chartInfo
              : DashboardData.chartInfo
          "
        />

        <!-- 表格图表：分为两种，不带分组的tableDetail和带有分组的report -->
        <form-chart
          v-else-if="
            dashboardtype === 'tableDetail' ||
            dashboardtype === 'report_summary'
          "
          :dashboardtype="dashboardtype"
          :tableData="showData.tableData"
          :colData="showData.colData"
          :mapGroupOneField="showData.mapGroupOneField || {}"
          :mapGroupTwoField="showData.mapGroupTwoField"
          :sourceType="showData.sourceType"
          @clickTable="linkagegetData"
        />

        <!-- 同环比图：根据报表id在组件内部获取数据 -->
        <sequential-chart
          v-else-if="dashboardtype === 'sequential'"
          @clickTable="linkagegetData"
          :sequentialChartId="
            sequentialChartId ||
            DashboardData.reportid ||
            DashboardData.reportInfo.id
          "
        />

        <!-- 图表组件，echarts、highcharts -->
        <chartsComponent
          v-else
          :dashboardtype="showChartInfos.dashboardtype"
          :isEditStatus="isEditStatus"
          :groupOneArr="showChartInfos.groupOneArr"
          :listChartData="showChartInfos.listChartData"
          :valueDataArr="showChartInfos.valueDataArr"
          :useLocation="useLocation"
          :dataArr="showChartInfos.dataArr"
          :seriesArr="showChartInfos.seriesArr"
          :dimensionsArr="showChartInfos.dimensionsArr"
          :sourceArr="showChartInfos.sourceArr"
          :min="showChartInfos.min"
          :max="showChartInfos.max"
          :mid1="showChartInfos.mid1"
          :mid2="showChartInfos.mid2"
          :isshowpercent="showChartInfos.isshowpercent"
          :isshowvalue="showChartInfos.isshowvalue"
          :mapGroupOneFieldLabelName="showChartInfos.mapGroupOneFieldLabelName"
          :mapGroupOneField="showChartInfos.mapGroupOneField"
          :mapSummaryFieldLabelName="showChartInfos.mapSummaryFieldLabelName"
          :mapSummaryWay="showChartInfos.mapSummaryWay"
          :objectLable="showChartInfos.objectLable"
          :reportName="showChartInfos.reportName"
          :unit="showChartInfos.unit"
          :showData="showData"
          :status="status"
          @linkagegetData="linkagegetData"
        />
      </div>
      <div
        class="nodata"
        v-if="
          useLocation === 'chartFullScreen'
            ? DashboardData.chartEmpty
            : isChartDataEmpty
        "
      >
        <svg
          class="icon"
          aria-hidden="true"
          :class="
            showData.chartInfo && showData.chartInfo.height <= 3
              ? 'chart-width'
              : ''
          "
        >
          <use href="#icon-a-BI-defaultmap"></use>
        </svg>
        <div
          class="title"
          :class="
            showData.chartInfo && showData.chartInfo.height >= 3
              ? 'titlechart'
              : ''
          "
        >
          {{ $t("vue_label_SMS_nodata") }}
        </div>
      </div>
      <!-- 暂无数据图片 -->
      <div
        class="noData_2"
        v-if="
          useLocation === 'chartFullScreen'
            ? DashboardData.chartEmpty
            : isChartDataEmpty
        "
      >
        <temporarily-no-data
          v-if="isDelete"
          :titleWord="$t('vue_label_homepage_chart_deleted')"
        />
        <temporarily-no-data v-else />
      </div>
    </div>
    <el-dialog
      :title="$t('label.reportexport')"
      :visible.sync="isShowExport"
      width="400px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <div class="addchoose">
        <el-button :loading="waterMarkLoading" @click="exportPdf('png')">
          <!-- 导出图片 -->
          {{ $t("front-lightning-module-v1-dashborad-export-picture") }}
          <!--  -->
        </el-button>
        <el-button :loading="waterMarkLoading" @click="exportPdf('pdf')">
          <!-- 导出PDF -->
          {{ $t("front-lightning-module-v1-dashborad-export-PDF") }}
          <!--  -->
        </el-button>
      </div>
    </el-dialog>

    <!-- 编辑弹框 -->
    <el-popover
      ref="popover2"
      placement="bottom"
      trigger="hover"
      popper-class="colPopover"
    >
      <div class="moreOperation">
        <!-- <div>复制</div> -->
        <div @click="fnchooseEdit">
          <!-- 编辑 -->
          {{ $t("pagecreator_page_button_edit") }}
        </div>
        <div @click="fnchoosedelete">
          <!-- 删除 -->
          {{ $t("component_setup_tabs_label_delete") }}
        </div>
        <div @click="exportChart">{{ $t("label.reportexport") }}</div>
        <!-- <div @click="addComponToKuOpen">添加到视图库</div> -->
        <!--  -->
        <div @click="fnchooseLinkage" v-if="isLinkage">
          <!-- 联动设置 -->
          {{ $t("vue_label_dashboard_linkagesettings") }}
        </div>
        <!-- 穿透设置 -->
        <!-- <div @click="fnchoosepierce" v-if="!cpnObject.homepageid">
          {{ $t("vue_label_dashboard_penetrationsetting") }}
        </div> -->
      </div>
    </el-popover>
  </el-card>
</template>

<script>
import mixin from "@/mixin/homepageAndDashboardMixin.js";
import JsPDF from "jspdf";
import waterMark from "@/utils/waterMark/callMark.js";
import * as DashboardApi from "./api.js";
import StatisticsChart from "../../dashboardObject/dashboardIndex/components/StatisticsChart"; // 统计数字图表
import DigitalChart from "../../dashboardObject/dashboardIndex/components/DigitalChart"; // 数字图
import SequentialChart from "../../dashboardObject/dashboardIndex/components/SequentialChart"; // 同环比图
import FormChart from "../../dashboardObject/dashboardIndex/components/FormChart"; // 表格图表
import TemporarilyNoData from "@/components/TemporarilyNoData/index.vue"; // 暂无数据
import chartsComponent from "@/components/Chart/chartsComponent.vue"; // 图表
import { toCanvas } from "html-to-image";
import browserApi from "@/utils/browserApi";
export default {
  props: {
    appreport: {
      // 区分移动端报表
      type: String,
      default: "",
    },
    // 组件下标，导出根据下标判断当前导出哪个图表
    assemblySubscript: {
      type: Number,
      default: 0,
    },
    // 当前图表组件数据
    DashboardData: {
      type: Object,
      default: () => {},
    },
    // 仪表板中所有图表位置及数据
    commonDataArr: {
      type: Array,
      default: () => [],
    },
    //当前组件的参数对象
    cpnObject: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },

    /**
     * 当前卡片所在的页面的对象
     * 我使用此对象给组件判断
     * 在简档主页的是否是从自定义页面进来的是否显示扩展菜单
     */
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
    // 当前缩放组件的对象参数
    currentResizedLayout: {
      type: Object,
      default: () => ({}),
    },
    useLocation: {
      // 图表使用位置：报表、仪表板、图表展示全屏、仪表板显示全屏
      type: String,
      default: "",
    },
    /**
     *  是否为编辑状态
     * 非编辑状态不可拖动，图表不可编辑
     */
    isEditStatus: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StatisticsChart,
    DigitalChart,
    FormChart,
    SequentialChart,
    TemporarilyNoData,
    chartsComponent,
  },
  mixins: [mixin],
  data() {
    return {
      linkageContent: "", //联动提示
      changeBlueYes: false,
      changeWhite: false,
      isShowExport: false, //导出弹框
      isEnableWaterMark: false, //是否添加水印
      isshowTimeFlag: true, //是否可以显示全部时间
      // 图表数据
      showData: {
        name: "", // 图表名称
        options: {}, // 图表options
        chartInfo: {}, // 图表基本信息
        reportInfo: {}, // 图表所选择的报表信息
        objectInfo: {}, // 图表所选择的对象信息
        viewInfo: {}, // 图表所选择的视图信息
        mapGroupOneField: {}, // 一级分组字段信息
        mapGroupTwoField: {}, // 二级分组字段信息
        mapSummaryField: {}, //=======
        statisticsValue: "", // number图表类型的统计值
        colorValue: "", // number图表类型具体的色值
        tableData: [], // 表格图表类型的表格行数据
        colData: [], // 表格图表类型的表格列数据
        sourceType: "", // 图表所使用的数据源：报表report、列表视图listView
        digitalData: {}, // 数字图类型的数据
      },
      // 图表展示所需要的信息数据
      showChartInfos: {
        dashboardtype: "", // 图表类型
        groupOneArr: [], // 一级分组数据
        listChartData: [], // 新增图表的data数据 : 同环比双轴图 + 气泡图 + 矩阵树形图 + 目标图 + （雷达图 + 双轴图 + 数字图）
        valueDataArr: [], // 统计数据
        dataArr: [], // data数据
        seriesArr: [], // series数据
        dimensionsArr: [], // dimensions数据
        sourceArr: [], // source数据
        min: "", // 指针图的最小值
        max: "", // 指针图的最大值
        mid1: "", // 指针图的断点1值
        mid2: "", // 指针图的断点2值
        isshowpercent: "false", // 是否显示百分比
        isshowvalue: "false", // 是否显示数值
        mapGroupOneFieldLabelName: "", // 一级分组的字段label
        mapSummaryFieldLabelName: "", // 统计字段的label
        mapSummaryWay: "", // 统计类型
        objectLable: "", // 列表视图数据源对象名称
        reportName: "", // 报表数据源报表名称
        unit: "", // 图表选择的单位
      },
      showDataFullScreen: {}, // 图表全屏使用数据
      status: "1", // 属于联动还是穿透状态，1：联动，2：穿透
      loadings: false, // loading加载
      waterMarkLoading: false, //导出loading
      isEmpty: false, // 返回是否无数据
      isChartDataEmpty: false, //图表数据是否为空
      isEnterEcharts: false, // 鼠标是否进入图表范围
      isDelete: false, // 主页中图表是否已被删除
      newH: 0, //最新的高度
      dashboardtype: "", //图表的case类型
      HighchartsCurrentClickName: "", //漏斗图表的点击事件获取到的当前点击的name
      isSaveCacheData: "false",
      // 是否保存缓存数据。true:保存；false:不保存。
      // 以下情况需保存缓存数据：新建编辑图表后获取时、图表设置联动穿透状态时、点击刷新按钮时、仪表板更改图表位置时。
      detailUserKey: "", // 选择的人员
      detailTimeKey: "", // 选择的时间
      sequentialChartId: "", //同环比报表id
    };
  },

  mounted() {
    this.getLinkagegetDataMethod();
    /**
     * 通过插件遍历时 携带的 id的值，即组件id，
     * 通过此id在每个请求中请求后台获取id对应的数据在组件中展示
     */
    if (this.useLocation === "report_summary" && this.DashboardData) {
      // 在报表中添加图表且图表有数据
      this.getOptionDatas(this.DashboardData);
    } else if (
      this.useLocation === "" ||
      this.useLocation === "dashboardFullScreen"
    ) {
      // 仪表板中添加图表，不刷新不缓存
      this.isSaveCacheData = "false";
      if (this.DashboardData.type === "homepageChart") {
        // 若是主页中直接添加的图表，取relatedid
        this.getChartDataById(this.DashboardData.relatedid);
      } else {
        this.getChartDataById(this.DashboardData.i);
      }
    }

    /**
      该方法只能给页面的添加仪表板页面中的局部全部 组件刷新使用，
    */
    this.$bus.$on("refreshInsideallData", this.ebFn1);

    /**
      设置时间、人员筛选条件，作用于图表
    */
    this.$bus.$on("getChartsByCondition", this.ebFn2);

    /**
     * 注意注意注意:
     * 这个事件只能在headerbar中调用此事件，其他任何时候都不可以调用
     */
    this.$bus.$on("refreshOverallData", this.ebFn3);
    this.$bus.$on('chart_render', this.chartRenderCallback)
    this.$bus.$on('chart_refresh_self', this.chartRefreshSelfCallback)
  },
  destroyed() {
    this.$bus.$off("refreshInsideallData", this.ebFn1);
    this.$bus.$off("getChartsByCondition", this.ebFn2);
    this.$bus.$off("refreshOverallData", this.ebFn3);
    this.$bus.$off("getLinkagegetData", this.ebFn4);
    this.$bus.$off('chart_render', this.chartRenderCallback)
    this.$bus.$off('chart_refresh_self', this.chartRefreshSelfCallback)
  },
  watch: {
    // 监听组件缩放的对象（改变漏斗图的大小自适应）
    currentResizedLayout: {
      handler() {
        const resizeEvent = new Event("resize");
        window.dispatchEvent(resizeEvent);
      },
      deep: true,
    },
    // 监听接收的图表数据，若是从报表编辑页直接获取、图表在展示全屏，均不需再根据id请求接口获取
    DashboardData: {
      handler() {
        if (
          (this.useLocation === "report" ||
            this.useLocation === "chartFullScreen") &&
          this.DashboardData
        ) {
          this.getOptionDatas(this.DashboardData);
        }
      },
      immediate: true, //代表在wacth里声明了reportInfo这个方法之后立即先去执行handler方法
      deep: true,
    },
    // 使用此方法控制主页重新编辑选择组件后，单独更新单个组件的功能
    cpnObject: {
      handler(newObj, oleObj) {
        // 若不是在主页编辑图表，则不需做监听
        if (
          !newObj.layout ||
          !newObj.layout.length ||
          newObj.type === "homepageChart"
        )
          return;
        if (
          newObj.name !== oleObj.name ||
          newObj.layout[0].i !== oleObj.layout[0].i
        ) {
          this.getChartDataByIdRefresh(newObj.layout[0].i);
        }
      },
      deep: true,
    },
  },
  methods: {
    isCurrentFreshEvent(id) {
      return (
        // 报表比较relateid，其他比较i
        (this.DashboardData.type === "homepageChart"
          ? this.DashboardData.relatedid
          : this.DashboardData.i) === id
      );
    },
    /**
     * 获取快照后根据快照渲染图表的监听回调
     * @param {string} id 当前要渲染的图表id 
     * @param {object} data 当前要渲染的图表数据
     */
    chartRenderCallback(id, data){
       if (!this.isCurrentFreshEvent(id)) return;
      this.renderDashboard(data.chartData)
    },
    /**
     * 获取快照后缺少快照数据的需要刷新图表自身的监听回调
     * @param {string} id 当前要渲染的图表id 
     */
    chartRefreshSelfCallback(id){
      if (!this.isCurrentFreshEvent(id)) return;
      this.getChartDataById(id)
    },
    /**
     * 获取图表数据后的处理逻辑
     * @param {object} data 当前要渲染的图表数据
     */
    renderDashboard(data){
      this.loadings = false; // 取消loading
      this.isEmpty = false;
      this.dashboardtype = data.chartInfo.dashboardtype;
      this.showData.refreshDate = data.refreshDate || '--'; // 获取图表刷新时间
      this.showData.chartInfo = data.chartInfo; // 获取图表基本信息
      this.showData.reportInfo = data.reportInfo; // 获取报表基本信息
      this.showData.objectInfo = data.objectInfo; // 获取对象基本信息
      this.showData.viewInfo = data.viewInfo; // 获取视图基本信息
      this.showData.mapGroupOneField = data.mapGroupOneField; // 一级分组字段信息
      this.showData.mapGroupTwoField = data.mapGroupTwoField
        ? data.mapGroupTwoField
        : {}; // 二级分组字段信息
      this.showData.mapSummaryField = data.mapSummaryField; //=======
      this.showData.name = data.chartInfo.name; // 获取图表名称
      this.showData.digitalData = data.digitalData; // 数字图的数据
      this.getOptionDatas(data);
      if (this.dashboardtype === "sequential") {
        this.sequentialChartId = data.chartInfo.reportid;
      }
    },
    ebFn1() {
      if (this.cpnObject.type === "PersonalPageCpn") {
        this.getChartDataByIdRefresh(this.DashboardData.i);
      }
    },
    ebFn2(e) {
      let dashboardCondition = this.$store.getters.getDashboardCondition; // 获取vuex中存储的配置的筛选条件
      let dashboardConditionTimeArr =
        dashboardCondition.dashboardConditionTimeArr; // 时间配置条件
      let dashboardConditionPersonArr =
        dashboardCondition.dashboardConditionPersonArr; // 人员配置条件
      this.isSaveCacheData = "false"; // 不需要更新缓存
      if (e.type === "time") {
        // 时间类型
        this.getChartDataByCondition(
          dashboardConditionTimeArr,
          dashboardConditionPersonArr,
          e
        );
      } else if (e.type === "user") {
        // 人员类型
        this.getChartDataByCondition(
          dashboardConditionPersonArr,
          dashboardConditionTimeArr,
          e
        );
      }
    },
    ebFn3() {
      // 全局刷新图表组件
      this.isSaveCacheData = "true";
      // 主页中全局刷新时，刷新的若是通过组件编辑器直接添加的图表，则取relatedid
      if (this.DashboardData.type === "homepageChart") {
        this.getChartDataByIdRefresh(this.DashboardData.relatedid);
        return;
      }
      this.getChartDataByIdRefresh(this.DashboardData.i);
    },
    ebFn4(e) {
      if (e && this.DashboardData.i == e.id) {
        if (e.linkageGroupOneApi) {
          // 联动
          this.isSaveCacheData = "false";
        } else {
          // 新建编辑、设置联动穿透状态、仪表板更改图表位置时
          this.isSaveCacheData = "true";
        }
        this.getChartDataByIdRefresh(e);
        return;
      }
      // 主页中直接添加的图表，进行编辑时
      if (
        e &&
        this.DashboardData.type === "homepageChart" &&
        this.DashboardData.relatedid == e.id
      ) {
        this.getChartDataByIdRefresh(this.DashboardData.relatedid);
      }
    },
    // 进入图表范围
    enterEcharts() {
      if (!this.appreport) {
        this.isEnterEcharts = true;
      }
    },
    // 离开图表范围
    leaveEcharts() {
      if (!this.appreport) {
        this.isEnterEcharts = false;
      }
    },
    async getExportWatermarkIsOpen() {
      let res = await DashboardApi.getExportWatermarkIsOpen();
      // 对图表添加用户名水印,根据下标判断导出哪个图表
      this.isEnableWaterMark = res.data.data[0].isEnableWaterMark;
      if (this.isEnableWaterMark == "true") {
        let waterMarkCanvas =
          document.getElementsByClassName("myCanvas")[this.assemblySubscript];
        waterMark(
          waterMarkCanvas,
          waterMarkCanvas.clientWidth + "px",
          waterMarkCanvas.clientHeight + "px",
          "999",
          "detail"
        );
      }
    },
    exportChart() {
      // 显示导出弹框
      this.getExportWatermarkIsOpen();
      this.isShowExport = true;
    },
    handleClose() {
      this.isShowExport = false;
    },
    // 导出pdf
    exportPdf(value) {
      this.waterMarkLoading = true;
      setTimeout(() => {
        this.$refs.loadimgbox.style.display = "none";
        // pdf公司logo水印
        let logoimg = document.getElementById("logo");
        var imgcanvas = document.createElement("canvas");
        // pdf公司logo水印
        imgcanvas.width = logoimg.width;
        imgcanvas.height = logoimg.height;
        var ctximg = imgcanvas.getContext("2d");
        ctximg.drawImage(logoimg, 0, 0, logoimg.width, logoimg.height);
        var logoURL = imgcanvas.toDataURL("image/png");

        let ddd =
          document.getElementsByClassName("myCanvas")[this.assemblySubscript];

        //截取页面元素生成图片获取base64文件流
        toCanvas(ddd, {
          dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
          scale: 4, //按比例增加分辨率
          allowTaint: true,
          taintTest: false,
          useCORS: true,
        }).then((canvas) => {
          let contentWidth = canvas.width * 4; //把画布扩大了4倍，提高清晰度
          let contentHeight = canvas.height * 4; //把画布扩大了4倍，提高清晰度
          let pageHeight = (contentWidth / 592.28) * 841.89;
          let leftHeight = contentHeight;
          // let position = 100;
          let imgWidth = 595.28;
          let imgHeight = (592.28 / contentWidth) * contentHeight;
          // png图片下载
          if (value == "png") {
            // 新建一个画布
            var pngcanvas = document.createElement("canvas");
            var chartPng = pngcanvas.getContext("2d");
            // 图表的base编码
            // let chartData = canvas.toDataURL("image/png", 1.0);
            // 把图表和logo的编码合并到同一张画布上
            pngcanvas.width = canvas.width;
            pngcanvas.height = canvas.height + 80;
            chartPng.fillStyle = "#FFF";
            chartPng.fillRect(0, 0, canvas.width, canvas.height + 80);

            chartPng.drawImage(
              canvas,
              0,
              0,
              canvas.width,
              canvas.height,
              0,
              0,
              canvas.width,
              canvas.height
            );

            chartPng.drawImage(
              logoimg,
              0,
              0,
              logoimg.width,
              logoimg.height,
              canvas.width - 142,
              canvas.height + 20,
              122,
              30
            );

            let link = document.createElement("a");
            link.href = pngcanvas.toDataURL("image/png", 1.0);
            let pngName = this.cpnObject.name
              ? this.cpnObject.name
              : this.showData.name;

            link.setAttribute("download", pngName); // 下载文件的名字
            document.body.appendChild(link);
            link.click();
          } else {
            let pageData = canvas.toDataURL("image/png", 1.0);
            // 文件流生成pdf
            let PDF = new JsPDF("", "pt", "a4");
            // 图片logo
            PDF.addImage(
              logoURL,
              "JPEG",
              PDF.internal.pageSize.width - 208,
              PDF.internal.pageSize.height - 100,
              178,
              44
            );
            if (leftHeight < pageHeight) {
              PDF.addImage(
                pageData,
                "JPEG",
                20,
                50,
                imgWidth - 40,
                imgHeight - 50
              );
            } else {
              while (leftHeight > 0) {
                PDF.addImage(
                  pageData,
                  "JPEG",
                  20,
                  50,
                  imgWidth - 40,
                  imgHeight - 50
                );
                leftHeight -= pageHeight;
                // position -= 841.89;
                if (leftHeight > 0) {
                  PDF.addPage();
                }
              }
            }
            // 下载pdf文件
            PDF.save(
              this.cpnObject.name
                ? this.cpnObject.name
                : this.showData.name + ".pdf"
            );
          }
          // 下载后删除水印，不可删除，1detail为水印层classname,否则水印重复
          if (this.isEnableWaterMark == "true") {
            document.getElementById("1detail").style.display = "none";
          }
          this.$refs.loadimgbox.style.display = "block";
          this.waterMarkLoading = false;
          this.isShowExport = false;
        });
      }, 100);
    },
    overfullChange() {
      this.changeBlueYes = true;
    },
    outfullChange() {
      this.changeBlueYes = false;
    },
    // 获取图表数据，取的是缓存数据
    async getChartDataById(e) {
      if (
        (this.DashboardData.homepageid &&
          this.DashboardData.layout &&
          this.DashboardData.layout.length > 0) ||
        (!this.DashboardData.homepageid && e) ||
        this.DashboardData.type === "homepageChart"
      ) {
        // 主页中添加的图表未被删除、仪表板中图表
        let isRefresh = "false";
        let newDate = new Date();
        if (
          this.DashboardData &&
          this.DashboardData.refreshDate &&
          this.$moment(newDate).format("YYYY-MM-DD") !=
            this.$moment(this.DashboardData.refreshDate).format("YYYY-MM-DD")
        ) {
          isRefresh = "true";
        } else {
          // eslint-disable-next-line no-unused-vars
          isRefresh = "false";
        }
        this.isDelete = false;
        this.loadings = true;
        let params = {
          isRefresh: isRefresh,
          isSaveCacheData: isRefresh == "true" ? "true" : "false",
          id: e.id ? e.id : e,
        };
        // 海外环境，临时改为不刷新
        // let params = {
        //   isRefresh: "false",
        //   isSaveCacheData: "false",
        //   id: e.id ? e.id : e,
        // };
        try {
          let { data } = await DashboardApi.getChartDataById(params);
          this.renderDashboard(data)
        } catch (error) {
          this.loadings = false; // 取消loading
          this.isEmpty = true;
        }
      } else {
        // 主页中添加的图表已被删除
        this.isEmpty = true;
        this.isDelete = true;
      }
    },
    // 判断图表是否有数据,不同类型图表，判断的条件不同
    chartIsEmpty(type, data) {
      // 根据不同的图表类型判断数据是否为空
      if (
        (type == "number" ||
          type == "biaxial" ||
          type == "funnel" ||
          type == "donut" ||
          type == "pie" ||
          type == "bar_0" ||
          type == "column_0" ||
          type == "line_0") &&
        ((data.listChartData && data.listChartData.length == 0) ||
          !data.listChartData)
      ) {
        this.isChartDataEmpty = true; //数字图，双轴图，同环比双轴图，漏斗图，雷达图，饼图和圆环图，条形图和柱形图  area
      } else if (type == "matrixTree") {
        // 矩阵树形图有两个统计值,从报表生成和列表视图生成取值不一样
        if (data.listChartData[0].listData) {
          // 报表生成
          this.isChartDataEmpty = data.listChartData[0].listData.every(
            (item) => {
              return item.data.length == 0;
            }
          );
        } else {
          // 列表视图生成
          this.isChartDataEmpty = data.listChartData.every((item) => {
            return item.listChartData.length == 0;
          });
        }
      } else if (type == "radarMap") {
        // 雷达图有多个统计值，判断每个统计值的数组是不是都为空
        if (data.listChartData[0].listData && data.listChartData[0].listGroup) {
          // 报表生成
          this.isChartDataEmpty = data.listChartData[0].listData.every(
            (item) => {
              return item.data.length == 0;
            }
          );
        } else {
          this.isChartDataEmpty = data.listChartData.every((item) => {
            return item.listChartData.length == 0;
          });
        }
      } else if (type == "target") {
        //目标图
        if (data.listChartData[0].target || data.listChartData.target) {
          this.isChartDataEmpty = false;
        } else {
          this.isChartDataEmpty = true;
        }
      } else if (type == "bubble") {
        //气泡图,报表生成图表
        if (data.listChartData[0].listData) {
          this.isChartDataEmpty = data.listChartData[0].listData.every(
            (item) => {
              return item.data == 0;
            }
          );
        } else {
          // 列表视图生成图表
          this.isChartDataEmpty = data.listChartData.every((item) => {
            return item.listChartData.length == 0;
          });
        }
      } else if (type == "biaxialFigure") {
        let filed = Object.keys(data.listChartData[0]);
        this.isChartDataEmpty = filed.every((item) => {
          return data.listChartData[0][item].length == 0;
        });
        //双轴图中有两个统计值，统计值中包含最大值，最小值，平均值，总和，一级分组，
        // 先获取统计值中的字段，然后看listChartData中对应字段是否有数据
      } else if (type == "digital") {
        //判断数字图是否有数据
        if (this.digitalChartData) {
          this.isChartDataEmpty = false;
        } else {
          this.isChartDataEmpty = true;
        }
      } else if (
        (type == "bar_1" ||
          type == "column_1" ||
          type == "line_1" ||
          type == "waterfall" ||
          type == "area" ||
          type == "bar_duidie" ||
          type == "column_duidie" ||
          type == "line_duidie") &&
        ((data.listChartData && data.listChartData.length == 0) ||
          !data.listChartData) &&
        ((data.groupOneArr && data.groupOneArr.length == 0) ||
          !data.groupOneArr)
      ) {
        this.isChartDataEmpty = true; //二维条形图和二维柱形图,二维折线图,瀑布图//堆叠条形图，堆叠柱形图和堆叠折线图
      } else if (
        data.data &&
        data.data.length == 0 &&
        (type == "worldMap" ||
          type == "heatMap" ||
          type == "usaMap" ||
          type == "britainMap" ||
          type == "singaporeMap" ||
          type == "mexicoMap" ||
          type == "canadaMap" ||
          type == "franceMap" ||
          type == "germanyMap" ||
          type == "australiaMap")
      ) {
        this.isChartDataEmpty = true; //地图
      } else {
        this.isChartDataEmpty = false;
      }
    },
    // 刷新按钮
    fnrefresh() {
      this.$refs.loadimgbox.classList.add("loadimg");
      this.isSaveCacheData = "true";
      // 主页中直接添加的图表需要使用relatedid来查询图表数据
      if (this.DashboardData.type === "homepageChart") {
        this.getChartDataByIdRefresh(this.DashboardData.relatedid);
      } else {
        this.getChartDataByIdRefresh(this.DashboardData.i);
      }
      // 同环比报表类型的图表，在刷新之后需要重新获取报表数据
      if (this.DashboardData.dashboardtype === "sequential") {
        this.$bus.$emit("againGetReportDataByDetail");
      }
    },
    // 根据时间人员配置条件将配置的图表进行对应类型的请求
    // dashboardConditionArr1：配置类型1，dashboardConditionArr2：配置类型2，e：筛选条件
    getChartDataByCondition(dashboardConditionArr1, dashboardConditionArr2, e) {
      // 在筛选配置了类型1的图表
      dashboardConditionArr1.map((item) => {
        // 该图表配置了类型1
        if (this.DashboardData.i == item.chartid) {
          if (
            // 且配置了类型2
            dashboardConditionArr2.some(
              (item1) => this.DashboardData.i === item1.chartid
            )
          ) {
            this.getChartDataByIdRefresh(
              item.chartid,
              e.detailTimeKey,
              e.detailUserKey
            );
          } else {
            // 未配置类型2
            if (e.type === "time") {
              this.getChartDataByIdRefresh(item.chartid, e.detailTimeKey, "");
            } else if (e.type === "user") {
              this.getChartDataByIdRefresh(item.chartid, "", e.detailUserKey);
            }
          }
        }
      });
    },
    // 点击刷新，取的是最新数据
    async getChartDataByIdRefresh(e, detailTimeKey, detailUserKey) {
      if (
        (this.DashboardData.homepageid &&
          this.DashboardData.layout &&
          this.DashboardData.layout.length > 0) ||
        (!this.DashboardData.homepageid && e) ||
        this.DashboardData.type === "homepageChart"
      ) {
        // 主页中添加的图表未被删除、仪表板中图表
        this.isDelete = false;
        this.loadings = true;
        /*--
          linkageGroupOneID;  //图表联动时第一分组字段的ID
          linkageGroupOneApi;   //图表联动时第一分组字段的API 例如：schemefield_name_api:t1.km
          linkageGroupOneValue; //图表联动时第一分组的值
          linkageGroupOneDateType; //图表联动时第一分组的日期格式
          linkageGroupTwoID;    //图表联动时第二分组字段的ID
          linkageGroupTwoApi; //图表联动时第二分组字段的API
          linkageGroupTwoValue; //图表联动时第二分组的值
          linkageGroupTwoDateType; //图表联动时第二分组的日期格式
          用户 detailUserKey 参数：id1,id2,id3,id4用英文逗号隔开
          时间 detailTimeKey 参数：2020-03-11 00:00:00,2020-11-11 00:00:00开始结束时间，逗号隔开 
        */
        let params = {
          isRefresh: "true", // 是否采用数据库数据，即最新数据
          isSaveCacheData: this.isSaveCacheData,
          id: e.id ? e.id : e,
          linkageGroupOneID: e.linkageGroupOneID,
          linkageGroupOneApi: e.linkageGroupOneApi,
          linkageGroupOneValue:
            e.linkageGroupOneValue == "null"
              ? null
              : e.linkageGroupOneValue == "-"
              ? ""
              : e.linkageGroupOneValue,
          linkageGroupOneDateType: e.linkageGroupOneDateType,
          linkageGroupTwoID: e.linkageGroupTwoID,
          linkageGroupTwoApi: e.linkageGroupTwoApi,
          linkageGroupTwoValue:
            e.linkageGroupTwoValue == "null" ? null : e.linkageGroupTwoValue,
          linkageGroupTwoDateType: e.linkageGroupTwoDateType,
          detailTimeKey: detailTimeKey,
          detailUserKey: detailUserKey,
        };
        try {
          let { data } = await DashboardApi.getChartDataById(params);
          this.loadings = false; // 取消loading
          if (this.$refs.loadimgbox) {
            this.$refs.loadimgbox.classList.remove("loadimg"); // 取消刷新图标转动
          }
          this.isEmpty = false;
          this.showData.refreshDate = data.refreshDate; // 获取图表刷新时间
          this.showData.chartInfo = data.chartInfo; // 获取图表基本信息
          this.showData.reportInfo = data.reportInfo; // 获取报表基本信息
          this.showData.objectInfo = data.objectInfo; // 获取对象基本信息
          this.showData.viewInfo = data.viewInfo; // 获取视图基本信息
          this.showData.mapGroupOneField = data.mapGroupOneField; // 一级分组字段信息
          this.showData.mapGroupTwoField = data.mapGroupTwoField
            ? data.mapGroupTwoField
            : {}; // 二级分组字段信息
          this.showData.name = data.chartInfo.name; // 获取图表名称
          this.showData.digitalData = data.digitalData; // 数字图的数据
          this.getOptionDatas(data);
          this.chartIsEmpty(data.chartInfo.dashboardtype, data);
          this.linkageContent = this.$store.getters.getChartLinkageContent;
          // 根据不同的图表类型判断数据是否为空
          // 数字图
          if (
            data.chartInfo.dashboardtype == "number" &&
            data.listChartData &&
            data.listChartData.length == 0
          ) {
            this.isChartDataEmpty = true;
          }
        } catch (error) {
          this.loadings = false; // 取消loading
          this.isEmpty = true;
        }
      } else {
        // 主页中添加的图表已被删除
        this.isEmpty = true;
        this.isDelete = true;
      }
    },
    // 根据接口返回数据获取对应的图表options
    getOptionDatas(data) {
      this.showDataFullScreen = data; // 图表全屏使用的数据
      // 先判断当前是在仪表板还是报表页
      let chartInfo = {}; // 图表信息
      if (
        this.useLocation === "report" ||
        (data.reportInfo && !data.chartInfo)
      ) {
        // if (data.reportInfo) {
        chartInfo = data.reportInfo;
      } else {
        chartInfo = data.chartInfo;
      }
      // 13种图表展示所需要的数据
      this.showChartInfos = {
        dashboardtype: chartInfo.dashboardtype,
        groupOneArr: data.groupOneArr,
        listChartData: data.listChartData,
        valueDataArr: data.valueDataArr,
        dataArr: data.data,
        seriesArr: data.series,
        dimensionsArr: data.dimensions,
        sourceArr: data.source,
        min: chartInfo.min,
        max: chartInfo.max,
        mid1: chartInfo.mid1,
        mid2: chartInfo.mid2,
        isshowpercent: chartInfo.isshowpercent,
        isshowvalue: chartInfo.isshowvalue,
        mapGroupOneFieldLabelName: data.mapGroupOneField
          ? data.mapGroupOneField.labelName
          : "",
        mapGroupOneField: data.mapGroupOneField,
        mapSummaryFieldLabelName: data.mapSummaryField
          ? data.mapSummaryField.labelName
          : "",
        mapSummaryWay: data.mapSummaryField
          ? data.mapSummaryField.summaryWay
          : "",
        objectLable: data.objectInfo ? data.objectInfo.label : "",
        reportName: data.reportInfo ? data.reportInfo.name : "",
        unit: chartInfo.unit,
      };
      this.dashboardtype = chartInfo.dashboardtype;
      this.chartIsEmpty(this.dashboardtype, data);
      // 数字类型
      if (this.dashboardtype === "number") {
        this.showData.statisticsValue = data.valueData;
        // 获取具体色值
        this.showData.colorValue = this.getColor(
          chartInfo.min,
          chartInfo.mid1,
          data.valueData
        );
        return;
      }
      // 双轴图类型
      if (this.dashboardtype === "biaxialFigure") {
        this.showData.chartInfo = chartInfo;
        this.showData.listMaxFields = data.listMaxFields;
        this.showData.listMinFields = data.listMinFields;
        this.showData.listAvgFields = data.listAvgFields;
        this.showData.listSumFields = data.listSumFields;
        this.showData.mapCountField = data.mapCountField;
        this.showData.mapGroupOneField = data.mapGroupOneField;
      }
      // 表格图表类型：没有分组tableDetail
      if (this.dashboardtype === "tableDetail") {
        if (data.reportInfo && data.reportInfo.listCheckedFields) {
          // 报表数据源
          this.showData.sourceType = "report";
          // 无分组表格图表不显示记录数
          let objParses = [];
          data.reportInfo.listCheckedFields.map((item) => {
            if (item.schemefieldName !== "totalrecord") {
              objParses.push(item);
            }
          });
          this.showData.colData = objParses;
          // 判断表头中字段是否有复选框类型（B）字段，把对应字段的值转为是否
          let filedTypeB = []; //复选框字段
          data.reportInfo.listCheckedFields.map((item) => {
            if (item.schemefieldType == "B") {
              filedTypeB.push(item.schemefield_name_alias);
            }
          });
          if (filedTypeB?.length > 0) {
            let reportdata = [];
            data.listChartData.map((item) => {
              filedTypeB.map((filedItem) => {
                if (
                  item[filedItem] === "false" ||
                  item[filedItem] === "" ||
                  item[filedItem] === "0" ||
                  item[filedItem] === null ||
                  item[filedItem] == this.$i18n.t("label.tabpage.noz")
                ) {
                  item[filedItem] = this.$i18n.t("label.tabpage.noz");
                } else if (
                  item[filedItem] === "true" ||
                  item[filedItem] === "1" ||
                  item[filedItem] == this.$i18n.t("label.tabpage.yesz")
                ) {
                  item[filedItem] = this.$i18n.t("label.tabpage.yesz");
                }
              });
              reportdata.push(item);
            });
            this.showData.tableData = reportdata;
          } else {
            this.showData.tableData = data.listChartData;
          }
          this.showData.mapGroupOneField = {};
        } else {
          let dateField = []; //日期字段数组
          data.listViewFields?.map((item) => {
            if (item.schemefieldType == "D") {
              dateField.push(item.schemefieldName);
            }
          });
          if (dateField?.length > 0) {
            data.listChartData.map((item) => {
              dateField.map((fieldItem) => {
                if (item[fieldItem]) {
                  item[fieldItem] = item[fieldItem].substring(0, 11);
                }
              });
            });
          }
          // 列表视图数据源
          this.showData.sourceType = "listView";
          this.showData.colData = data.listViewFields;
          this.showData.tableData = data.listChartData;
        }
        return;
      }
      // 表格图表类型：带有分组：report
      if (this.dashboardtype === "report_summary") {
        if (
          data.mapGroupOneField?.schemefieldType == "B" ||
          data.mapGroupTwoField?.schemefieldType == "B"
        ) {
          let fieldType = [];
          if (data.mapGroupOneField?.schemefieldType == "B") {
            fieldType.push(data.mapGroupOneField.schemefield_name_alias);
          }
          if (data.mapGroupTwoField?.schemefieldType == "B") {
            fieldType.push(data.mapGroupTwoField.schemefield_name_alias);
          }
          let reportdata = [];
          data.listChartData.map((item) => {
            fieldType.map((fielditem) => {
              if (
                item[fielditem] === "false" ||
                item[fielditem] === "0" ||
                item[fielditem] === null ||
                item[fielditem] === "" ||
                item[fielditem] === this.$i18n.t("label.tabpage.noz")
              ) {
                item[fielditem] = this.$i18n.t("label.tabpage.noz");
                reportdata.push(item);
              } else if (
                item[fielditem] === "true" ||
                item[fielditem] === "1" ||
                item[fielditem] === this.$i18n.t("label.tabpage.yesz")
              ) {
                item[fielditem] = this.$i18n.t("label.tabpage.yesz");
                reportdata.push(item);
              }
            });
          });
          this.showData.tableData = reportdata;
        } else if (
          data.mapGroupOneField?.schemefieldType == "D" ||
          data.mapGroupTwoField?.schemefieldType == "D"
        ) {
          if (data.mapGroupOneField?.schemefieldType == "D") {
            let filedType = data.mapGroupOneField.schemefield_name_alias;
            data.listChartData.map((item) => {
              if (item[filedType]?.length > 0) {
                item[filedType] = item[filedType].substring(0, 11);
              }
            });
          }

          if (data.mapGroupTwoField?.schemefieldType == "D") {
            let filedType = data.mapGroupTwoField.schemefield_name_alias;
            data.listChartData.map((item) => {
              if (item[filedType]?.length > 0) {
                item[filedType] = item[filedType].substring(0, 11);
              }
            });
          }
          this.showData.tableData = data.listChartData;
        } else if (data.reportInfo && data.reportInfo.listCheckedFields) {
          // 从报表生成仪表板
          // 判断表头中字段是否有复选框类型（B）字段，把对应字段的值转为是否
          let filedTypeB = []; //复选框字段
          data.reportInfo.listCheckedFields.map((item) => {
            if (item.schemefieldType == "B") {
              filedTypeB.push(item.schemefield_name_alias);
            }
          });
          if (filedTypeB?.length > 0) {
            let reportdata = [];
            data.listChartData.map((item) => {
              filedTypeB.map((filedItem) => {
                if (
                  item[filedItem] === "false" ||
                  item[filedItem] === "0" ||
                  item[filedItem] === "" ||
                  item[filedItem] === null ||
                  item[filedItem] == this.$i18n.t("label.tabpage.noz")
                ) {
                  item[filedItem] = this.$i18n.t("label.tabpage.noz");
                } else if (
                  item[filedItem] === "true" ||
                  item[filedItem] === "1" ||
                  item[filedItem] == this.$i18n.t("label.tabpage.yesz")
                ) {
                  item[filedItem] = this.$i18n.t("label.tabpage.yesz");
                }
              });
              reportdata.push(item);
            });
            this.showData.tableData = reportdata;
          } else {
            this.showData.tableData = data.listChartData;
          }
        } else {
          this.showData.tableData = data.listChartData;
        }
        if (data.reportInfo) {
          this.showData.colData = [];
          this.showData.colData.push(
            data?.mapGroupOneField,
            data?.mapGroupTwoField
          );
          if(data.reportInfo.summaryway.indexOf('count')==-1&&data.reportInfo.xcon.indexOf('totalrecord')==-1){
            let datalistSumFields=[];
            data.listSumFields&&data.listSumFields.map((item)=>{                     
              if(item.id!="totalrecord"){
                datalistSumFields.push(item)
              }
            })
            let datalistAvgFields=[];
            data.listAvgFields&&data.listAvgFields.map((item)=>{
              if(item.id!="totalrecord"){
                datalistAvgFields.push(item)
              }
            })
            let datalistMaxFields=[];
            data.listMaxFields&&data.listMaxFields.map((item)=>{
              if(item.id!="totalrecord"){
                datalistMaxFields.push(item)
              }
            })
            let datalistMinFields=[];
            data.listMinFields&&data.listMinFields.map((item)=>{
              if(item.id!="totalrecord"){
                datalistMinFields.push(item)
              }
          })
          this.showData.colData = this.showData.colData
            .concat(datalistAvgFields)
            .concat(datalistMaxFields)
            .concat(datalistMinFields)
            .concat(datalistSumFields);
        }else{
          this.showData.colData = this.showData.colData
            .concat(data.listAvgFields)
            .concat(data.listMaxFields)
            .concat(data.listMinFields)
            .concat(data.listSumFields);
        }
          // 报表数据源
          this.showData.sourceType = "report";
          this.showData.colData = this.showData.colData.filter((item) => item); // 过滤掉空值
        } else {
          // 列表视图数据源
          this.showData.sourceType = "listView";
          this.showData.colData = [];
          this.showData.colData.push(
            data?.mapGroupOneField,
            data?.mapGroupTwoField
          );
          this.showData.colData = this.showData.colData
            .concat(data.listAvgFields)
            .concat(data.listMaxFields)
            .concat(data.listMinFields)
            .concat(data.listSumFields)
            .concat(data.mapCountField);
          this.showData.colData = this.showData.colData.filter((item) => item); // 过滤掉空值
        }
        return;
      }
    },
    // 在number图表类型中根据返回颜色范围获取对应颜色
    getColor(rangeOne, rangeTwo, valueData) {
      // 均转换为数字比较
      if (Number(valueData) < Number(rangeOne)) {
        // 小于第一个数值
        return "#7F8DE1";
      } else if (
        Number(valueData) >= Number(rangeOne) &&
        Number(valueData) < Number(rangeTwo)
      ) {
        // 大于等于第一个数值，小于第二个数值
        return "#2CC7BE";
      } else {
        // 大于等于第二个数值
        return "#4087F2";
      }
    },
    // 将数值转换为千分位
    changeMicrometerLevel(num) {
      if (num) {
        var parts = num.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    },
    // 切换联动穿透状态
    changeStatus(value) {
      if (value === "2") {
        if (this.showData.chartInfo.isurl) {
          // 设置过穿透才可进行切换
          this.status = value;
        }
      } else {
        if (this.showData.chartInfo.linkageid) {
          // 设置过联动才可进行切换
          this.status = value;
        }
      }
    },
    // 联动、穿透获取点击数据,是否取消联动标志
    linkagegetData(params, value) {
      if (this.showData.chartInfo.linkageid && this.status === "1") {
        // 联动状态
        let linkageGroupOneID = ""; // 一级分组字段id
        let linkageGroupOneApi = ""; // 一级分组字段api
        let linkageGroupOneValue = ""; // 一级分组字段值
        let linkageGroupOneDateType = ""; // 一级分组日期格式
        let linkageGroupTwoID = ""; // 二级分组字段id
        let linkageGroupTwoApi = ""; // 二级分组字段api值
        let linkageGroupTwoValue = ""; // 二级分组字段值
        let linkageGroupTwoDateType = ""; // 二级分组日期格式
        if (this.showData.chartInfo.linkageid && value != "cancellinkage") {
          // 所选择图表有联动的图表
          linkageGroupOneID = this.showData.mapGroupOneField.id;
          linkageGroupOneApi =
            this.showData.mapGroupOneField.schemefield_name_api;
          // 一级分组名称
          linkageGroupOneValue = params.name;
          linkageGroupOneDateType = this.showData.chartInfo.onegroupdatetype;
          if (this.showData.chartInfo.xgather) {
            // 图表有二级分组
            linkageGroupTwoID = this.showData.mapGroupTwoField.id;
            linkageGroupTwoApi =
              this.showData.mapGroupTwoField.schemefield_name_api;
            // 二级分组名称
            linkageGroupTwoValue = params.seriesName;
            linkageGroupTwoDateType = this.showData.chartInfo.twogroupdatetype;
          } else {
            linkageGroupTwoID = "";
            linkageGroupTwoApi = "";
            linkageGroupTwoValue = "";
            linkageGroupTwoDateType = "";
          }
          // 对所有设置联动的图表进行数据查询
          this.showData.chartInfo.linkageid.split(",").map((item) => {
            let dataArr = {
              id: item,
              linkageGroupOneID: linkageGroupOneID,
              linkageGroupOneApi: linkageGroupOneApi,
              linkageGroupOneValue: linkageGroupOneValue,
              linkageGroupOneDateType: linkageGroupOneDateType,
              linkageGroupTwoID: linkageGroupTwoID,
              linkageGroupTwoApi: linkageGroupTwoApi,
              linkageGroupTwoValue: linkageGroupTwoValue,
              linkageGroupTwoDateType: linkageGroupTwoDateType,
            };
            this.$emit("linkagegetData", dataArr);
          });
        } else {
          linkageGroupOneID = "";
          linkageGroupOneApi = "";
          linkageGroupOneValue = "";
          linkageGroupOneDateType = "";
          linkageGroupTwoID = "";
          linkageGroupTwoApi = "";
          linkageGroupTwoValue = "";
          linkageGroupTwoDateType = "";
          this.showData.chartInfo.linkageid.split(",").map((item) => {
            let dataArr = {
              id: item,
              linkageGroupOneID: linkageGroupOneID,
              linkageGroupOneApi: linkageGroupOneApi,
              linkageGroupOneValue: linkageGroupOneValue,
              linkageGroupOneDateType: linkageGroupOneDateType,
              linkageGroupTwoID: linkageGroupTwoID,
              linkageGroupTwoApi: linkageGroupTwoApi,
              linkageGroupTwoValue: linkageGroupTwoValue,
              linkageGroupTwoDateType: linkageGroupTwoDateType,
            };
            this.$emit("linkagegetData", dataArr);
          });
        }
      }
      // 重新排序时不可进行穿透：isEditStatus
      if (
        ((!this.showData.chartInfo.linkageid &&
          this.showData.chartInfo.isurl) ||
          this.status === "2") &&
        !this.isEditStatus
      ) {
        // 穿透状态
        if (this.showData.reportInfo) {
          // 报表数据源，根据报表id跳转至报表运行页

          if (browserApi.getBrowserType() == "feishu") {
            this.$router.push({
              path: `/reportObject/editReport/run/${this.showData.reportInfo.id}`,
            });
          } else {
            let routeData = this.$router.resolve({
              path: `/reportObject/editReport/run/${this.showData.reportInfo.id}`,
              query: {},
            });
            window.open(routeData.href);
          }

          // let url = browserApi.getUrl(routeData.href)
        } else {
          // 列表视图数据源，跳转至对应的视图列表
          if (browserApi.getBrowserType() == "feishu") {
            this.$router.push({
              path: `/commonObjects/views/table/${this.showData.objectInfo.schemetableName}/${this.showData.objectInfo.id}/${this.showData.objectInfo.prefix}`,
              query: {
                viewId: this.showData.viewInfo.id, // 视图id,
              },
            });
          } else {
            let routeData = this.$router.resolve({
              path: `/commonObjects/views/table/${this.showData.objectInfo.schemetableName}/${this.showData.objectInfo.id}/${this.showData.objectInfo.prefix}`,
              query: {
                viewId: this.showData.viewInfo.id, // 视图id,
              },
            });
            window.open(routeData.href);
          }
        }
      }
    },
    // 点击联动：除了无分组的三种图表，其他均可设置联动
    fnchooseLinkage() {
      //筛选同一数据源的数据，不包括本身
      let commonDataArr_data = JSON.parse(JSON.stringify(this.commonDataArr));
      var data;
      if (this.DashboardData.reportid) {
        // 属于报表数据源的图表，筛选同一报表id的图表
        data = commonDataArr_data.filter(
          (item) =>
            item.reportid === this.DashboardData.reportid &&
            item.id !== this.DashboardData.id
        );
      } else if (this.DashboardData.objectid) {
        // 属于列表视图数据源的图表，筛选同一对象id的图表
        data = commonDataArr_data.filter(
          (item) =>
            item.objectid === this.DashboardData.objectid &&
            item.id !== this.DashboardData.id
        );
      }
      this.$emit("fnchooseLinkage", data, this.showData.chartInfo);
    },
    // 点击穿透：穿透需要区分是报表数据源还是列表视图数据源，跳转页面不同
    fnchoosepierce() {
      let through = {
        throughLabel: "", // 穿透需要显示的label
        throughType: "", // 穿透类型
      };
      if (this.showData.reportInfo) {
        // 选择的报表数据源
        through = {
          throughLabel: this.showData.reportInfo.name,
          throughType: "报表",
        };
      } else {
        // 选择的列表视图数据源
        through = {
          throughLabel:
            this.showData.viewInfo.label + "|" + this.showData.objectInfo.label,
          throughType: "列表视图",
        };
      }
      this.$emit(
        "fnchoosepierce",
        this.showData.chartInfo, // 图表数据
        through // 穿透数据
      );
    },
    //删除弹框
    fnchoosedelete() {
      this.$emit("fnchoosedelete", this.DashboardData.i, this.showData.name);
      this.$bus.$emit("fnchoosedelete", this.cpnObject.i, this.cpnObject.name);
    },
    // 编辑图表弹框
    fnchooseEdit() {
      // 将当前仪表板组件的数据发送到(在仪表板中使用)
      this.$emit("fnchooseEdit", this.showData.chartInfo);
      // 发送当前图表组件数据到index页面，index页面通过监听传递的数据发送给弹窗，组装数据再发送到index页面请求
      this.$bus.$emit(
        "fnchooseEdit",
        this.DashboardData,
        this.cpnObject,
        this.showData.chartInfo
      );
    },
    //添加到组件库弹框
    addComponToKuOpen() {
      this.$emit("addComponToKuOpen", this.showData.name);
    },
    // 点击图表全屏展示
    clickChartFullScreen() {
      //1.showDataFullScreen---图表数据
      //2.cpnObject.name(在主页中图表组件的名字)
      //3.chartEmpty：当前是否有数据
      this.showDataFullScreen.chartEmpty = this.isChartDataEmpty;
      this.$bus.$emit(
        "clickChartFullScreen",
        this.showDataFullScreen,
        this.cpnObject.name
      );
    },
    enterChange() {
      this.changeWhite = true;
    },
    outChange() {
      this.changeWhite = false;
    },
    /* 
      $bus以this为中心，只要引入的组件销毁了一次on的监听事件，则该组件所有的引入中的该事件都会销毁
        再次进行获取对应图表数据，触发场景：新建编辑图表时、
        设置联动穿透状态时、联动时、仪表板更改图表位置时
    */
    getLinkagegetDataMethod() {
      this.$bus.$on("getLinkagegetData", this.ebFn4);
    },
  },
  computed: {
    // 数字图的数据
    digitalChartData() {
      if (this.showData && this.showData.digitalData) {
        return this.showData.digitalData;
      } else if (this.DashboardData && this.DashboardData.digitalData) {
        return this.DashboardData.digitalData;
      } else {
        return "";
      }
    },
    /* 
      是否展示图表左侧信息:
        1.在报表中展示图表，不展示；
        2.非空或者被删除，展示；
        3.其他情况，不展示；
    */
    isShowLeftChartInfo() {
      if (this.useLocation === "report") return false;
      if (!this.isEmpty || this.isDelete) return true;
      return false;
    },
    /* 
      是否展示图表右侧全屏图标：以下情况不展示
        1.仪表板全屏、主页全屏且主页中包含有仪表板；
        2.图表无数据，为空；
        3.在主页中添加图表，图表或仪表板已被删除；
    */
    isShowRightFullScreen() {
      if (
        this.useLocation === "dashboardFullScreen" ||
        this.isEmpty ||
        this.isDelete
      )
        return false;
      return true;
    },
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.useLocation === "report") return false;
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      } else if (this.homePageObject.type == "PersonalPageCpn") {
        return true;
      } else if (
        !this.cpnObject.homepageid &&
        this.isEdit &&
        !this.isEditStatus
      ) {
        // 没有主页id，属于在仪表板主页且属于运行状态
        return true;
      } else {
        return false;
      }
    },
    // 是否可以联动，以下情况不可设置联动：处于主页、指针图、统计数字、表格图表
    // 新增的国家地图8个，雷达图，气泡图，矩阵树形图，同环比数字图，同环比报表图
    isLinkage() {
      if (
        this.cpnObject.homepageid ||
        this.dashboardtype === "gauge" ||
        this.dashboardtype === "number" ||
        this.dashboardtype === "tableDetail" ||
        this.dashboardtype === "bubble" ||
        this.dashboardtype === "sequential" ||
        this.dashboardtype === "radarMap" ||
        this.dashboardtype === "digital" ||
        this.dashboardtype === "matrixTree" ||
        this.dashboardtype === "usaMap" ||
        this.dashboardtype === "heatMap" ||
        this.dashboardtype === "britainMap" ||
        this.dashboardtype === "singaporeMap" ||
        this.dashboardtype === "mexicoMap" ||
        this.dashboardtype === "canadaMap" ||
        this.dashboardtype === "franceMap" ||
        this.dashboardtype === "germanyMap" ||
        this.dashboardtype === "australiaMap" ||
        this.dashboardtype === "report_summary"
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-content {
  width: 100%;
  height: 100%;
}
.box-card ::v-deep .el-card__body {
  padding: 0;
}
.q {
  overflow: hidden;
}
//刷新
@keyframes loadimg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loadimg {
  animation: loadimg 2s linear infinite;
}
.box-card {
  height: 100%;
  user-select: none;
  background-color: #fff;
  padding: 15px 10px 5px 10px;
  ::v-deep .el-card__body {
    height: 100% !important;
    .head-app {
      display: flex;
      justify-content: space-between;
      height: 10px;
    }
    .head-bar {
      display: flex;
      justify-content: space-between;
      min-height: 40px;
      .head-bar-left {
        width: calc(100% - 54px);
        display: flex;
        .linkageImg {
          margin-right: 5px;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
        .blank {
          width: 25px;
          height: 20px;
        }
        .name_time_div {
          min-width: calc(100% - 26px);
          .name_div {
            display: flex;
            align-items: center;
            .title_word {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: bold;
              font-size: 15px;
            }
            i {
              margin: 4px 5px 0 10px;
              cursor: pointer;
              width: 13px;
              height: 13px;
            }
          }
          .time_div {
            color: #fe8a14;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
          }
        }
      }
      // .el-icon-rank {
      //   font-size: 18px;
      //   transform: rotate(45deg);
      //   color: #777;
      //   cursor: pointer;
      // }
      .screenfullImg {
        width: 15px;
        height: 15px;
        cursor: pointer;
        vertical-align: -5px;
        margin-right: 8px;
        margin-top: 2px;
      }

      .head-bar-right {
        margin-left: auto;
        display: flex;
        cursor: pointer;
        padding: 3px 0;
        .menu_dot {
          height: 18px;
          width: 18px;
        }
      }
    }
    #logo {
      width: 50px !important;
      height: 50px !important;
    }
    #chartInfo {
      display: flex;
      flex-direction: column;
      .chart-name {
        font-size: 18px;
        font-weight: bold;
      }
      .chart-time {
        font-size: 14px;
        margin-top: 10px;
      }
    }
    .chart_div {
      height: calc(100% - 45px);
    }
    .nodata {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      svg {
        width: 60%;
        height: 45%;
        margin: 0 auto;
        margin-top: 10%;
      }
      .chart-width {
        margin-top: 2%;
      }
      .title {
        margin-top: 2%;
        font-size: 12px;
        text-align: center;
        color: #acacac;
      }
      .titlechart {
        margin-top: 20px;
      }
    }
    .noData_2 {
      height: calc(100% - 28px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
// 更多操作弹框
.moreOperation {
  // height: 180px;
  color: #333333;
  background: #ffffff;
  border-radius: 5px;
  padding: 5px 0px;
  > div {
    padding: 6px 18px 8px 18px;
    cursor: pointer;
    &:hover {
      color: #2d6cfc !important;
      background: #f3f7ff !important;
    }
  }
}
.addchoose {
  display: flex;
  justify-content: center;
  align-items: center;
  .choose {
    width: 262px;
    margin-top: 12px;
    margin-left: 0;
  }
}
</style>

<style lang="scss">
.el-popover.colPopover {
  padding: 0 !important;
  min-width: 130px;
  border: 1px solid #e1e1e1 !important;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
</style>

