<!-- 图表全屏 -->
<template>
  <el-dialog
    :title="
      dataShowName
        ? dataShowName
        : DashboardDataFullScreen.chartInfo
        ? DashboardDataFullScreen.chartInfo.name
        : ''
    "
    :visible.sync="chartFullScreenTo"
    width="85%"
    :center="titleCenter"
    :close-on-click-modal="false"
    :before-close="chartFullScreenClose"
  >
    <!-- 图表组件 -->
    <dashboardCard
      :DashboardData="DashboardDataFullScreen"
      :useLocation="'chartFullScreen'"
    />
  </el-dialog>
</template>

<script>
/**
 * @description 图表全屏
 * 功能：
 * 1、图表全屏显示
 * 逻辑：
 * 1、根据传入的图表数据， 进行全屏放大展示
 */
import dashboardCard from "@/views/homePage/components/DashboardCard.vue"; // 图表组件

export default {
  components: {
    dashboardCard,
  },
  props: {
    /**
     * 控制图表是否全屏显示
     */
    chartFullScreenTo: {
      type: Boolean,
      default: false,
    },
    /**
     * 图表数据
     */
    DashboardDataFullScreen: {
      type: Object,
      default: () => ({}),
    },
    /**
     * (在主页时)图表name
     */
    dataShowName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      titleCenter: true, // 控制弹窗标题居中显示
    };
  },
  methods: {
    /**
     * 取消图表全屏
     */
    chartFullScreenClose() {
      this.$emit("chartFullScreenClose");
    },
  },
};
</script>

<style lang='scss' scoped>
.el-dialog__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
}
::v-deep .el-dialog {
  background: white !important;
  height: 80%;
  .el-dialog__header {
    height: 57px;
  }
  .el-dialog__body {
    height: calc(100% - 57px);
    .el-card {
      border: none !important;
      box-shadow: none !important;
      padding-top: 50px;
    }
  }
}
</style>