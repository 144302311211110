let browserType = '' //内置浏览器类型 飞书 企业微信 微信 钉钉
let url = '' // 跳转链接
let appid = 'cli_a3e37d398e7bd00c'
function getBrowserType() {
    let _userAgent = navigator.userAgent
    if (_userAgent?.includes('Lark')) {
        browserType = 'feishu'
    } else if (_userAgent?.includes('MicroMessenger')) {
        browserType = 'qiyeweixin'
    } else if (_userAgent?.includes('micromessenger')) {
        browserType = 'weixin'
    } else if (_userAgent?.includes('DingTalk')) {
        browserType = 'dingding'
    }
    return browserType
        
}
// 飞书跳转列链接
// path格式 仪表板   #/homePageObject/standardPage
function getUrl(path) {
    getBrowserType()
   
    if(browserType=='feishu'){
        url = `https://applink.feishu.cn/client/web_app/open?appId=${appid}&mode=window&lk_target_url=${encodeURIComponent(location.origin+'/'+path)}`
    }else{
        url = path
    }
    return url
}

export default {getBrowserType,getUrl}
