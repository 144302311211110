<!--
 * @Author: chenwc
 * @Date: 2022-03-18 11:28:38
 * @Description: 同事圈主页标准组件
-->
<template>
  <el-card class="workloadRankingBox">
    <!-- 头部 -->
    <div slot="header" class="flexBox">
    {{$t('label.ccchat.2018')}}
    <!-- 删除组件 -->
     <DeleteEdit
          :cpnName="tableData.name"
          :cpnId="tableData.i"
          v-if="isShowExtendBtnDropdown"
          :cpnObject="tableData"
        />
    </div>
    <!-- 主体 -->
    <div>
      <Tsquan :heightBox="tableHeight"/>
    </div>
  </el-card>
</template>

<script>
import Tsquan from "@/views/colleagueCircle/main.vue";
import DeleteEdit from "@/views/homePage/components/DeleteEdit.vue";
export default {
  name: "tsquan",
  components: {
    Tsquan,
    DeleteEdit
  },
  props: {
    tableData: {
      type: Object,
      default: () => ({}),
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tableHeight: "", // 表格高度
    };
  },
  mounted() {
      
    // 浏览器高度
    let bodyHeight = document.documentElement.clientHeight;
    this.tableHeight = (bodyHeight / 9) * this.tableData.h - 80;
  },
    computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      }
      return undefined
    },
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>

.workloadRankingBox {
  width: 100%;
  height: 100%;
//   overflow: auto;

  
  .flexBox {
    display: flex;
    justify-content: space-between;
  }
}

</style>