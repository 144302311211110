<template>
  <!-- 
    仪表板组件
    插入仪表板组件使用
    也是作为中间件来使用，不做数据的处理，直接传给图表组件即可
    关于主页中添加仪表板组件、图表组件被删除情况：
      1.主页中添加仪表板，仪表板被删除；--根据属性isdeletedashboard判断
      2.主页中添加图表，图表被删除；--根据layout是否为空判断
      3.主页中添加图表，图表所在仪表板被删除。--根据layout是否为空判断
       :body-style="mouseEnterFlag?'overflow: auto':''"
  -->
  <el-card class="box-body">
    <!-- 仪表板页面标题 -->
    <div
      class="dashbord-head"
      slot="header"
    >
      <div class="dashbord-head-left">
        <div class="dashbord-head-left-title">
          {{ PersonalPageCpnData.name }}
        </div>
        <!-- 时间人员筛选 -->
        <time-person-screen
          :timeUseLocation="'bulletinboard'"
          :dashboardid="PersonalPageCpnData.dashboardid"
        />
        <img
          class="el-icon-refresh-right rolePageHeaderCardStyle-right-refresh"
          ref="loadimgbox"
          @click="refreshEvent"
        />
        <!-- 这个刷新的时间，暂时不需要 -->
        <!-- <div class="dashbord-head-left-refresh">上次刷新时间 {{PersonalPageCpnData.lastmodifydate| formatTime}}</div> -->
      </div>
      <div class="dashbord-head-right">
        <!-- 右方面菜单 -->
        <DeleteEdit
          :cpnName="PersonalPageCpnData.name"
          :cpnId="PersonalPageCpnData.i"
          v-if="isShowExtendBtnDropdown"
          :cpnObject="PersonalPageCpnData"
          :isEdit="isEdit"
        />
      </div>
    </div>
    <!-- 仪表板已被删除 -->
    <div
      class="img_div"
      v-if="
        PersonalPageCpnData.isdeletedashboard &&
        JSON.parse(PersonalPageCpnData.isdeletedashboard)
      "
    >
      <!-- 已被删除 -->
      <temporarily-no-data
        :titleWord="$t('vue_label_homepage_dashboard_deleted')"
      />
    </div>
    <!-- 仪表板里的所有图表组件 -->
    <div
      v-else
      class="div1 scrollBoxUnique"
    >
      <Grid-layout
        :layout="PersonalPageCpnData.layout"
        :col-num="12"
        :row-height="80"
        :is-draggable="false"
        :is-resizable="false"
        :is-mirrored="false"
        :vertical-compact="true"
        :margin="[10, 10]"
        :use-css-transforms="true"
        style="width: 100%"
      >
        <Grid-item
          v-for="item in PersonalPageCpnData.layout"
          :key="item.i"
          :x="Number(item.x)"
          :y="Number(item.y)"
          :w="Number(item.w)"
          :h="Number(item.h)"
          :i="item.i"
          :min-w="Number(item.minW)"
          :min-h="Number(item.minH)"
        >
          <!-- 图表卡片 -->
          <Dashboard-card
            v-if="item.type === 'Dashboard'"
            :DashboardData="item"
            :useLocation="useLocation"
            @fnchooseLinkage="fnchooseLinkage"
            @fnchoosepierce="fnchoosepierce"
            @fnchoosedelete="fnchoosedelete"
            @linkagegetData="linkagegetData"
            :cpnObject="item"
          />
        </Grid-item>
      </Grid-layout>
    </div>
  </el-card>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import DashboardCard from "./DashboardCard";
// import * as request from "./api.js";
import DeleteEdit from "./DeleteEdit";
import TimePersonScreen from "../../dashboardObject/dashboardIndex/components/TimePersonScreen"; // 时间人员筛选组件
import timePersonnelFiltrateMin from "@/mixin/timePersonnelFiltrate";
import TemporarilyNoData from "@/components/TemporarilyNoData/index.vue"; // 暂无数据

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    DashboardCard,
    DeleteEdit,
    TimePersonScreen,
    TemporarilyNoData,
  },
  props: {
    // 当前组件的item对象
    PersonalPageCpnData: {
      type: Object,
      default: () => ({}),
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
    useLocation: {
      // 图表使用位置：报表、仪表板、图表展示全屏
      type: String,
      default: "",
    },
  },
  mixins: [timePersonnelFiltrateMin],
  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      }
      return undefined
    },
    // 定义全局变量dashboardid仪表板id
    // dashboardid() {
    //   return this.PersonalPageCpnData.dashboardid;
    // },
    // 主页中添加的仪表板是否可编辑
    isEdit() {
      if (
        this.PersonalPageCpnData.isdeletedashboard &&
        JSON.parse(this.PersonalPageCpnData.isdeletedashboard)
      ) {
        // 已被删除则不可编辑
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    // 获取组件数据
    // this.getHomePageData();
  },
  data() {
    return {
    };
  },
  mounted() {},
  methods: {
    fnchooseLinkage() {
      this.$emit("fnchooseLinkage");
    },
    fnchoosepierce() {
      this.$emit("fnchoosepierce");
    },
    fnchoosedelete() {
      this.$emit("fnchoosedelete");
    },
    // 刷新组件内全局刷新仪表板内所有组件(传给仪表板组件，让其调用刷新)
    refreshEvent() {
      this.$refs.loadimgbox.classList.add("loadimg");
      this.$bus.$emit("refreshInsideallData");
      setTimeout(() => {
        this.$refs.loadimgbox.classList.remove("loadimg");
      }, 1000);
    },
    linkagegetData(e) {
      // 联动
      this.$emit("linkagegetData", e);
    },
  },
};
</script>

 <style lang="scss" scoped>
.box-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  // overflow: auto;
  .div1 {
    width: 100%;
    height: 100%;
    padding: 10px;
  }
}
.box-body ::v-deep .el-card__body {
  padding: 0!important;
  padding-top: 0;
  padding-bottom: 0;
  height: calc(100% - 77px);
  // height: calc(100% - 62px);
  // overflow: auto;
}

.dashbord-head {
  /* height: 40px; */
  /* background-color: red; */
  // padding: 0 20px;
  font-size: 16px;
  // font-weight: bold;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .dashbord-head-left {
    display: flex;
    align-items: center;
    height: 22px;
    ::v-deep .select_div {
      height: auto;
    }
  }
  .dashbord-head-left-title {
    margin-right: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  .el-icon-refresh-right {
    cursor: pointer;
    font-size: 14px;
  }

  .rolePageHeaderCardStyle-right {
    display: flex;
    align-items: center;
    .rolePageHeaderCardStyle-right-refresh {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .dashbord-head-left-refresh {
    color: #acacac;
    font-size: 12px;
    margin-left: 5px;
  }
}
.img_div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>