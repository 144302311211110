// 时间人员筛选：仪表板主页顶部

import * as request from "./api.js"; // 仪表板api
import { calendarYear } from "@/config/reportDataType/unitData"; // 日历年

export default {
  data() {
    return {
      timeOptions: [], // 时间下拉内容
      treeData: [], // 人员下拉内容
      timeConditionTo: false, // 是否显示时间选择框
      personConditionTo: false, // 是否显示人员选择框
    }
  },
  methods: {
    // 获取仪表板条件时间
    async getDashboardConditionTime() {
      let { data } = await request.getDashboardConditionTime();
      this.timeOptions = [];
      this.timeOptions.push(
        {
          name: this.$i18n.t("label.custom_time"),
          options: [{
            id: this.$i18n.t("label.custom_time"),//'自定义时间',
            name: this.$i18n.t("label.custom_time")
          }],
        },
        {
          name: this.$i18n.t("canlendaryear"),//'日历年',
          options: calendarYear(),
        },
        {
          name: this.$i18n.t("lable.account.year"),//'会计年'
          options: data.fiscalyear,
        },
        {
          name: this.$i18n.t("label.fiscal.FQ"),//'会计季度'
          options: data.fiscalquarter,
        },
        {
          name: this.$i18n.t("label_custom_year"),//'自定义年度'
          options: data.customyear,
        },
        {
          name: this.$i18n.t("label.custom.CustomQuarter"),//'自定义季度'
          options: data.customquarter,
        },
      )
    },
    // 获取用户所属角色下所有用户
    async getUserList() {
      let { data } = await request.getUserList();
      this.treeData = [];
      // 判断返回数据是否有下级，当有下级的情况下才需要执行下面方法
      if (data.children) {
        this.getEachRole(data); // 操作data数据
      }
      this.treeData.push(data);
    },
    // 将接口返回的用户数据递归，userList和roleList均拆开放进对应的children中
    getEachRole(data) {
      data.childrenList = []; // 新定义一个数组childrenList，用来存放拆分出来的用户和角色
      // 用户集合
      if (data.children.userList.length > 0) {
        data.children.userList.map((item) => {
          data.childrenList.push({
            roleId: item.userid,
            roleName: item.username,
          });
        })
      }
      // 角色集合
      if (data.children.roleList.length > 0) {
        data.children.roleList.map((item) => {
          data.childrenList.push(item);
          this.getEachRole(item); // 接着往下，开始下一轮的push
        })
      }
    },
    // 获取仪表板中的所有图表
    async getChartsByDashboardId() {
      let params = {
        dashboardid: this.dashboardid,
      };
      await request.getChartsByDashboardId(params);
    },
    // 获取仪表板筛选项配置
    async getDashboardConditionByDashboardId() {
      let params = {
        dashboardid: this.dashboardid, // 仪表板id
      };
      let data = await request.getDashboardConditionByDashboardId(params);
      this.$store.commit("setDashboardCondition", data.data); // 将返回的筛选条件存储到vuex中
    },
    // 判断该仪表板是否设置了筛选项配置，时间和角色均需判断
    async isSetCondition() {
      await this.getDashboardConditionByDashboardId();
      // 判断该仪表板下是否设置了时间筛选条件
      if (this.$store.getters.getDashboardCondition.dashboardConditionTimeArr.length === 0) {
        this.timeConditionTo = false; // 表示没有设置时间筛选条件，此时需要禁用时间选择器
      } else {
        this.timeConditionTo = true;
      }
      // 判断该仪表板下是否设置了人员筛选条件
      if (this.$store.getters.getDashboardCondition.dashboardConditionPersonArr.length === 0) {
        this.personConditionTo = false; // 表示没有设置人员筛选条件，此时需要禁用人员选择器
      } else {
        this.personConditionTo = true;
      }
    },
  }
}
