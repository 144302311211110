<!--
  卡片视图：
    1.控制字段只要可跳转，就可以出现悬浮框；
    2.name字段虽然是选择完对象就默认选择的，但该字段可删除，可重选；
-->
<template>
  <div class="card_div">
    <el-card class="box-card" v-loading="loadingStatus">
      <!-- 表头 -->
      <div slot="header" class="clearfix">
        <!-- 表头内容 -->
        <div class="refresh-date">
          <div>{{ fixedCpnlanguageFilter(CardViewData.name) }}</div>
        </div>
        <!-- 操作按钮的下拉菜单 -->
        <div class="right-box">
          <DeleteEdit
            class="menu-extend-dropdown"
            :cpnName="CardViewData.name"
            :cpnId="CardViewData.i"
            v-if="isShowExtendBtnDropdown"
            :cpnObject="CardViewData"
            :isEdit="true"
          />
        </div>
        <!-- 操作按钮的下拉菜单 -->
      </div>

      <!-- 中间内容区 -->
      <!-- 暂无数据时显示这个div -->

      <!-- 内容区整体盒子 business_opportunitys-->
      <div class="business_opportunity">
        <!-- 暂无数据和没有权限提示 -->
        <temporarily-no-data v-if="isEmpty && this.allDatas == ''" />
        <temporarily-no-data
          v-if="errormessage"
          :titleWord="errormessage ? errormessage : ''"
          :imgSrc="errormessage ? 'icon-file404' : ''"
        />

        <!-- 数据区盒子 -->

        <!-- class="overall-data-box" -->
        <div class="data-area-box">
          <div
            v-for="(item, index) in allDatas"
            :key="index"
            class="overall-data-box"
          >
            <div class="right-data-box">
              <!-- 右侧图标 -->
              <div class="right-img" v-if="!userDefined">
                <svg class="icon" aria-hidden="true">
                  <use :href="'#icon-' + item.titImg"></use>
                </svg>
              </div>
              <div class="right-img" v-if="userDefined">
                <svg class="icon" aria-hidden="true">
                  <use :href="'#icon-' + item.titImg"></use>
                </svg>
              </div>
              <!--数据小盒子 确定的卡片视图 -->
              <div class="data-box" v-if="!userDefined">
                <!-- 除今日事件数据title -->
                <div
                  class="data-title"
                  v-if="item.khmcccname || item.createbyidccname"
                >
                  <!-- 业务机会 -->
                  <!-- 右击出现新的标签页 -->
                  <a
                    :href="`#/commonObjects/detail/${item.id}/DETAIL`"
                    target="_blank"
                  >
                    <Popover-cpn
                      :labelName="tabStyle"
                      :recordObj="item"
                      :content="item.name"
                      @jumpDetail="cardViewjump(item.id)"
                    ></Popover-cpn
                  ></a>
                </div>
                <!-- 今日事件数据title -->
                <div v-if="item.subject" class="data-title">
                  <!-- 右击出现新的标签页 -->
                  <a
                    :href="`#/commonObjects/detail/${item.id}/DETAIL`"
                    target="_blank"
                    ><Popover-cpn
                      :labelName="tabStyle"
                      :recordObj="item"
                      :content="item.subject"
                      @jumpDetail="detailsOfTodayEventJump(item)"
                    ></Popover-cpn
                  ></a>
                </div>

                <!-- title下面盒子 -->
                <div class="bottom_detail">
                  <!-- 字段名称 -->
                  <el-tooltip popper-class="my-tooltip" placement="top">
                    <div slot="content">{{ item.khmcccname }}</div>
                    <div
                      class="field-name"
                      @click.prevent="jumpDetail(item.khmc)"
                    >
                      <!-- 右击出现新的标签页 -->
                      <a
                        :href="`#/commonObjects/detail/${item.khmc}/DETAIL`"
                        target="_blank"
                      >
                        {{ item.khmcccname }}</a
                      >
                    </div>
                    <!-- "业务机会" -->
                  </el-tooltip>
                  <el-tooltip popper-class="my-tooltip" placement="top">
                    <div slot="content">
                      {{ item.begintime }}
                    </div>
                    <div class="start-time" v-if="item.begintime">
                      {{ item.begintime }}
                    </div>
                  </el-tooltip>

                  <el-tooltip popper-class="my-tooltip" placement="top">
                    <div slot="content">
                      {{ item.endtime }}
                    </div>
                    <div v-if="item.endtime" class="deadline">
                      · {{ item.endtime }}
                    </div>
                  </el-tooltip>
                  <!-- 日期 -->
                  <el-tooltip
                    popper-class="my-tooltip"
                    placement="top"
                    :content="item.jsrq"
                  >
                    <div
                      class="date-box"
                      v-if="item.jsrq && item.khmcccname == null"
                    >
                      {{ item.jsrq }} ·
                    </div>
                  </el-tooltip>
                  <el-tooltip
                    popper-class="my-tooltip"
                    placement="top"
                    :content="item.jsrq"
                  >
                    <div class="date-box" v-if="item.jsrq && item.khmcccname">
                      · {{ item.jsrq }}
                    </div>
                  </el-tooltip>
                  <!-- 金额 -->
                  <el-tooltip
                    popper-class="my-tooltip"
                    placement="top"
                    :content="item.jine"
                  >
                    <div slot="content">
                      <!--  金额 -->
                      {{ $t("label.stdashbord.xsld.business.jine") }}
                      ：{{ item.jine }}
                    </div>
                    <div class="money-box" v-if="item.jine">
                      · {{ item.jine }}
                    </div>
                  </el-tooltip>
                </div>
              </div>
              <!-- 自定义卡片视图 -->
              <div class="customCard" v-if="userDefined">
                <!-- 每条数据的所有字段 -->
                <!-- 第一行字段数据 -->
                <div v-for="(item1, index1) in item.slice(0, 1)" :key="index1">
                  <div
                    v-if="item1.id"
                    class="custom-title"
                    :class="{ 'custom-name': item1.id }"
                    @click.prevent="customClick(item1)"
                  >
                    <!-- 右击出现新的标签页 -->
                    <a
                      :href="`#/commonObjects/detail/${item1.id}/DETAIL`"
                      target="_blank"
                      ><Popover-cpn
                        :recordObj="item1"
                        :content="item1.value"
                        :labelName="tabStyle"
                    /></a>
                  </div>
                  <span v-else>{{ item1.value }}</span>
                </div>
                <!-- 第二行字段数据 -->
                <div class="each_filed">
                  <div
                    class="each_filed2"
                    v-for="(item1, index1) in item.slice(1)"
                    :key="index1"
                  >
                    <el-tooltip
                      popper-class="my-tooltip"
                      placement="top"
                      :content="item1.value"
                    >
                      <div class="custom-data">
                        <span v-show="item1.value && index1 !== 0" class="spot"
                          >&nbsp;&nbsp;·&nbsp;&nbsp;</span
                        >
                        <span
                          slot="content"
                          class="custom-page-style"
                          @click.prevent="customClick(item1)"
                          v-if="item1.id"
                          :class="{ 'custom-name': item1.id }"
                        >
                          <a
                            :href="`#/commonObjects/detail/${item1.id}/DETAIL`"
                            target="_blank"
                          >
                            <Popover-cpn
                              :recordObj="item1"
                              :content="item1.value"
                              :labelName="tabStyle"
                          /></a>
                        </span>
                        <span v-else class="custom-page-style">{{
                          item1.value
                        }}</span>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <!-- 事件右侧图标盒子 -->
            <div class="event-icon">
              <!-- 新建任务 -->
              <div
                class="task-icon"
                @click="newTaskPopup(item, index)"
                v-show="Authority.isNewTask"
              >
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-system_task_ic1"></use>
                </svg>
              </div>
              <!-- 新建事件 -->
              <div
                class="task-icon"
                v-show="Authority.isNewEvent"
                @click="newEventPopup(item, index)"
              >
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-calendar"></use>
                </svg>
              </div>
              <!-- 编辑 -->
              <div
                class="task-icon"
                v-show="Authority.isEdit"
                @click="editContent(item, index)"
              >
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-edit-big"></use>
                </svg>
              </div>
              <!-- 删除 -->
              <div
                class="task-icon"
                v-show="Authority.isDelete"
                @click="deleteRecord(item.id)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部区域内容 -->
      <div class="bottom-box">
        <!-- 分割线 -->
        <!-- 底部内容 -->
        <div class="viewTasks" @click.prevent="viewContent">
          <a :href="viewHref" target="_blank">
            <!-- 查看 日历  全部-->
            {{ $t("component_todaytask_label_all") }}
            <!-- {{
              CardViewData.relatedid === "todayevent"
                ? $t("component_event_label_calendar")
                : $t("component_todaytask_label_all")
            }} -->
          </a>
        </div>
      </div>
    </el-card>

    <!-- 数据新建编辑 -->
    <create-edit-obj
      ref="createEditObj"
      :id="id"
      :prefix="prefix"
      :objectApi="objectApi"
      :object-name="typeName"
      :bigTitle="bigTitle"
      :homeNweName="homeNweName"
      :homeNweId="homeNweId"
      :homeContactName="homeContactName"
      :homeNweObjid="homeNweObjid"
      :standardHomePage="standardHomePage"
      @save="save"
    >
    </create-edit-obj>
  </div>
</template>

<script>
import * as request from "./api.js";
import DeleteEdit from "./DeleteEdit";
import PopoverCpn from "./PopoverCpn";
import createEditObj from "@/views/commonObjects/components/create-edit-obj";
import TemporarilyNoData from "@/components/TemporarilyNoData/index.vue"; // 暂无数据
import { getobjectlist } from "./api.js"; //获取视图列表新建回显
import { owneridIsJump } from "@/config/commonMehods/everyoneJump.js"; // 判断所有人是否跳转权限方法
// 标准组件和多语言对应关系
import { objKey } from "@/views/homePage/components/standardComponents.js"; 

export default {
  props: {
    CardViewData: {
      type: Object,
      default: () => ({}),
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    DeleteEdit,
    PopoverCpn,
    createEditObj,
    TemporarilyNoData,
  },
  data() {
    return {
      // 新建修改需要的id
      id: "",
      objectApi: "",
      typeName: "",
      editId: "",
      dependencyObjectName: "", //新建名称和相关对象的名称
      dependencyObjectId: "",
      viewListData: [],
      // 向子组件传递名称相关项数据
      homeNweName: "",
      homeNweId: "",
      homeContactName: "", //对象前缀
      homeNweObjid: "", //对象类型id
      // 判断是否是主页来控制名称相关项不可编辑
      standardHomePage: "",
      // 图表对应的图片
      iconDisplay: "",
      // 自定义id和Api
      customId: "",
      customApi: "",
      prefixData: "",
      prefix: "",
      // 删除需要的数据
      deleteData: "",
      // 我的任务弹框显示
      // isShowPierceAlert: true,
      // 上次刷新时间
      refreshTimes: "",
      // 查看事件
      jumpEvent: [
        {
          name: this.$i18n.t("label.calendar"), //日历
          id: "calendar",
        },
        {
          name: this.$i18n.t("label.quickbooks.view.all"), //全部
          id: "allTasks",
        },
        {
          name: this.$i18n.t("label.quickbooks.view.all"), //全部
          id: "near",
        },
        {
          name: this.$i18n.t("label.quickbooks.view.all"), //全部
          id: "beoverdue",
        },
      ],
      // 查看内容
      jumpText: "",
      // 卡片视图数据
      allDatas: [],
      // 图片显示隐藏
      isShowImg: {},
      Authority: {
        isDelete: "",
        isEdit: "",
        isNewEvent: "",
        isNewTask: "",
      },
      userDefined: false, // 是否属于自定义卡片视图
      loadingStatus: false, //
      isEmpty: true,
      bigTitle: [],
      tabStyle: "", // 图标key
      // countryCode: $cookies.get("countryCode"),
      viewId: "", //自定义卡片视图id
      errormessage: "", //提示信息
    };
  },
  created() {
    this.getHomePageData();
    // this.getViewList();
  },
  mounted() {
    this.jumpEvent.map((item, index) => {
      // "今日事件"
      if (
        this.CardViewData.name === this.$i18n.t("label.weixin.input.content") &&
        index == 0
      ) {
        return (this.jumpText = item.name);
      }
      if (
        this.CardViewData.name ===
          this.$i18n.t("vue_label_homepage_30daysnocontact") &&
        index == 1
      ) {
        return (this.jumpText = item.name);
      }
      if (
        this.CardViewData.name ===
          this.$i18n.t("label.productsales.oppor.approach") &&
        index == 2
      ) {
        //"临近业务机会"
        return (this.jumpText = item.name);
      }
      if (
        this.CardViewData.name ===
          this.$i18n.t("label.productsales.oppor.overdue") &&
        index == 3
      ) {
        //"已逾期的业务机会"
        return (this.jumpText = item.name);
      }
    });
  },
  destroyed() {
    this.$bus.$off("refreshOverallData");
  },
  methods: {
    // 自定义卡片视图name点击事件
    customClick(option) {
      if (option.id) {
        this.$router.push({
          path: `/commonObjects/detail/${option.id}/DETAIL`,
        });
      }
    },
    // async getViewList() {
    //   let res = await getobjectlist();
    //   this.viewListData = res.data;
    // },
    // 触发新建任务弹框事件
    newTaskPopup(item) {
      this.objectApi = "Task";
      this.id = "";
      //任务
      this.typeName = this.$i18n.t("label.exchange.sync.state.task");

      this.prefix = "bfa";
      this.editObj = true;
      this.standardHomePage = "homepage"; //传给新建弹框判断名称或者相关项是否可编辑
      if (this.CardViewData.iscustom === "1") {
        // 过滤出视图列表中的id相同的哪一项，，取到labelname名称，并且传值给新建弹框组件
        // 给相关项或者名称赋值
        getobjectlist().then((res) => {
          //请求对象名称，给相关项弹框相关项赋值
          this.viewListData = res.data;
          this.viewListData.filter((res) => {
            if (res.id === item[0].dependencyObjectId) {
              this.dependencyObjectName = res.labelname;
              return this.dependencyObjectName;
            }
          });
          this.homeNweObjid = item[0].objectApi;
          let objectData = {
            name: "",
            id: "",
          };
          objectData.name = item[0].value;
          // objectData.id=item[0].id;
          this.homeNweName = objectData.name;
          let a = item.map((items) => {
            if (items.id) {
              return items;
            }
          });
          let b = a.filter((n) => {
            return n !== undefined;
          });
          this.homeNweId = b[0].id;
          this.homeContactName = this.dependencyObjectName;
        });
        //给新建弹框相关项或者名称赋值
      } else if (this.CardViewData.iscustom === "0") {
        this.homeNweName = item.name;
        this.homeNweId = item.id;
        this.homeNweObjid = this.CardViewData.objid;
        if (this.homeNweObjid === "opportunity") {
          // 业务机会
          this.homeContactName = this.$i18n.t(
            "lable.quote.syncing.opportunity"
          );
        }
      }
      this.$nextTick(() => {
        // this.objectApi = "Task";

        setTimeout(() => {
          this.$refs.createEditObj.add();
        }, 500);
      });
      // this.$emit("newTaskPopup", "Task");
      // this.$emit("newTaskPopup");
      // 传什么东西就把参数放到第三个参数中
      // this.$bus.$emit("changedialogAttrStatusEvent", true, val);
    },
    // 触发新建事件弹框方法
    newEventPopup(item) {
      this.id = "";
      //事件
      this.typeName = this.$i18n.t("label.umeng.activity.event");
      this.objectApi = "Event";
      this.prefix = "bef";
      this.standardHomePage = "homepage";
      // this.prefix="bef";
      if (this.CardViewData.iscustom === "1") {
        // 过滤出视图列表中的id相同的哪一项，，取到labelname名称，并且传值给新建弹框组件
        // 给相关项或者名称赋值
        getobjectlist().then((res) => {
          //请求对象名称，给相关项弹框相关项赋值
          this.viewListData = res.data;
          this.viewListData.filter((res) => {
            if (res.id === item[0].dependencyObjectId) {
              this.dependencyObjectName = res.labelname;
              return this.dependencyObjectName;
            }
          });
          let objectData = {
            name: "",
            id: "",
          };
          objectData.name = item[0].value;
          // objectData.id=item[0].id;
          this.homeNweName = objectData.name;
          // this.homeNweId = objectData.id;
          let a = item.map((items) => {
            if (items.id) {
              return items;
            }
          });
          let b = a.filter((n) => {
            return n !== undefined;
          });
          this.homeNweId = b[0].id;
          this.homeNweObjid = item[0].objectApi;
          this.homeContactName = this.dependencyObjectName; //给新建弹框相关项或者名称赋值
        });
      } else if (this.CardViewData.iscustom === "0") {
        this.homeNweName = item.name;
        this.homeNweId = item.id;
        this.homeNweObjid = this.CardViewData.objid;
        if (this.homeNweObjid === "opportunity") {
          // 业务机会
          this.homeContactName = this.$i18n.t(
            "lable.quote.syncing.opportunity"
          );
        }
      }
      setTimeout(() => {
        this.$refs.createEditObj.add();
      }, 500);
    },
    //编辑
    editContent(item) {
      this.typeName = "";
      if (this.CardViewData.iscustom === "0") {
        this.objectApi = "Opportunity";
        this.prefix = "002";
        this.id = item.id;

        this.$nextTick(() => {
          this.$refs.createEditObj.edit();
          // this.$refs.createEditObj.edit()
        });
      } else if (this.CardViewData.iscustom === "1") {
        // this.prefix = this.prefix;
        this.objectApi = item[0].objectApi;
        // let arrItem = [];
        let a = item.map((items) => {
          if (items.id) {
            return items;
          }
        });
        let b = a.filter((n) => {
          return n !== undefined;
        });
        this.editId = b[0].id;
        this.id = this.editId;
        this.$nextTick(() => {
          this.$refs.createEditObj.edit();
        });
      }

      // this.$bus.$emit("changedialogAttrStatusEdit", true, tale, valId);
    },
    save() {
      this.getHomePageData();
    },
    // 查看内容
    viewContent() {
      //日历
      if (this.CardViewData.iscustom === "0") {
        if (this.CardViewData.relatedid === "todayevent") {
          this.$router.push({
            path: `/CalendarHeader`,
          });
        } else {
          this.$router.push({
            path: `/commonObjects/views/table/Opportunity/opportunity/002`,
          });
        }
        // else if (
        //   this.CardViewData.relatedid === "opplj" ||
        //   "oppoverdue" ||
        //   "opp30"
        // ) {
        //   this.$router.push({
        //     path: `/commonObjects/views/table/Opportunity/opportunity/002`,
        //   });
        // }
      } else if (this.CardViewData.iscustom === "1") {
        if (this.jumpText === "") {
          this.$router.push({
            path: `/commonObjects/views/table/${this.customApi}/${this.customId}/${this.prefixData}?viewId=${this.viewId}`,
          });
        }
      }
    },
    // 获取数据
    async getHomePageData() {
      try {
        this.loadingStatus = true;
        let res = await request.getHomePageData({
          id: this.CardViewData.i,
          refresh: "true",
        });
        /**
         * 保存视图id
         * 卡片点击查看全部跳转使用
         */
        this.loadingStatus = false;
        if (res.data.errormessage) {
          this.errormessage = res.data.errormessage;
        }
        this.viewId = res.data.viewId && res.data.viewId;
        // this.tabStyle = res.data.tabStyle; // 获取该卡片视图中所对应的图标的key
        // 点击跳转需要的参数
        this.customId = res.data.objId && res.data.objId;
        this.customApi = res.data.objectApi && res.data.objectApi;
        this.prefixData = res.data.prefix && res.data.prefix;
        this.Authority = res.data.Authority && res.data.Authority;
        // 如果是自定义添加的卡片视图，则采用下面的方法
        if (res.data.fieldHeader) {
          this.userDefined = true;
          let arr = [];
          res.data.data.map((item) => {
            let eachArr = [];
            res.data.fieldHeader.map((item1) => {
              let object = {};
              object.objectApi = res.data.objectApi;
              object.dependencyObjectId = res.data.objId;
              // 主祥字段和查找字段(所有人需要单独判断是否可跳转)，后面加上ccname查找
              if (item1.fieldtype === "Y" || item1.fieldtype === "M") {
                if (
                  item1.apiname !== "ownerid" ||
                  (item1.apiname === "ownerid" &&
                    owneridIsJump(item[item1.apiname]))
                ) {
                  object.id = item[item1.apiname];
                }
                object.label = item1.labelname;
                object.value = item[item1.apiname + "ccname"];
              } else if (
                item1.apiname === "name" ||
                item1.apiname === "subject"
              ) {
                // 名称字段、主题字段：id取该条数据id
                object.id = item.id;
                object.label = item1.labelname;
                object.value = item[item1.apiname];
              } else if (item1.apiname === "pricebook2id") {
                // 业务机会中的价格手册
                object.id = item.id;
                object.label = item1.labelname;
                object.value = item[item1.apiname+ "ccname"];
              }else if( item1.apiname === "recordtype"){
                // 客户对象下的记录类型
                object.label = item1.labelname;
                object.value = item[item1.apiname+ "ccname"];
              } else {
                object.label = item1.labelname;
                object.value = item[item1.apiname];
              }
              eachArr.push(object);
            });
            arr.push(eachArr);
          });
          this.allDatas = arr;
          // 如果没有返回头，不是自定义的卡片视图
        } else {
          this.userDefined = false;
          this.allDatas = res.data.data;
          this.iconDisplay = res.data.objectApi;
          if (this.allDatas.length < 1) {
            this.isEmpty = true;
          } else {
            this.isEmpty = false;
          }
        }
        // 判断该对象是否有对应图标，匹配图标
        if (res.data.tabStyle) {
          // 若是有，统一使用后台返回的字段tabStyle来匹配对应图标
          this.tabStyle = res.data.tabStyle;
        } else {
          // 若是没有，使用默认
          this.tabStyle = "hometab";
        }
        this.prefix = res.data.prefix;
        this.allDatas.forEach((item) => {
          this.$set(item, "titImg", this.tabStyle);
          if (this.CardViewData.iscustom === "0") {
            if (res.data.tabStyle === "cloudtab10norm") {
              //潜在客户
              this.tabStyle = "cloudtab29";
              this.$set(item, "titImg", this.tabStyle);
            }
          }
        });
      } catch (error) {
        this.loadingStatus = false;
      }
    },
    // 今日事件点击跳转详情
    detailsOfTodayEventJump(eventId) {
      this.$router.push({
        path: `/commonObjects/detail/${eventId.id}/DETAIL`,
      });
    },

    // 时间刷新事件
    // async refreshTime(e, id) {
    //   let res = await request.getHomePageData({
    //     id: this.CardViewData.i,
    //     refresh: "true",
    //   });
    //   this.CardViewData.lastmodifydate = res.data.refreshTime;
    //   this.getHomePageData();
    // },

    //点击跳转详情页name
    cardViewjump(option) {
      this.$router.push({
        path: `/commonObjects/detail/${option}/DETAIL`,
      });
    },
    //点击跳转详情查找字段
    jumpDetail(fieldId) {
      this.$router.push({
        path: `/commonObjects/detail/${fieldId}/DETAIL`,
      });
    },
    // 删除记录
    deleteRecord() {
      // if (this.deleteData == "false") {
      //   this.$confirm(
      //     "此操作将永久删除该条内容, 是否继续?",
      //     this.$i18n.t("label_popup_infoz"), // "提示"
      //     {
      //       confirmButtonText: this.$i18n.t("label.ems.confirm"), //"确定
      //       cancelButtonText: this.$i18n.t("button_partner_cancel"), //"取消",
      //       type: "warning",
      //     }
      //   )
      //     .then(() => {
      //       this.$message({showClose: true,
      //         type: "success",
      //         message: this.$i18n.t("label_tabpage_delsuccessz"), //"删除成功!",
      //       });
      //     })
      //     .catch(() => {
      //       this.$message({showClose: true,
      //         type: "info",
      //         message: "已取消删除",
      //       });
      //     });
      //   this.deleteRecordData(dataId);
      // } else {
      //   this.$alert(
      //     "您试图删除的记录已锁定。如果必须执行此操作，请与您的管理员联系。",
      //     this.$i18n.t("component_setup_tabs_label_delete"), //"删除",
      //     {
      //       confirmButtonText: this.$i18n.t("label.ems.confirm"), //"确定",
      //       // callback: action => {
      //       //   this.$message({showClose: true,
      //       //     type: 'info',
      //       //     message: `action: ${ action }`
      //       //   });
      //       // }
      //     }
      //   );
      // }
    },
    // async deleteRecordData(dataId) {
    //   await request.del({
    //     id: dataId,
    //     objectApi: "Opportunity",
    //   });
    //   //
    // },
  },
  computed: {
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      }
      return undefined
    },
    // 固定的3个卡片的名称前端进行多语言
    fixedCpnlanguageFilter() {
      return (name) => {
        if (name === "今日事件") {
          //今日事件
          return this.$i18n.t("vue_label_homepage_todaysevent");
        } else if (name === "30天未联系的业务机会") {
          //30天未联系的业务机会
          return this.$i18n.t("vue_label_homepage_card_30dayswithout");
        } else if (name === "临近业务机会") {
          //临近业务机会
          return this.$i18n.t("vue_label_homepage_card_impending");
        } else if (name === "已逾期的业务机会") {
          //已逾期的业务机会
          return this.$i18n.t("vue_label_homepage_card_closedopportunities");
        } else {
          // objKey
          return objKey[this.CardViewData.relatedid]?this.$i18n.t(objKey[this.CardViewData.relatedid]):name;
        }
      };
    },
    // 底下查看全部跳转的href计算
    viewHref() {
      if (this.CardViewData.iscustom === "0") {
        if (this.CardViewData.relatedid === "todayevent") {
          return "#/CalendarHeader";
        } else {
          return "#/commonObjects/views/table/Opportunity/opportunity/002";
        }
        // else if (
        //   this.CardViewData.relatedid === "opplj" ||
        //   "oppoverdue" ||
        //   "opp30"
        // ) {
        //   return "#/commonObjects/views/table/Opportunity/opportunity/002";
        // }
      } else if (this.CardViewData.iscustom === "1") {
        if (this.jumpText === "") {
          return `#/commonObjects/views/table/${this.customApi}/${this.customId}/${this.prefixData}?viewId=${this.viewId}`;
        }
      }
      return undefined
    },
  },
  watch: {
    // 监听接收的图表数据，调获取图表数据接口
    CardViewData: function () {
      this.getHomePageData(this.CardViewData.i);
    },
  },
};
</script>
<style lang="scss" scoped>
a {
  color: #006dcc !important;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
::v-deep .el-card__body {
  height: 100%;
  padding: 10px 0px !important;
}
.box-card {
  width: 100%;
  height: 100%;

  .menu-extend-dropdown {
    cursor: pointer;
  }
  // 表头样式
  // .title {
  //   width: 100%;
  //   height: 40px;
  //   display: flex;
  //   justify-content: space-between;
  //   .menu-extend-dropdown {
  //     cursor: pointer;
  //     margin: 20px;
  //   }

  //   .title-left {
  //     margin: 20px 0 0 20px;
  //   }
  // }
  // 内容区样式
  .business_opportunity {
    height: 73%;
    padding: 0 30px;
    // padding: 0 10px;
    // margin-top: 22px;
    position: relative;
    ::v-deep .div_temporarily_no_data  svg{
      max-width: 50%!important;
      max-height: 50% !important;
      margin-bottom: 15px;
    }
    .noData {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      div {
        position: absolute;
        left: 30%;
        top: 113px;
      }
    }
    .data-area-box {
      height: 66%;
      .overall-data-box {
        display: flex;
        height: 30%;
        .right-data-box {
          display: flex;
          flex: 1;
          width: calc(100% - 30px);
          white-space: nowrap;
          overflow: hidden;
          .right-img {
            svg {
              width: 24px;
              height: 24px;
              margin-top: 5px;
              margin-right: 12px;
            }
          }
          .data-box {
            width: calc(100% - 50px);
            .data-title {
              display: inline-block;
              color: #006dcc;
              cursor: pointer;
              max-width: 90%;
              height: 16px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 12px;
              div {
              }
              ::v-deep .el-popover__reference {
                min-width: 20%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .bottom_detail {
              width: 100%;
              display: flex;
              white-space: nowrap;
              overflow: hidden;
              // div:nth-child(2) {
              //   margin: 0;
              // }
              // div:nth-child(2),
              // div:nth-child(3) {
              //   color: #3e3e3c;
              // }
              .start-time {
                min-width: 0%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
              }
              .deadline {
                min-width: 20%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 5px;
                font-size: 12px;
              }
              .afternoon-time {
                max-width: 30%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-left: 5px;
                font-size: 12px;
              }
              .field-name {
                // max-width: 33%;
                min-width: 0%;
                color: #006dcc;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
                font-size: 12px;
              }

              .date-box {
                min-width: 10%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                // padding: 0 5px 0 5px;
                padding-right: 5px;
                font-size: 12px;
              }
              .money-box {
                min-width: 20%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
              }
            }
          }
          .customCard {
            width: 83%;
            overflow: hidden;
            .custom-title {
              display: inline-block;
              max-width: 90%;
              height: 17px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              a {
                color: #006dcc;
              }
            }
            .each_filed {
              width: 100%;
              display: flex;
              .each_filed2 {
                display: flex;
                min-width: 1%;
                padding-right: 5px;
                white-space: nowrap;
                .custom-data {
                  display: flex;
                  // width: 100%;
                  max-width: 100%;
                  // min-width: 35%;
                  .spot {
                    padding-right: 5px;
                  }
                  .custom-page-style {
                    a {
                      color: #006dcc;
                    }
                    // max-width:95%;
                    max-width: 100%;
                    // min-width: 35%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
        .event-icon {
          display: flex;
          width: 50px;
          // background: #ffffff;
          //
          margin-right: auto;
          .task-icon {
            margin: 2px 2px;
            width:22px;
            svg {
              width: 22px;
              cursor: pointer;
              margin-top: 13px;
            }
          }
        }
      }
    }
  }
  // 底部样式
  .bottom-box {
    width: 100%;
    height: 13%;
    border-top: 1px solid #dcdbda;
    position: relative;
    .viewTasks {
      font-size: 12px;
      // display: flex;
      // justify-content: center; /* 水平居中 */
      color: #006dcc !important;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.el-card__body div:nth-child(6) .business_opportunity {
  border-bottom: 0;
}

.custom-name {
  font-size: 12px;
  cursor: pointer;
  color: #006dcc !important;
}
::v-deep .el-popover__reference {
  // width: 80%;
  margin: 0;
}
</style>
