<template>
  <!-- 最近记录 -->
  <div class="card_div">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>
          <!-- 最近记录 -->
          {{ $t("component_newrecords_label_title") }}
        </span>
        <DeleteEdit
          class="deleteEditJdyj"
          :cpnName="RecordData.name"
          :cpnId="RecordData.i"
          v-if="isShowExtendBtnDropdown"
          :cpnObject="RecordData"
          :isEdit="true"
        />
      </div>
      <div
        v-for="(item, index) in recentRecordData"
        :key="index"
        class="content"
      >
        <div class="content_img">
          <svg class="icon" aria-hidden="true">
            <use :href="'#icon-' + item.imgSrc"></use>
          </svg>
        </div>
        <div class="content_text">
          <div>
            <p>{{ item.label }}</p>
          </div>
          <!-- 报表和仪表板和文件不需要悬浮气泡 -->
          <div>
            <a :href="jumpDetailHref(item)" target="_blank">
              <Popover-cpn
                v-if="
                  item.objid !== 'lightningreport' &&
                  item.objid !== 'dashboardlightning' &&
                  item.objid !== 'file'
                "
                :recordObj="item"
                :content="item.recordname"
                @jumpDetail="jumpDetail(item)"
              />
              <p v-else @click.prevent="jumpDetail(item)" class="elseStyle">
                {{ item.recordname }}
              </p>
            </a>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { consoleSaveTab } from "@/views/home/api.js";
import * as request from "./api.js";
import PopoverCpn from "./PopoverCpn";
import DeleteEdit from "./DeleteEdit";

export default {
  components: {
    PopoverCpn,
    DeleteEdit,
  },
  props: {
    RecordData: {
      type: Object,
    },
    homePageObject: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.getHomePageData();
  },
  data() {
    return {
      recentRecordData: [],
      // popoverCpnTo: false,
      itemHref: "",
    };
  },
  computed: {
    ...mapState(["consoleData"]),
    jumpDetailHref() {
      return (item) => {
        if (item.objid === "lightningreport") {
          // 属于报表
          return `#/reportObject/editReport/run/${item.recordid}`;
        } else if (item.objid === "dashboardlightning") {
          // 属于仪表板
          return `#/dashboardObject/dashboardIndex/${item.recordid}`;
        } else if (item.objid === "file") {
          //属于文件
          return `#/ccFileCenter/fileDetail?id=${item.recordid}`;
        } else {
          return `#/commonObjects/detail/${item.recordid}/DETAIL`;
        }
      };
    },
    // 是否显示扩展的下拉按钮
    isShowExtendBtnDropdown() {
      if (this.homePageObject.type == "personal") {
        return true;
      } else if (
        this.homePageObject.type == "profile" &&
        this.homePageObject.isCustomToRolePage
      ) {
        return true;
      }
      return undefined
    },
  },
  methods: {
    // 获取数据
    async getHomePageData() {
      let res = await request.getHomePageData({
        id: this.RecordData.i,
      });
      this.recentRecordData = res.data;
      this.recentRecordData.map((item) => {
        if (item.label) {
          // 通用对象有label
          if (item.tabStyle) {
            item.imgSrc = item.tabStyle;
          } else {
            item.imgSrc = "hometab";
          }
        } else {
          // 报表对象没有label
          if (item.objid === "lightningreport") {
            item.label = this.$i18n.t("label.visualpage.btype"); //"报表";
            item.imgSrc = this.$i18n.t("label.visualpage.btype"); //报表
          }
        }
      });
    },
    //跳转到记录详情
    jumpDetail(item) {
      this.itemHref = item;
      if (item.objid === "lightningreport") {
        // 属于报表
        this.$router.push({
          path: `/reportObject/editReport/run/${item.recordid}`,
          query: {},
        });
      } else if (item.objid === "dashboardlightning") {
        // 属于仪表板
        this.$router.push({
          path: `/dashboardObject/dashboardIndex/${item.recordid}`,
          query: {},
        });
      } else if (item.objid === "file") {
        //属于文件
        this.$router.push({
          path: "/ccFileCenter/fileDetail",
          query: {
            id: item.recordid,
          },
        });
      } else {
        // 属于通用对象
        if (this.$store.state.navigationStyle) {
          // 如果导航样式为控制台样式
          let Level1TabItem = {};
          Level1TabItem.id = item.recordid;
          Level1TabItem.name = item.recordname;
          // 设置当前选中tab的样式
          this.consoleData.data.level1Id = item.recordid;
          // 判断是否存在此点击项
          let res = this.consoleData.data.list.some((item) => {
            return item.id == item.recordid;
          });
          if (!res) {
            // 如果不存在
            this.consoleData.data.list.push(Level1TabItem);
            this.$store.commit("setConsoleData", this.consoleData);
            consoleSaveTab({
              appid: this.$store.state.home.homeApplicationId,
              opentab: JSON.stringify(this.consoleData),
            });
          }
          this.$router.push({
            path: `/commonObjects/console-multi-screen/console-detail/${item.recordid}/DETAIL/?dataId=${item.recordid}`,
          });
        } else {
          this.$router.push({
            path: `/commonObjects/detail/${item.recordid}/DETAIL`,
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// 最近记录卡片自适应高度
.card_div {
  height: 100%;
  .box-card {
    height: 100% !important;
    ::v-deep .el-card__body {
      height: calc(100% - 56px);
      padding: 20px 20px 2px 20px !important;
    }
  }
}
.el-card__header {
  .clearfix {
    span {
      font-weight: bold !important;
    }
    .deleteEditJdyj {
      position: absolute;
      right: 30px;
    }
  }
}
.content {
  width: 100%;
  display: flex;
  height: 10%;
  // height: 36px;
  padding-left: 10px;
  .content_img {
    width: 24px;
    flex-shrink: 0;
    svg {
      width: 24px;
      height: 24px;
    }
    margin-right: 8px;
  }
  .content_text {
    width: 90%;
    display: flex;
    margin-top: 2px;
    font-size: 12px;
    div:first-of-type {
      max-width: 25%;
      margin-right: 6px;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    div:last-of-type {
      cursor: pointer;
      color: #006dcc;
      max-width: 70%;
      a {
        color: #006dcc;
      }
      p {
        span {
          outline: none;
          color: #006dcc;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .elseStyle {
        color: #006dcc;
        outline: none;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>

