<!--
统计数据日期选择下拉组件
-->
<template>
  <el-popover
    :popper-class="!isCustom ? 'select_popover' : 'select_popovers'"
    placement="bottom"
    trigger="click"
    v-model="visible"
    ref="StatisticalSelect"
  >
    <!-- 非自定义时间展示 -->
    <div class="slelect_div" v-if="!isCustom">
      <span
        v-for="item in timeArr"
        :key="item.index"
        @click="choose(item)"
        :class="item.label === selectDefault ? 'active' : ''"
      >
        {{ item.label }}
      </span>
    </div>
    <!-- 自定义时间展示 -->
    <div class="customTime" v-else>
      <div class="startDate">
        <!-- 开始日期 -->
        <span>{{ $t("label_chatter_startDate") }}</span>
        <el-date-picker
          v-model="value1"
          type="date"
          :placeholder="$t('label.tabpage.selectdate')"
        >
        </el-date-picker>
      </div>
      <div class="endDate">
        <!-- 结束日期 -->
        <span>{{ $t("label.quickbooks.searchlist.enddate") }}</span>

        <el-date-picker
          v-model="value2"
          type="date"
          :placeholder="$t('label.tabpage.selectdate')"
          :disabled="value1 ? false : true"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <div class="button">
        <!-- 应用过滤器 -->
        <el-button type="primary" class="application" @click="submit">{{
          $t("c561")
        }}</el-button>
        <el-button class="cancel" @click="cancel">{{
          $t("label_chatter_cancel")
        }}</el-button>
      </div>
    </div>
    <div class="box_select" slot="reference">
      <span style="cursor: pointer">
        {{ selectDefault }}
        <svg class="" aria-hidden="true">
          <use href="#icon-drop-down"></use></svg
      ></span>
    </div>
  </el-popover>
</template>

<script>
import * as request from "./api.js";
export default {
  props: {
    Application: {
      //区分是哪个页面应用的该组件
      typeof: String,
      default: "",
    },
  },
  data() {
    return {
      // 是否是自定义
      isCustom:false,
      timeArr: [
        {
          id: "latelyHour",
          label: this.$i18n.t("c546"), //最近24小时
        },
        {
          id: "latelyDay",
          label: this.$i18n.t("c547"), //最近7天
        },
        {
          id: "yesterday",
          label: this.$i18n.t("lable.account.Yesterday"), //昨天
        },
        {
          id: "Today",
          label: this.$i18n.t("today"), //今天
        },
        {
          id: "LastWeek",
          label: this.$i18n.t("label.brief.lastweek"), //上周
        },
        {
          id: "ThisWeek",
          label: this.$i18n.t("this.week"), //本周
        },
        {
          id: "LastMonth",
          label: this.$i18n.t("label.account.previous.M"), //上月
        },
        {
          id: "ThisMonth",
          label: this.$i18n.t("this.month"), //本月
        },
        {
          id: "custom",
          label: this.$i18n.t("label.mask.custom"), //自定义
        },
      ],
      selectDefault: this.$i18n.t("c547"), //选择默认值
      idDefault:"latelyDay",
      visible: false,
      value1: "", //开始时间
      value2: "", //结束时间
      customize: false, //是否为自定义时间
      timeFrame:"",
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < this.value1;
        },
      },
    };
  },
  watch: {
    visible() {
      if (!this.visible) {
        setTimeout(() => {
          this.isCustom = false;
        });
      }
    },
  },
  mounted() {
    this.getIndividualCase();
  },
  methods: {
    //获取当前个案的统计数据
    getIndividualCase(starttime, endtime) {
    this.getStatus(this.idDefault);
      let params = {
        timeFrame: this.customize ? "" : this.timeFrame, //选择时间范围
        customize: this.customize, //是否为自定义时间
        starttime: starttime ? starttime : "", //自定义开始日期
        endtime: endtime ? endtime : "", //自定义结束日期
      };
      if (this.Application === "customer") {
        //客服云主页图表
        this.$parent.loading = true; //改变父组件加载状态
        request.getIndividualCase(params).then((res) => {
          this.$parent.timeArr = [];
          this.$parent.receivedArr = [];
          this.$parent.shelveArr = [];
          this.$parent.upgradeArr = [];
          this.$parent.closeArr = [];
          const { averageResolutionTime,largestProportion,
          AverageResponseTime,Maxvalue,largestProportionpercentage,
          received,unresolved,displayTime} = res.data;//解构赋值
          this.$parent.averageResolutionTime = averageResolutionTime;
          this.$parent.largestProportion = largestProportion;
          this.$parent.AverageResponseTime = AverageResponseTime;
          this.$parent.Maxvalue = Maxvalue;
          this.$parent.largestProportionpercentage =
          largestProportionpercentage;
          this.$parent.received = received;
          this.$parent.unresolved = unresolved;
          displayTime.forEach((item) => {
          //是小时的话按小时赋值，否则按月赋值 原返回数据为“2022-3-25-10格式”按需求做特殊处理
          if (item.displayTime.split("-")[2].split(" ").length > 1) {
              item.displayTime =
                item.displayTime.split("-")[2].split(" ")[1] + ":00";
              this.$parent.timeArr.push(item.displayTime);
          } else {
              let str = `${item.displayTime.split("-")[2]} `
              // 根据数字对应的月份
              if(parseInt(item.displayTime.split("-")[1]) == 1) {
                  item.displayTime = str + this.$i18n.t("january");
                } else if(parseInt(item.displayTime.split("-")[1]) == 2) {
                  item.displayTime = str + this.$i18n.t("february");
                } else if(parseInt(item.displayTime.split("-")[1]) == 3) {
                  item.displayTime = str + this.$i18n.t("march");
                } else if(parseInt(item.displayTime.split("-")[1]) == 4) {
                  item.displayTime = str + this.$i18n.t("april");
                } else if(parseInt(item.displayTime.split("-")[1]) == 5) {
                  item.displayTime = str + this.$i18n.t("mary");
                } else if(parseInt(item.displayTime.split("-")[1]) == 6) {
                  item.displayTime = str + this.$i18n.t("june");
                } else if(parseInt(item.displayTime.split("-")[1]) == 7) {
                  item.displayTime = str + this.$i18n.t("july");
                } else if(parseInt(item.displayTime.split("-")[1]) == 8) {
                  item.displayTime = str + this.$i18n.t("august");
                } else if(parseInt(item.displayTime.split("-")[1]) == 9) {
                  item.displayTime = str + this.$i18n.t("september");
                } else if(parseInt(item.displayTime.split("-")[1]) == 10) {
                  item.displayTime = str + this.$i18n.t("october");
                } else if(parseInt(item.displayTime.split("-")[1]) == 11) {
                  item.displayTime = str + this.$i18n.t("november");
                } else if(parseInt(item.displayTime.split("-")[1]) == 12) {
                  item.displayTime = str + this.$i18n.t("december");
              }
              // `${
              // )}${"月timeArr:Array"}`;
              this.$parent.timeArr.push(item.displayTime);
          }
            this.$parent.receivedArr.push(item.receivedcases);
            this.$parent.shelveArr.push(item.shelvecases);
            this.$parent.upgradeArr.push(item.upgradecases);
            this.$parent.closeArr.push(item.closecases);
          });
          this.$parent.loading = false; //改变父组件加载状态
          setTimeout(() => {
            this.$parent.drawLine(); //重新加载echarts图表
          }, 1);
        });
      }
      if (this.Application === "channel") {
        this.$parent.loading = true; //改变父组件加载状态
        //渠道的解决时间
        request.problemSolvingTime(params).then((res) => {
          this.$parent.tableData = res.data;
          this.$parent.loading = false; //改变父组件加载状态
        });
      }
    },
    //选择日期
    choose(item) {
      if (item.id !== "custom") {
        if (item.label !== this.selectDefault) {
          this.visible = false;
          this.selectDefault = item.label;
          this.idDefault=item.id;
          this.customize = false;
          this.getIndividualCase();
        }
      } else {
        //刷新元素内容显示自定义时间弹出框
        this.visible = false;
        this.visible = true;
        this.customize = true;
        this.isCustom = true;
      }
    },
    //取消日期选择器选择
    cancel() {
      this.visible = false;
    },
    //应用过滤器
    submit() {
      //开始日期和结束日期都选择了才可应用过滤器否则提示
      if (this.value1 && this.value2) {
        let time = this.datedifference(this.value1, this.value2);
        if (time > 31) {
          return this.$message.warning(this.$i18n.t("c549")); //日期范围不能超过31天！
        } else {
          this.value1 = this.gettime(this.value1);
          this.value2 = this.gettime(this.value2);
          if (this.value1 <= this.value2) {
            this.getIndividualCase(this.value1, this.value2);
            this.selectDefault = this.$i18n.t("label.mask.custom");
            this.cancel();
          } else {
            return this.$message.warning(
              this.$i18n.t("startdate.enddate.time.illegal")
            ); //开始日期不能大于结束日期！
          }
        }
      } else {
        return this.$message.warning(this.$i18n.t("c550")); //请选择开始日期或结束日期
      }
    },
    //设置timeFrame值
    getStatus(idDefault) {
    const statusArr = {
      'latelyHour': '最近24小时',
      'latelyDay': '最近7天',
      'yesterday': '昨天',
      'Today': '今天',
      'LastWeek': '上周',
      'ThisWeek': '本周',
      'LastMonth': '上月',
      'ThisMonth': '本月',
    }
    this.timeFrame=statusArr[idDefault];
    },
    //计算选择日期范围
    // datedifference(sDate1, sDate2) {
    //   //sDate1和sDate2是yyyy-mm-dd格式
    //   let dateSpan, iDays;
    //   sDate1 = Date.parse(sDate1);
    //   sDate2 = Date.parse(sDate2);
    //   dateSpan = sDate2 - sDate1;
    //   dateSpan = Math.abs(dateSpan);
    //   iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
    //   return iDays;
    // },
    //格式化标准时间
    // gettime(data) {
    //   if (typeof data === "object") {
    //     let value =
    //       data.getFullYear() +
    //       "-" +
    //       this.checkTime(data.getMonth() + 1) +
    //       "-" +
    //       this.checkTime(data.getDate()) +
    //       " ";
    //     return value;
    //   } else {
    //     return data;
    //   }
    // },
    /**
     * 时间处理
     * 如果时间小于10 ，则再前面加一个'0'
     * */
    // checkTime(i) {
    //   if (i < 10) {
    //     i = "0" + i;
    //   }
    //   return i;
    // },
  },
};
</script>
<style lang="scss">
.el-popover.select_popover {
  width: 116px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.14);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin: 0;
  padding: 4px;
  border: 1px solid #e1e1e1;
  .slelect_div {
    display: flex;
    flex-wrap: wrap;
    span {
      display: block;
      width: 116px;
      height: 32px;
      padding-left: 12px;
      line-height: 32px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      cursor: pointer;
      &:hover {
        background: #f3f7ff;
        color: #2d6cfc;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
      }
    }
    .active {
      width: 116px;
      height: 32px;
      background: #eeeeee;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
    }
  }
}
.el-popover.select_popovers {
  width: 200px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.14);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #e1e1e1;
  .customTime {
    width: 100%;
    margin: 0;
    padding: 10px;
    .el-input__icon {
      display: none;
    }
    .el-input--prefix .el-input__inner {
      width: 160px;
      padding: 0;
      margin: 0;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid#E1E1E1;
      margin-bottom: 16px;
      background: #fff;
    }
    .startDate {
      span {
        display: block;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #888888;
        line-height: 14px;
      }
    }
    .endDate {
      span {
        display: block;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #888888;
        line-height: 14px;
      }
    }
    .application {
      width: 84px;
      height: 28px;
      padding: 0;
      font-size: 12px;
    }
    .cancel {
      width: 48px;
      height: 28px;
      padding: 0;
      font-size: 12px;
    }
  }
}
</style>
<style lang="scss" scoped>
svg {
  width: 14px;
  height: 14px;
}
</style>